



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ArrowButton extends Vue {
  @Prop() clickedDirection: (direction: 'left' | 'right') => void
  @Prop({ required: true }) total: number
  @Prop({ required: false, default: true }) showAll: boolean

  seeAll() {
    this.$emit('goToCollection')
  }

  clicked(direction: 'left' | 'right') {
    this.clickedDirection(direction)
  }
}
