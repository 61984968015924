































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth, { IUser } from '@/shared/storeModules/auth'
import { getInitials } from '../../shared/services/Functions'
import { IClient, IClientAccount } from '../../shared/components/interfaces/clients.interface'
import ClientsApi from '../../shared/services/api/ClientsApi'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import TextField from '@/components/legacy_components/TextField.vue'
import DeleteUserModal from '../../components/users/DeleteUserModal.vue'
import UrlHelper from '@/services/helpers/url.helper'
import AmplitudeHelper from '../../services/helpers/amplitudeHelper'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'

const userState = getModule(Auth)
@Component({
  components: {
    ButtonPrimary,
    TextField,
    DeleteUserModal,
    Space,
    Heading
  }
})
export default class ClientProfile extends Vue {
  @Prop() client: IClient
  getInitials = getInitials
  userState = userState
  userDetails: IClientAccount = {}
  user: IClientAccount = {}
  country: string | null
  showChangePassword = false
  $notify: any
  showDeleteUserModal = false

  get disableSaveButton() {
    return JSON.stringify(this.userDetails) === JSON.stringify(this.user)
  }

  async created() {
    const user = (await this.userState.getUser()) as IClientAccount
    if (!user.clientId) {
      this.userState.logout()
      this.$router.push({ name: 'Login' })
    } else {
      this.setUserData(user)
    }
  }

  get localeDomain() {
    return this.country === 'DK' ? 'https://www.goodmonday.io/da/' : 'https://www.goodmonday.io/'
  }

  cancelDeleteModalCallback() {
    this.showDeleteUserModal = false
  }

  showModal() {
    this.showDeleteUserModal = false
    setTimeout(() => {
      this.showDeleteUserModal = true
    }, 0)
  }

  async deleteModalConfirm(user: IUser, reason: string, other: string) {
    try {
      await this.$billie.users.delete(user.clientId!, user.id!)
      AmplitudeHelper.amplitude
        .getInstance()
        .logEvent('User management - Delete user', { Type: 'Own account', Reason: reason, Other: other })
      this.userState.logout().then(() => {
        UrlHelper.redirect()
      })
      this.showDeleteUserModal = false
    } catch (error) {
      //TODO: show error toast
    }
  }

  setUserData(user: IClientAccount) {
    this.userDetails = JSON.parse(JSON.stringify(user))
    this.user = JSON.parse(JSON.stringify(user))
    this.country = JSON.parse(JSON.stringify(user.client)).countryCode
  }

  changePassword() {
    this.showChangePassword = true
  }

  changedProperties() {
    const param: IClientAccount = { id: this.userDetails.id }
    Object.keys(this.userDetails).forEach((prop: string) => {
      if (typeof this.userDetails[prop] === 'object' && this.userDetails[prop] !== null) {
        if (JSON.stringify(this.userDetails[prop]) !== JSON.stringify(this.user[prop])) {
          param[prop] = this.userDetails[prop]
        }
      } else if (this.userDetails[prop] !== null && this.userDetails[prop] !== this.user[prop]) {
        param[prop] = this.userDetails[prop]
      }
    })
    return param
  }

  saveChanges() {
    const props = this.changedProperties()
    if (this.disableSaveButton || Object.keys(props).length === 1) {
      return
    }
    ClientsApi.saveUserData(this.userDetails).then((data: IClientAccount) => {
      this.setUserData(data)
      this.$notify({ title: 'Saved!' })
    })
  }
}
