
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import LabelForm from '@/components/legacy_components/LabelForm.vue'
import LabelField from '@/components/legacy_components/LabelField.vue'

@Component({
  components: { LabelForm, LabelSmall, LabelField }
})
export default class StepperInput extends Vue {
  @Prop({ required: true }) label: string
  @Prop({ default: 'form' }) labelType: 'form' | 'field'
  @Prop({ default: '' }) description: string
  @Prop({ required: true }) value: number | string | null

  get stepperValue() {
    if (this.value) return parseInt(this.value.toString(), 10)
    return 0
  }

  set stepperValue(value: string | number) {
    const number = parseInt(value.toString(), 10)
    if (number > 0) {
      this.$emit('input', number)
    } else if (number === 0 && typeof value === 'string') {
      this.$emit('input', value)
    } else {
      this.$emit('input', 0)
    }
  }
}
