import { IElement } from '../../shared/services/api.interface'
import NumberHelper from '@/shared/helpers/numberHelper'

/*
/ Helper functions for IElement
/ These functions will always return
/ the real quantity (divided by 100)
/ and prices in cents.
*/

const clientPrice = (e: IElement): number => {
  return Number(e.clientPrice)
}

const quantity = (e: IElement): number => {
  return e.count ? Number(e.count) : Number(e.quantity)
}

const subtotal = (e: IElement): number => {
  return NumberHelper.makeFloat(quantity(e) * clientPrice(e))
}

const vat = (e: IElement): number => {
  return NumberHelper.makeFloat((subtotal(e) * Number(e.vatPercentage)) / 100)
}

const total = (e: IElement): number => {
  return subtotal(e) + vat(e)
}

export default {
  clientPrice: clientPrice,
  quantity: quantity,
  subtotal: subtotal,
  vat: vat,
  total: total,
}
