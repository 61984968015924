

























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import CheckboxGroup from '@/components/core/CheckboxGroup.vue'
import Space from '@/components/layout/Space.vue'
import DropdownSelectItem from '@/components/core/dropdown/DropdownSelectItem.vue'
import { IFrequency } from '@/shared/billie/api/serviceRequests'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'

@Component({
  components: { CheckboxGroup, Space, DropdownSelectItem, Tooltip }
})
export default class FrequencyInput extends Vue {
  @Prop({ required: true }) value: IFrequency | null

  showFrequncyOptions = true
  frequencyType: null | string = null
  frequencyValue: null | string = null
  options = ['once', 'everyDay', 'perWeek', 'perMonth', 'perQuarter', 'perYear', 'outOfStock', 'other']
  days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  times = Array.from(Array(8), (_, x) => (x + 1).toString())
  $refs: { otherInput: HTMLElement }

  isMobileDevice: boolean = document.body.clientWidth <= 768

  @Watch('value')
  inFrequencyChange(value: IFrequency | null) {
    this.populateFrequency(value)
  }

  populateFrequency(value = this.value) {
    if (value) {
      this.frequencyType = value.type
      this.frequencyValue = value.value ? value.value.toString() : value.days ? value.days.toString() : null
    }
  }

  isChecked(option: string) {
    if (this.value && option === this.value.type) {
      return true
    } else {
      false
    }
  }

  itemSelected(event: Event) {
    let newSelected = (event.target as HTMLElement).getAttribute('data-value')
    this.showFrequncyOptions = this.isMobileDevice ? true : false
    if (this.frequencyType !== newSelected) {
      this.frequencyValue = null
    }
    this.frequencyType = newSelected
    if (newSelected === 'other') {
      this.frequencyValue = ''
      this.$nextTick(() => {
        Array.isArray(this.$refs.otherInput) ? this.$refs.otherInput[0].focus() : this.$refs.otherInput.focus()
      })
    }
    if (!this.frequencySecondChoice && !this.isMobileDevice) {
      this.$emit('closeDropdown')
    }
    this.onValueChange()
  }

  handleCheckboxValue(value: string[]) {
    this.frequencyValue = value.toString()
    this.onValueChange()
  }

  onValueChange() {
    if (this.frequencyType && !this.frequencyValue) {
      this.frequencyType === 'other'
        ? this.$emit('input', { type: this.frequencyType, value: '' })
        : this.$emit('input', { type: this.frequencyType })
    } else if (this.frequencyType && this.frequencyValue) {
      let frequencyObject: any = {
        type: this.frequencyType
      }
      this.frequencyType === 'perWeek'
        ? (frequencyObject.days = this.frequencyValue.split(','))
        : this.frequencyType === 'other'
        ? (frequencyObject.value = this.frequencyValue)
        : (frequencyObject.value = parseInt(this.frequencyValue))
      this.$emit('input', frequencyObject)
    }
  }

  get frequencySecondChoice() {
    if (this.frequencyType) {
      switch (this.frequencyType) {
        case 'perMonth':
        case 'perQuarter':
        case 'perYear':
          return 'number'
        case 'perWeek':
          return 'days'
        case 'other':
          return 'input'
        default:
          this.showFrequncyOptions = true
          return null
      }
    } else {
      return null
    }
  }

  inputChange(e: InputEvent) {
    const target = e.target as HTMLTextAreaElement
    const parent = target.parentNode as HTMLDivElement
    parent.dataset.value = target.value
    this.onValueChange()
  }

  created() {
    this.populateFrequency()
  }
}
