










































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import moment from 'moment'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import ChatModule from '@/components/chat/ChatModule.vue'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'
import Button from '@/components/core/Button.vue'
import IconButton from '@/components/core/IconButton.vue'
import Space from '@/components/layout/Space.vue'
import ChatPageTemplate from '@/components/ChatPageTemplate.vue'
import routerGoBack from '@/mixins/routerGoBack'

const userState = getModule(Auth)
@Component({
  components: {
    ChatPageTemplate,
    Flex,
    Space,
    Button,
    IconButton,
    ChatModule,
    Heading,
    LabelNormal
  },
  mixins: [routerGoBack]
})
export default class SubscriptionPage extends Vue {
  country: string | undefined = userState.user.client?.countryCode
  openingHours: string | undefined = ''
  get chatId() {
    return userState.clientId
  }
  get henry() {
    return require('@/assets/henry_square.svg')
  }
  getOpeningHours() {
    const monday = moment().day(1).format('ddd')
    const friday = moment().day(5).format('ddd')
    this.openingHours =
      this.country === 'DK' ? `${monday} - ${friday}: 09:00 - 17:00` : `${monday} - ${friday}: 09:00 - 17:30`
  }
  async created() {
    this.getOpeningHours()
  }
}
