










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class RequestInputTab extends Vue {
  @Prop({ required: true }) type: string
  @Prop() active: boolean
  @Prop({ required: true }) icon: string
  @Prop() label: string
}
