












import LoginForm from '@/components/LoginForm.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import jwt from 'jsonwebtoken'

const AuthState = getModule(Auth)

@Component({
  components: { LoginForm }
})
export default class LoginPage extends Vue {
  error: null | Error = null
  loading = false
  AuthState = AuthState
  $t: (str: string) => ''

  get resetMessage() {
    return this.$route.query['reset-password'] !== undefined && !this.error
      ? this.$t('labels.login.passwordReset')
      : null
  }

  mounted() {
    const error = this.$route.query.error as string
    if (error) {
      this.error = {
        name: 'Error',
        message:
          error === 'login-failure' ? this.$t('labels.login.incorrectEmail') : this.$t('labels.login.noSuperadminLogin')
      }
    }
  }

  get errorText() {
    const error = this.$route.query.error as string
    if (error) {
      return {
        name: 'Error',
        message:
          error === 'login-failure' ? this.$t('labels.login.incorrectEmail') : this.$t('labels.login.noSuperadminLogin')
      }
    } else {
      return null
    }
  }

  async login({ strategy, email, password }: { email: string; password: string; strategy: string }) {
    this.loading = true
    this.AuthState.authenticate({ strategy, email, password })
      .then(async token => {
        if (token) {
          if ((jwt as any).decode(token).gm_role !== 'superadmin') {
            window.location.href = (this.$route.query.redirect as string) || '/'
          } else {
            localStorage.clear()
            this.error = {
              name: 'Error',
              message: this.$t('labels.login.noSuperadminLogin')
            }
          }
        }
      })
      .catch((e: Error) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
