

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import DropdownRow from './DropdownRow.vue'
import DropdownRowTitle from './DropdownRowTitle.vue'
import SearchStore from '@/store/modules/searchStore'
import { getModule } from 'vuex-module-decorators'
const searchStore = getModule(SearchStore)

@Component({
  components: { DropdownRow, DropdownRowTitle }
})
export default class SearchPopularServices extends Vue {
  @Prop() talkToUsClickedCallback: () => void
  searchStore = searchStore
}
