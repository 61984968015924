import { render, staticRenderFns } from "./FrequencyInput.vue?vue&type=template&id=25b95800&scoped=true&"
import script from "./FrequencyInput.vue?vue&type=script&lang=ts&"
export * from "./FrequencyInput.vue?vue&type=script&lang=ts&"
import style0 from "./FrequencyInput.vue?vue&type=style&index=0&id=25b95800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b95800",
  null
  
)

export default component.exports