import Vue from 'vue'
import { config } from './config'

/**
 * Good Monday plugins
 */
import Amplitude from './plugins/Amplitude'
import Http from './plugins/Http'
import Sentry from './plugins/Sentry'
import Storyblok from './plugins/Storyblok'

import 'reflect-metadata'
import App from './App.vue'
import router from './router'
import store from './store/store'

import VueI18n from 'vue-i18n'
import Billie from '@/shared/billie'
import translations from './i18n/i18n'
import themeService from '@/services/themeService'
import Vue2TouchEvents from 'vue2-touch-events'
// @ts-ignore
import VueMq from 'vue-mq'
import browserDetect from 'vue-browser-detect-plugin'
import globals from './globals'
import Notifications from 'vue-notification'
import VueLazyload from 'vue-lazyload'
// @ts-ignore
import VueTippy, { TippyComponent } from 'vue-tippy'
import PortalVue from 'portal-vue'

const locale = config.LOCALES.supported.includes(navigator.language) ? navigator.language : config.LOCALES.default
const logger = window.console
const token = localStorage.getItem(globals().accessTokenKey)

Vue.prototype.$appName = 'platform'
Vue.prototype.$locale = locale
Vue.prototype.$logger = logger
Vue.prototype.$token = token

/***************************
 *** GOOD MONDAY PLUGINS ***
 ***************************/

/**
 * Amplitude
 *
 * Configure and setup an Amplitude instance. Available through Vue.prototype.$amplitude.
 * More info at https://github.com/amplitude/amplitude-javascript
 */
Vue.use(Amplitude, { apiKey: config.AMPLITUDE_API_KEY })

/**
 * Http
 *
 * Configure base URLs for HTTP and Socket connections + authentication headers.
 */
Vue.use(Http, { baseURL: config.API_BASE_URL, baseSocketURL: config.SOCKET_BASE_URL, token: token })

/**
 * Sentry
 *
 * Configure logging with Sentry for production environments
 */
Vue.use(Sentry, { token: token })

/**
 * Storyblok
 *
 * Sets up the storyblok access key. Available through Vue.prototype.$storyblokKey.
 */
Vue.use(Storyblok, { token: token, accessKeyDK: config.STORYBLOK_DK_KEY, accessKeyUK: config.STORYBLOK_UK_KEY })

/***************************
 *** THIRD PARTY PLUGINS ***
 ***************************/

/**
 * VueMQ
 *
 * Configures VueMQ to expose media queries through Vue.prototype.$mq.
 * TODO: Add Storybook doc and link
 * More info at https://github.com/AlexandreBonaventure/vue-mq
 */
Vue.use(VueMq, { breakpoints: { mobile: 768, tablet: 1024, desktop: 1200, desktopLg: 1440, desktopXl: Infinity } })

/**
 * BrowserDetect
 *
 * Configures BrowserDetect to allow queries through Vue.prototype.$browserDetect.
 * More info at https://github.com/ICJIA/vue-browser-detect-plugin
 */
Vue.use(browserDetect)

/**
 * Notifications
 *
 * Configures Notifications and exposes function to show toast through Vue.prototype.$notify.
 * * TODO: Add Storybook doc and link
 * More info at https://github.com/euvl/vue-notification
 */
Vue.use(Notifications)

/**
 * VueLazyLoad
 *
 * Give access to the v-lazy directive.
 * More info at https://github.com/hilongjw/vue-lazyload
 */
Vue.use(VueLazyload, { preLoad: 1.3, attempt: 2 })

/**
 * PortalVue
 *
 * For rendering DOM outside of a component, anywhere in your app or the entire document.
 * More info at https://github.com/linusborg/portal-vue
 */
Vue.use(PortalVue)

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
      hide: { enabled: false },
    },
  },
})
Vue.component('tippy', TippyComponent)

Vue.prototype.$billie = new Billie(Vue.prototype.$axios)

Vue.use(VueI18n)
Vue.use(Vue2TouchEvents)

themeService.setTheme('orange')

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: config.LOCALES.default,
  messages: translations,
})

Vue.config.productionTip = false

// Vue.config.errorHandler = function() {
//   //TODO: Show error messages to the user here
//   //Vue.prototype.$notify({ type: 'error', title: err.message })
// }

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
