






import Vue from 'vue'
import Component from 'vue-class-component'
@Component({
  components: {}
})
export default class List extends Vue {}
