


































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ImagePreviewModal from '@/components/core/modal/ImagePreviewModal.vue'
import AttachmentHelper from '@/services/helpers/attachmentHelper'

@Component({
  components: { ImagePreviewModal }
})
export default class Button extends Vue {
  @Prop({ required: false, default: null }) text: string | null
  @Prop({ required: false, default: [] }) attachments: string[]
  @Prop({ required: false, default: false }) isReply: boolean

  showModal = false

  get hasAttachments() {
    return this.attachments.length > 0
  }

  get isAugmentedText() {
    const urlRegex =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g
    const requestPageRegex = /(localhost|goodmonday).*\/requests$/g

    if (this.text) {
      let messageText = this.text
      const augmented = messageText.replace(urlRegex, url => {
        const isRequestPage = requestPageRegex.test(url)
        if (isRequestPage) {
          return `<a href="${url}">${this.$t('requests.createRequest')}</a>`
        } else {
          return `<a target='_blank' href="${url}">${url}</a>`
        }
      })
      return augmented !== this.text ? augmented : ''
    } else {
      return this.text
    }
  }

  openModal() {
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }

  checkIsImage(fileName: string) {
    return AttachmentHelper.checkIsImage(fileName)
  }

  fileUrl(filename: string) {
    /**
     * TODO: File token in local storage? Meh ..
     */
    const fileToken = localStorage.getItem('file_token')
    return `${this.$baseURL}/api/file/${filename}/original/${fileToken}`
  }
}
