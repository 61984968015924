






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IRevision } from '@/shared/services/api.interface'
import ProposalElements from '@/components/proposals/ProposalElements.vue'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'

@Component({ components: { ProposalElements, LabelNormal } })
export default class WhatV2 extends Vue {
  @Prop() revisions: IRevision[]
  @Prop() currency: string
  @Prop({ default: false }) showRecurrence: boolean
}
