import { render, staticRenderFns } from "./SupplierCategoryCard.vue?vue&type=template&id=cdc6c364&scoped=true&"
import script from "./SupplierCategoryCard.vue?vue&type=script&lang=ts&"
export * from "./SupplierCategoryCard.vue?vue&type=script&lang=ts&"
import style0 from "./SupplierCategoryCard.vue?vue&type=style&index=0&id=cdc6c364&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdc6c364",
  null
  
)

export default component.exports