































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class BModal extends Vue {
  @Prop() size?: string
  @Prop() scrollable?: boolean

  isShowing = false

  show() {
    this.isShowing = true
  }

  hide() {
    this.isShowing = false
  }
}
