











































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IElement } from '@/shared/services/api.interface'
import ProposalsList from './ProposalsList.vue'
import FormattedPrice from '@/components/FormattedPrice.vue'
import Element from '@/services/rpa/element'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import Space from '@/components/layout/Space.vue'
import Flex from '@/components/layout/Flex.vue'

@Component({
  components: {
    ProposalsList,
    FormattedPrice,
    LabelNormal,
    Space,
    Flex
  }
})
export default class ProposalElements extends Vue {
  @Prop({ required: true }) elements!: IElement[]
  @Prop({ required: true }) currency!: string
  @Prop({ default: false }) showRecurrence: boolean
  isMobileDevice: boolean = document.body.clientWidth <= 768

  get subtotal() {
    return this.elements.map(Element.subtotal).reduce((sum, amount) => sum + amount, 0)
  }

  get vat() {
    return this.elements.map(Element.vat).reduce((sum, amount) => sum + amount, 0)
  }

  get total() {
    return this.elements.map(Element.total).reduce((sum, amount) => sum + amount, 0)
  }

  created() {
    window.addEventListener('resize', this.resizeHandler)
  }
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler(e: Event) {
    this.isMobileDevice = (e.target as Window).outerWidth <= 768
  }

  get isMobile() {
    return this.isMobileDevice
  }
}
