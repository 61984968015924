
























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import SupplierApi from '@/shared/services/api/supplierApi'
import { ISupplierData, ISuppliersService } from '@/shared/components/interfaces/supplierData.interface'
import { IServiceResponse } from '@/shared/services/api.interface'
import LabelField from '@/components/legacy_components/LabelField.vue'
import LeadText from '@/components/legacy_components/LeadText.vue'
import SupplierProfilePicture from '@/components/legacy_components/SupplierProfilePicture.vue'
import CardElement from '@/components/explore/CardElement.vue'
import SupplierAboutSection from '@/components/suppliers/SupplierAboutSection.vue'
import AmplitudeHelper from '../../services/helpers/amplitudeHelper'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'
import Grid from '@/components/layout/Grid.vue'
import Space from '@/components/layout/Space.vue'

@Component({
  components: {
    Flex,
    Grid,
    Space,
    Heading,
    CardElement,
    LabelField,
    LeadText,
    SupplierAboutSection,
    SupplierProfilePicture
  }
})
export default class SupplierProfile extends Vue {
  serviceData: ISuppliersService | IServiceResponse | null = null
  companyData: ISupplierData | null = null
  aboutSectionShowLength = 300
  activeService: ISuppliersService | null = null

  @Watch('$route')
  onPropertyChanged() {
    this.loadData()
  }

  async mounted() {
    this.loadData()
  }

  async loadData() {
    this.companyData = await SupplierApi.getSupplierData(this.$route.params.supplierId)
    await this.getReviewsData(this.companyData!.offerings[0])
    AmplitudeHelper.amplitude
      .getInstance()
      .logEvent('Marketplace - Supplier Profile', { Supplier: this.companyData?.name })
  }

  async getReviewsData(service: ISuppliersService) {
    if (service) {
      this.serviceData = await SupplierApi.getSupplierServiceData(service.id)
      this.activeService = service
    }
  }
}
