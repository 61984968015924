










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class ModalStep extends Vue {
  @Prop({ default: [], required: true }) steps: number[]
  @Prop({ default: 1, required: true }) activeStep: number
  @Prop({ required: false, default: false }) center: boolean
  @Prop({ required: false, default: false }) middle: boolean
}
