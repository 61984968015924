





















































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import { IInvoice } from '@/shared/components/interfaces/invoices.interface'
import InvoiceApi from '@/shared/services/api/invoiceApi'
import moment from 'moment'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'
import Price from '@/components/core/Price.vue'
import Tag from '@/components/ui/Tag.vue'
import List from '@/components/core/list/List.vue'
import ListItem from '@/components/core/list/ListItem.vue'
import Label from '@/components/core/Label.vue'
import Space from '@/components/layout/Space.vue'
import EmptyCard from '@/components/legacy_components/EmptyCard.vue'

const UserState = getModule(Auth)

@Component({
  components: { Flex, Space, Heading, List, ListItem, Label, Price, EmptyCard, Tag }
})
export default class Invoices extends Vue {
  UserState = UserState
  invoiceData: IInvoice[] = []
  showProgressBar = false

  async created() {
    this.invoiceData = await InvoiceApi.getInvoices(this.UserState.clientId)
    this.invoiceData = this.sortedInvoices()
  }

  sortedInvoices() {
    return this.invoiceData.sort((a, b) => {
      //@ts-ignore
      return moment(b.dueDate) - moment(a.dueDate)
    })
  }

  async downloadInvoice(invoiceId: string, _invoiceNumber: number) {
    this.showProgressBar = true
    const pdfData = await InvoiceApi.getInvoicePdf(invoiceId)
    if (pdfData) {
      const blob = new Blob([pdfData], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      this.showProgressBar = false
      window.open(url, '_blank')
    }
  }

  formattedDate(date: string) {
    return moment(date).format('ddd DD MMM YYYY')
  }
}
