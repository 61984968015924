




































import { Component, Prop, Vue } from 'vue-property-decorator'
import CircularProgressBar from '@/components/legacy_components/CircularProgressBar.vue'
import FormWizardNav from '@/components/signup/FormWizardNav.vue'
import Button from '../../components/core/Button.vue'
import Heading from '@/components/core/Heading.vue'

@Component({
  components: { CircularProgressBar, FormWizardNav, Button, Heading }
})
export default class FormWizardUsePlatform extends Vue {
  @Prop({ default: '' }) errorMessage: string
  @Prop({ default: '' }) loadingState: 'started' | 'done'
  @Prop({ required: true }) tabSize: number
  @Prop({ required: true }) activeTab: number
  $t: (str: string) => ''

  get title(): string {
    if (this.errorMessage) return this.$t('labels.signup.errorTitle')
    return this.$t('labels.signup.thankyouMessage')
  }

  get sectionTitle(): string {
    if (!this.errorMessage) return this.$t('labels.signup.readyForPlatform')
    return this.errorMessage
  }

  get buttonText(): string {
    if (this.loadingState === 'started') return this.$t('labels.doingJustThat')
    if (this.loadingState === 'done') return this.$t('labels.done')
    return this.$t('labels.logMeIn')
  }

  login(e: any) {
    e.preventDefault()
    e.stopPropagation()
    this.$emit('continue')
  }
}
