

















import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class AddAttachmentItem extends Vue {
  @Prop({ required: true }) uploadInProgress: boolean
  @Prop({ default: true }) showAddFile: boolean

  file: File | null = null
  $refs: { fileInput: HTMLInputElement }

  addFile() {
    if (!this.file) {
      this.$refs.fileInput.click()
    }
  }

  handleUploadFile() {
    this.file = this.$refs.fileInput.files![0]
    if (!this.file) return
    this.$emit('addFile', this.file)
    this.$refs.fileInput.value = ''
    this.file = null
  }
}
