




























import { Component, Prop, Vue } from 'vue-property-decorator'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import ShowText from '@/components/suppliers/ShowText.vue'

@Component({
  components: {
    ShowText,
    LabelNormal
  }
})
export default class CardElement extends Vue {
  @Prop() image: string
  @Prop() companyLogo: string
  @Prop() serviceName: string
  @Prop() shortDescription: string
  @Prop() link: string
  @Prop({ default: true }) showLogo: boolean
  @Prop({ default: 36 }) xslimit: number
  @Prop({ default: 48 }) smlimit: number
  @Prop({ default: 52 }) mdlimit: number
  @Prop({ default: 64 }) lglimit: number

  windowWidth = 0
  get hideFrom() {
    if (this.windowWidth < 769) {
      return this.xslimit
    } else if (this.windowWidth > 768 && this.windowWidth < 1025) {
      return this.smlimit
    } else if (this.windowWidth > 1024 && this.windowWidth < 1200) {
      return this.mdlimit
    } else if (this.windowWidth > 1199) {
      return this.lglimit
    } else {
      return this.lglimit
    }
  }
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize() {
    this.windowWidth = window.innerWidth
  }
}
