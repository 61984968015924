import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'
import { IServiceResponse } from '@/shared/services/api.interface'

@Module({
  name: 'Services',
  dynamic: true,
  store: store,
})
export default class Services extends VuexModule {
  initialServices: { [key: string]: IServiceResponse } = {}

  services = { ...this.initialServices }

  get allServices() {
    return this.services
  }

  @Mutation
  public setServices(payload: IServiceResponse[]) {
    for (const service of payload) {
      this.services[service.id] = service
    }
  }

  @Action({ commit: 'setServices' })
  public async getServices(services: IServiceResponse[]) {
    return services
  }
}
