




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IProposal } from '@/shared/services/api.interface'
import Modal from '@/components/core/modal/Modal.vue'
import ModalStep from '@/components/core/modal/ModalStep.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import Button from '@/components/core/Button.vue'
import WhatV2 from '@/components/agreements/WhatV2.vue'
import WhoAndWhere from '@/components/proposals/WhoAndWhere.vue'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import Tag from '@/components/ui/Tag.vue'
import IconButton from '@/components/core/IconButton.vue'
import Emoji from '@/components/core/Emoji.vue'
import AttachmentItem from '@/components/core/AttachmentItem.vue'
import Flex from '@/components/layout/Flex.vue'

import { PAGES } from '@/globals'

const userState = getModule(Auth)

@Component({
  components: {
    Modal,
    ModalStep,
    ModalHeader,
    Space,
    Label,
    Button,
    WhatV2,
    WhoAndWhere,
    Tag,
    IconButton,
    Emoji,
    AttachmentItem,
    Flex
  }
})
export default class RequestProposalModal extends Vue {
  @Prop({ required: true }) proposals: IProposal[]
  @Prop({ required: true }) selectedProposal: IProposal
  @Prop({ required: false }) proposalStatus: { status: string; icon: string; text: string }
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) isShowing: boolean
  stepIndex = 1

  get acceptedStatus() {
    return this.selectedProposal.accepted === null ? 'pending' : this.selectedProposal.accepted
  }

  get lastProposal() {
    return this.proposals.filter(proposal => proposal.accepted === null).length === 1
  }

  get hasAcceptedProposals() {
    return this.proposals.filter(proposal => proposal.accepted).length > 0
  }

  get listUntouchedProposals() {
    return this.proposals.reduce((prev, curr) => prev + (curr.accepted === null ? `<li>${curr.title}</li>` : ''), '')
  }

  get modalContent() {
    if (this.proposals.filter(proposal => proposal.accepted === null).length > 0) {
      // Accepted and there is more proposals
      return {
        emoji: '☝️',
        title: this.$t('requests.modal.completeModal.accepted.title'),
        text: `<p>${this.$t('requests.modal.completeModal.accepted.text')}</p><br/>
      				<span style="color: #c43a33;">${this.$t('requests.modal.completeModal.accepted.text2')}<br/><br/>
      				<div style="width: 70%; margin: 0 auto;">
      				<ul style="text-align: left;">
      				${this.listUntouchedProposals}
      				</ul>
      				</div></span>
      				<br/>${this.$t('requests.modal.completeModal.accepted.text3')}`,
        moreButton: this.$t('requests.modal.completeModal.accepted.moreButton'),
        closeButton: this.$t('requests.modal.completeModal.accepted.closeButton')
      }
    } else if (this.selectedProposal.accepted === false) {
      // All rejected
      return {
        emoji: '😕',
        title: this.$t('requests.modal.completeModal.allRejected.title'),
        text: this.$t('requests.modal.completeModal.allRejected.text'),
        moreButton: this.$t('requests.modal.completeModal.allRejected.moreButton'),
        closeButton: this.$t('requests.modal.completeModal.allRejected.closeButton')
      }
    } else {
      // Default
      return {
        emoji: '😉',
        title: this.$t('requests.modal.completeModal.default.title'),
        text: this.$t('requests.modal.completeModal.default.text'),
        moreButton: this.$t('requests.modal.completeModal.default.moreButton'),
        closeButton: this.$t('requests.modal.completeModal.default.closeButton')
      }
    }
  }

  async rejectProposal(proposal: IProposal) {
    if (this.lastProposal) {
      this.selectedProposal.accepted = false
      this.stepIndex = 2
    } else {
      this.selectedProposal.accepted = false
      this.onClose()
    }
    this.trackProposalActionEvent('Rejected')
    await this.$billie.proposals.reject(proposal.id)
  }

  async acceptProposal(proposal: IProposal) {
    this.selectedProposal.accepted = true
    this.stepIndex = 2
    this.trackProposalActionEvent('Accepted')
    await this.$billie.proposals.accept(proposal.id)
  }

  async handleCloseRequest() {
    await this.$billie.serviceRequests.close(userState.clientId, this.$route.params.id)
    this.trackRequestActionEvent('Close')
    this.$router.push({ name: PAGES.DASHBOARD })
  }

  handleKeepAliveRequest() {
    this.trackRequestActionEvent('Keep')
    this.onClose()
  }

  trackProposalActionEvent(action: string) {
    this.$amplitude.logEvent('Request - Proposal - ' + action)
  }

  trackRequestActionEvent(action: string) {
    this.$amplitude.logEvent('Request - ' + action)
  }
}
