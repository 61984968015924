import axios from './interceptor'
import { AxiosResponse } from 'axios'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
const userState = getModule(Auth)
class SearchApi {
  static async search(term: string) {
    if (userState.user.serviceArea) {
      return axios
        .get(`/api/area/${userState.user.serviceArea}/search/${term}`)
        .then((resp: AxiosResponse) => resp.data)
    } else {
      return axios.get(`/api/search/${term}`).then((resp: AxiosResponse) => resp.data)
    }
  }
}

export default SearchApi
