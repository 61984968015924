import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'ClientStateStore',
  dynamic: true,
  store: store,
})
export default class ClientStateStore extends VuexModule {
  clientState: {
    overdueNotificationLevel: number | null | undefined
    status: 'active' | 'under_termination' | 'terminated'
    modalShown: boolean
  } = {
    overdueNotificationLevel: undefined,
    status: 'active',
    modalShown: false,
  }

  get showModal() {
    return this.clientState.modalShown
  }

  get stateOfClient() {
    return this.clientState
  }

  get notificationLvlOriginal() {
    return this.clientState.overdueNotificationLevel
  }

  get notificationLvl() {
    return this.clientState.overdueNotificationLevel ?? 0
  }

  get functionalityBlocked() {
    return this.notificationLvl && this.notificationLvl >= 3
  }

  get status() {
    return this.clientState.status
  }

  get terminated() {
    return this.clientState.status === 'terminated'
  }

  @Mutation
  public mutateState(stateObj: {
    overdueNotificationLevel?: number | null
    status?: 'active' | 'under_termination' | 'terminated'
  }) {
    if (stateObj.overdueNotificationLevel) {
      this.clientState.overdueNotificationLevel = stateObj.overdueNotificationLevel
    }
    if (stateObj.status) {
      this.clientState.status = stateObj.status
    }
  }

  @Mutation
  public hideShowModal(show: boolean) {
    this.clientState.modalShown = show
  }
}
