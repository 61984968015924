export const config = {
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
  SOCKET_BASE_URL: process.env.VUE_APP_SOCKET_BASE_URL,
  AMPLITUDE_API_KEY: process.env.VUE_APP_AMPLITUDE_API_KEY,
  /**
   * SUNRISE_AIRTABLE_DB IS ONLY USED FOR SUNRISE EXPERIMENT
   */
  SUNRISE_AIRTABLE_DB: process.env.VUE_APP_SUNRISE_AIRTABLE_DB,
  STORYBLOK_DK_KEY: 'azLmh46wamn95yy1OwHRxQtt',
  STORYBLOK_UK_KEY: 'Dz5O5FSaTfbC25yX3QMZ3wtt',
  LOCALES: {
    default: 'en',
    supported: ['en', 'da', 'da-DK'],
  },
  LUNCH_CLIENTS: [
    /**********
     * LOCAL *
     **********/

    // Good Monday
    'dfa8b5c2-83d8-479b-a7fd-5a146a724b0a',

    /**********
     * CONVAL *
     **********/

    // Denmark ApS
    '7ab2f1c6-e80c-4333-ade4-b4903fdb988e',

    /********
     * DEMO *
     ********/

    // Spaceti
    '675ef506-b3f1-48f4-abfe-f4dcaebafd21',

    /********
     * PROD *
     ********/

    // The Real Good Monday
    '72874ea7-13a7-46f3-8fda-d587cb572ce5',

    // Astralis
    '2d22982a-dc63-42d0-ab91-cd9fcae7afea',

    // Knowit Consulting Service
    '05233c34-09c8-462c-96fc-82c8efe29533',

    // Blackwood Seven
    '3e867caf-9871-4520-9fb1-8e9582c8116d',

    // Direktionen ApS
    '1a735b77-f909-4ad3-b0f6-14bdb1de8b12'
  ],
}
