























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { IClient, IClientPreferences } from '../../shared/components/interfaces/clients.interface'
import UserPreferencesApi from '@/shared/services/api/userPreferencesApi'
import Auth from '@/shared/storeModules/auth'
import SwitchInput from '@/components/legacy_components/SwitchInput.vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'

const userState = getModule(Auth)

@Component({
  components: { Flex, Space, Heading, SwitchInput }
})
export default class ClientPrefrences extends Vue {
  @Prop({ required: true }) client: IClient
  userState = userState
  emailPreferences = false
  preferences: IClientPreferences = {
    chatNotificationsEnabled: false
  }
  isLoaded = false

  async created() {
    this.preferences = (await UserPreferencesApi.getUserPrefrences(
      this.client.id,
      this.userState.user.id!
    )) as IClientPreferences
    this.emailPreferences = this.preferences.chatNotificationsEnabled
    this.isLoaded = true
  }

  async toggle(value: boolean) {
    if (this.preferences.chatNotificationsEnabled !== value) {
      try {
        await UserPreferencesApi.updateUserEmailPrefrences(this.client.id, this.userState.user.id!, value)
        this.emailPreferences = value
        this.preferences.chatNotificationsEnabled = value
      } catch (error) {
        //TODO: show error
      }
    }
  }
}
