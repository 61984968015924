











































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'
import TextField from '@/components/legacy_components/TextField.vue'
import CustomCheckboxInput from '@/components/legacy_components/CustomCheckboxInput.vue'
import FormWizardNav from '@/components/signup/FormWizardNav.vue'
import Button from '@/components/core/Button.vue'
import moment from 'moment'
import Price from '@/components/core/Price.vue'
import Heading from '@/components/core/Heading.vue'

@Component({
  components: {
    TextField,
    CustomCheckboxInput,
    Button,
    FormWizardNav,
    Price,
    Heading
  },
  mixins: [validationMixin],
  validations: {
    profile: {
      firstName: { required },
      lastName: { required },
      position: { required },
      gdpr: { isChecked: value => value },
      acceptsTerms: { isChecked: value => value }
    }
  }
})
export default class FormWizardCreateProfile extends Vue {
  @Prop({ required: true }) inviteData!: IInvitee
  @Prop({ required: true }) tabSize: number
  @Prop({ required: true }) activeTab: number
  $t: (str: string) => ''
  profile = {
    firstName: '',
    lastName: '',
    position: '',
    phone: this.inviteData.phone,
    acceptsTerms: false,
    gdpr: false
  }

  isMobile = window.innerWidth <= 768

  get getTrialEndDate() {
    return moment(this.inviteData.trialExpirationDate).format('DD/MM/YYYY')
  }

  get disableSaveButton(): boolean {
    return !Object.keys(this.profile).every(key => {
      if (key === 'phone') return true
      if (key === 'acceptsTerms') {
        if (this.inviteData.shouldAcceptTerms) {
          return (this.profile as any)[key]
        } else {
          return true
        }
      }
      return !!(this.profile as any)[key]
    })
  }

  get firstNameValidationMessage(): string {
    let error = ''
    if (!this.$v.profile.firstName!.$dirty) error = ''
    else if (!this.$v.profile.firstName!.required) error = this.$t('labels.signup.firstNameRequiredMessage')
    return error
  }

  get lastNameValidationMessage(): string {
    let error = ''
    if (!this.$v.profile.lastName!.$dirty) error = ''
    else if (!this.$v.profile.lastName!.required) error = this.$t('labels.signup.lastNameRequiredMessage')
    return error
  }

  get positionValidationMessage(): string {
    let error = ''
    if (!this.$v.profile.position!.$dirty) error = ''
    else if (!this.$v.profile.position!.required) error = this.$t('labels.signup.positionRequiredMessage')
    return error
  }

  get acceptsTermsValidationMessage(): string {
    let error = ''
    if (!this.$v.profile.acceptsTerms!.$dirty) error = ''
    else if (!this.$v.profile.acceptsTerms!.isChecked) error = this.$t('labels.signup.termsRequiredMessage')
    return error
  }

  get gdprValidationMessage(): string {
    let error = ''
    if (!this.$v.profile.gdpr!.$dirty) error = ''
    else if (!this.$v.profile.gdpr!.isChecked) error = this.$t('labels.signup.gdprRequiredMessage')
    return error
  }

  saveChanges(): void {
    let data = Object.assign({}, this.profile)
    this.$emit('continue', data)
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768
    })
  }
}
