









import Vue from 'vue'

const Tag = Vue.extend({
  props: {
    icon: { type: String, default: '' },
    theme: { type: String, default: '' }
  }
})
export default Tag
