















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class DropdownSelectItem extends Vue {
  @Prop({ default: false }) checkboxStyle: boolean
  @Prop({ default: false }) checked: boolean
  @Prop() option: string

  handleClick(e: Event) {
    this.$emit('click', e)
  }
}
