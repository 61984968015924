



























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Modal from '@/components/core/modal/Modal.vue'
import ModalStep from '@/components/core/modal/ModalStep.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import Button from '@/components/core/Button.vue'
import Flex from '@/components/layout/Flex.vue'
import { ITimeframe } from '@/shared/components/interfaces/request.interface'
import moment from 'moment'
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Heading from '@/components/core/Heading.vue'
import Emoji from '@/components/core/Emoji.vue'
import RequestInputTab from '@/components/requests/RequestInputTab.vue'

@Component({
  components: { Modal, ModalStep, ModalHeader, Heading, Space, Flex, Label, Button, DatePicker, Emoji, RequestInputTab }
})
export default class TimeframeModal extends Vue {
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) isShowing: boolean
  @Prop({ default: {} }) value: ITimeframe | null
  @Prop() locale: string
  @Prop() selectedDates: any
  @Prop() stepIndex: number
  @Prop() type: 'date' | 'between' | 'asap' | 'before' | 'after'
  @Prop() startDate: Date | null
  @Prop() endDate: Date | null
  @Prop() time: string | null
  @Prop() contentStyleOrange: any
  @Prop() selectAttribute: any
  @Prop() selectRangeAttribute: any

  get disableButton() {
    return this.stepIndex === 3 && this.type === 'asap' ? false : this.endDate ? false : true
  }

  onClick() {
    switch (this.stepIndex) {
      case 3:
        this.$emit('setDate', {
          type: this.type,
          from: moment(this.startDate).format('YYYY-MM-DD'),
          to: moment(this.endDate).format('YYYY-MM-DD')
        })
        break
      case 2:
        this.$emit('setDate', { type: this.type })
        break
      default:
        this.$emit('setDate', { type: this.type, date: moment(this.startDate).format('YYYY-MM-DD') })
    }
    this.onClose()
  }
}
