






















import { IClientOffering } from '@/shared/components/interfaces/agreements.interface'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import SubscriptionsHelper from '@/services/helpers/subscriptionsHelper'
import Tag from '@/components/ui/Tag.vue'
import moment from 'moment'

@Component({
  components: { InlineSvg, Tag }
})
export default class SubscriptionCarousel extends Vue {
  @Prop() subscription: IClientOffering
  @Prop() hasNotifications: boolean
  @Prop() link: string

  getCategoryIcon(category: IClientOffering): string {
    return SubscriptionsHelper.getCategoryIcon(category)
  }

  getLabelForBadge(category: IClientOffering) {
    switch (category.status) {
      case 'ending':
        return `${this.$t('common.dates.ending')} ${moment(category.agreementEnd).format('MMM. Do')}`
      case 'terminated':
        return this.$t('subscriptions.terminated')
      case 'pending':
        return `${this.$t('common.dates.starting')} ${moment(category.agreementStart).format('MMM. Do')}`
      default:
        return this.$t('common.active')
    }
  }

  trackClickEvent() {
    this.$amplitude.logEvent('Your Office - Subscription', {
      title: this.subscription.title,
      hasNotifications: this.hasNotifications
    })
  }
}
