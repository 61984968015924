










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ButtonPrimary extends Vue {
  @Prop() text: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) fillWidth: boolean
  @Prop({ default: false }) backoffice: boolean

  btnClicked(e: Event) {
    e.preventDefault()
    this.$emit('callback', e)
  }
}
