


























import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import { IClientAccount } from '../../shared/components/interfaces/clients.interface'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'

const userState = getModule(Auth)

@Component({
  components: {
    Heading,
    Space
  }
})
export default class ClientInfo extends Vue {
  client: IClientAccount | null = null
  userState = userState

  get ClientInfoRoutes() {
    return this.$router.options.routes?.[0].children?.find(item => item.name == 'clientInfo')?.children
  }

  async created() {
    this.client = await this.$billie.clients.get(this.userState.clientId)
  }
}
