//? Amplitude Docs: https://developers.amplitude.com/docs/javascript

// @ts-ignore
import amplitude, { init } from 'amplitude-js'

class AmplitudeHelper {
  public static amplitude = amplitude
  public static init(apiKey: string, userId?: string, config?: any) {
    AmplitudeHelper.amplitude.getInstance().init(apiKey, userId, config)
  }
}
export default AmplitudeHelper
