






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class DropdownRowTitle extends Vue {
  @Prop() title: string
}
