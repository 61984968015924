




















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import uuid from 'uuid-random'
import moment from 'moment'
import { PAGES } from '@/globals'
import { IRequest, IRequestFormData } from '@/shared/components/interfaces/request.interface'
import Flex from '@/components/layout/Flex.vue'
import Heading from '@/components/core/Heading.vue'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'
import RequestForm from '@/components/requests/RequestForm.vue'
import RequestGuide from '@/components/requests/RequestGuide.vue'
import Modal from '@/components/core/modal/Modal.vue'
import Button from '@/components/core/Button.vue'
import IconButton from '@/components/core/IconButton.vue'
import Space from '@/components/layout/Space.vue'
import Emoji from '@/components/core/Emoji.vue'
import ClientStateStore from '@/store/modules/clientStateStore'
import ClientsApi from '@/shared/services/api/ClientsApi'
import ChatPageTemplate from '@/components/ChatPageTemplate.vue'
import routerGoBack from '@/mixins/routerGoBack'
import Tag from '@/components/ui/Tag.vue'
import { SunriseOffering, augmentOffering, OFFERING_DK_ID, OFFERING_UK_ID } from '@/pages/explore/sunrise/sunriseData'

const userState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

@Component({
  components: {
    Space,
    Flex,
    Tag,
    ChatPageTemplate,
    Heading,
    RequestGuide,
    Tooltip,
    RequestForm,
    Modal,
    Button,
    IconButton,
    Emoji
  },
  mixins: [routerGoBack]
})
export default class RequestPage extends Vue {
  featuredOffering: SunriseOffering | null = null
  clientStateStore = clientStateStore
  userState = userState
  request: IRequest | null = null
  showCancelPrompt = false
  showCompletePrompt = false
  uploadInProgress = false
  isEditable = true
  isCancelled = false
  form: IRequestFormData = {
    id: uuid(),
    title: '',
    description: '',
    timeframe: null,
    budget: null,
    people: null,
    frequency: null,
    location: '',
    serviceId: '',
    supplierId: '',
    offeringId: '',
    attachments: []
  }

  validationErrors: { [key: string]: any } = {
    noTitle: false
  }

  validateForm() {
    if (!this.form.title) {
      this.validationErrors.noTitle = true
    }
  }

  back() {
    this.goBack()
    if (this.request === null) {
      this.$amplitude.logEvent('Request Abandoned (Sunrise)', {
        offeringId: this.form.offeringId ? this.form.offeringId : null,
        serviceId: this.form.serviceId ? this.form.serviceId : null,
        supplierId: this.form.supplierId ? this.form.supplierId : null
      })
    }
  }

  async addFile(fileInput: File) {
    try {
      this.uploadInProgress = true
      let response = await this.$billie.fileUpload.upload(fileInput)
      this.uploadInProgress = false
      this.form.attachments.push(response.id)
    } catch (e) {
      this.uploadInProgress = false
    }
  }

  removeFile(index: number) {
    this.form.attachments.splice(index, 1)
  }

  onTitleChange() {
    this.validationErrors.noTitle = false
  }

  getReferrals() {
    if (this.request && this.request.referrals && (this.request.referrals.offering || this.request.referrals.service)) {
      return this.request.referrals.offering
        ? { id: this.request.referrals.offering?.id, name: this.request.referrals.offering?.name }
        : { id: this.request.referrals.service?.id, name: this.request.referrals.service?.name }
    }
    return null
  }

  get requestPageLabel() {
    const createdBy = this.request?.createdBy.firstName + ' ' + this.request?.createdBy.lastName
    const date = moment(this.request?.createdAt).format('MMMM D')
    return this.$t('requests.creationLabel', { date: date, createdBy: createdBy })
  }

  get enableChatForCustomersOnlyOnPlatform() {
    return userState.isCustomer
  }

  get hasAcceptedProposals() {
    return this.request?.proposals.filter(proposal => proposal.accepted)
  }

  async getRequest() {
    this.request = await this.$billie.serviceRequests.get(userState.clientId, this.$route.params.id)
    this.isEditable = this.request.status !== 'cancelled' && this.request.status !== 'completed'
    this.isCancelled = this.request && this.request.status === 'cancelled'
    this.form = Object.assign(
      {},
      {
        id: this.request.id,
        title: this.request.title,
        description: this.request.description,
        timeframe: this.request.timeframe,
        frequency: this.request.frequency,
        budget: this.request.budget,
        people: this.request.people,
        location: this.request.location ? this.request.location : '',
        referrals: this.request.referrals,
        attachments: this.request.attachments
      }
    )
  }

  scrollToTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  resetAnimation() {
    const el = document.getElementById('animated')
    //@ts-ignore
    el.focus()
    //@ts-ignore
    el.style.animation = 'none'
    //@ts-ignore
    el.offsetHeight /* trigger reflow */
    //@ts-ignore
    el.style.animation = null
  }
  submit() {
    this.validateForm()
    let hasErrors = Object.keys(this.validationErrors).every(key => this.validationErrors[key])
    if (!hasErrors) {
      !this.$route.params.id ? this.createRequest() : this.updateRequest()
    } else {
      if (this.validationErrors.noTitle) {
        this.scrollToTop()
        this.resetAnimation()
      }
    }
  }

  trackRequestEvent(isCreated: boolean) {
    let label = isCreated ? 'Request - Save' : 'Request - Update'
    this.$amplitude.logEvent(label, {
      title: this.form.title,
      description: this.form.description,
      budgetMin: this.form.budget && this.form.budget.priceFrom ? this.form.budget.priceFrom : null,
      budgetMax: this.form.budget && this.form.budget.priceTo ? this.form.budget.priceTo : null,
      budgetType: this.form.budget && this.form.budget.type ? this.form.budget.type : null,
      people: this.form.people,
      timeframe: this.form.timeframe,
      frequency: this.form.frequency,
      offeringId: this.form.offeringId ? this.form.offeringId : null,
      serviceId: this.form.serviceId ? this.form.serviceId : null,
      supplierId: this.form.supplierId ? this.form.supplierId : null
    })
  }

  trackCancelEvent() {
    this.$amplitude.logEvent('Request - Cancel', {
      title: this.form.title
    })
  }

  async createRequest() {
    this.trackRequestEvent(true)
    await this.$billie.serviceRequests.create(userState.clientId, this.form)
    this.$router.push({ name: PAGES.DASHBOARD })
  }

  async updateRequest() {
    this.trackRequestEvent(false)
    await this.$billie.serviceRequests.update(userState.clientId, this.$route.params.id, this.form)
    this.$router.push({ name: PAGES.DASHBOARD })
  }

  populatePreviousRouteFields() {
    if (this.$route.query.service || this.$route.query.offering || this.$route.query.supplier) {
      if (this.$route.query.offering) this.form.offeringId = this.$route.query.offering as string
      if (this.$route.query.supplier) this.form.supplierId = this.$route.query.supplier as string
      if (this.$route.query.service) this.form.serviceId = this.$route.query.service as string
    }
  }

  toggleCancelPropmt() {
    this.showCancelPrompt = !this.showCancelPrompt
  }

  toggleCompletePropmt() {
    this.showCompletePrompt = !this.showCompletePrompt
  }

  async cancelRequest() {
    this.trackCancelEvent()
    await this.$billie.serviceRequests.close(userState.clientId, this.$route.params.id)
    this.$router.push({ name: PAGES.DASHBOARD })
  }

  async completeRequest() {
    await this.$billie.serviceRequests.close(userState.clientId, this.$route.params.id)
    this.$router.push({ name: PAGES.DASHBOARD })
  }

  async populatePrefilledAddress() {
    const client = await ClientsApi.getClientById(userState.clientId)
    this.form.location =
      client.address.addressLine +
      ' ' +
      client.address.zipcode +
      ' ' +
      client.address.city +
      ' ' +
      client.address.country
  }

  async created() {
    if (this.$route.params.id) {
      await this.getRequest()
    } else {
      await this.populatePrefilledAddress()
      this.populatePreviousRouteFields()
      this.form.frequency = { type: 'once' }
    }
    if (clientStateStore.functionalityBlocked) {
      this.$router.push({ name: 'dashboard' })
    }

    if (this.$route.query && this.$route.query.offering) {
      const offering = await this.$billie.offerings.get(this.$route.query.offering as string)
      if (offering.id === OFFERING_DK_ID || offering.id === OFFERING_UK_ID) {
        this.featuredOffering = augmentOffering(offering, this.$locale)
      }
    }

    if (this.request === null) {
      const el = document.getElementById('animated')
      //@ts-ignore
      el.focus()
    }
  }
}
