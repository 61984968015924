




































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IPeople } from '@/shared/components/interfaces/request.interface'
import RequestProperty from '@/components/requests/RequestProperty.vue'
import InputDropdown from '@/components/core/dropdown/InputDropdown.vue'
import PeopleModal from '@/components/requests/PeopleModal.vue'
import MinMax from '@/components/requests/MinMax.vue'

@Component({
  components: { RequestProperty, InputDropdown, PeopleModal, MinMax }
})
export default class PeopleInput extends Vue {
  @Prop({ required: false }) value: IPeople | null
  @Prop({ required: false, default: true }) isEditable: boolean
  min: null | number = null
  max: null | number = null
  type: null | string = null
  isModalOpen = false
  invalidMax = false
  isDropdownOpen = false

  setDropdown(isDropdownOpen: boolean) {
    this.isDropdownOpen = isDropdownOpen
  }

  toggleModal() {
    this.invalidMax = false
    this.isModalOpen = !this.isModalOpen
  }

  @Watch('value')
  inPeopleChange(value: IPeople | null) {
    this.populatePeople(value)
  }

  isNumberKey(e: KeyboardEvent) {
    if (!/^[0-9]$/i.test(e.key)) {
      e.preventDefault()
    }
  }

  minMaxInput(minMax: { min: number | null; max: number | null }) {
    this.min = minMax.min
    this.max = minMax.max
    this.onValueChange()
  }

  isValid(minMax: { min: number | null; max: number | null }) {
    if (minMax.min && minMax.max)
      return (this.invalidMax = minMax.min >= minMax.max && minMax.min >= 0 && minMax.max >= 0)
    if (minMax.min && !minMax.max) return (this.invalidMax = true)
    return (this.invalidMax = false)
  }

  onValueChange() {
    this.isValid({ min: this.min, max: this.max })
    if (this.invalidMax === true) return this.$emit('input', null)
    if (this.min && this.max) {
      this.type = 'range'
      return this.$emit('input', { type: this.type, min: this.min, max: this.max })
    }
    if (!this.min && this.max) {
      this.type = 'absolute'
      return this.$emit('input', { type: this.type, value: this.max })
    } else return this.$emit('input', null)
  }

  populatePeople(value = this.value) {
    if (value) {
      this.type = value.type
      if (value.type === 'absolute') {
        this.max = value.value
      } else {
        this.min = value.min
        this.max = value.max
      }
    }
  }

  get displayValue() {
    if (!this.invalidMax) {
      if (this.min && this.max) {
        return this.min + ' - ' + this.max
      }
      if (this.max) {
        return this.max
      }
    }
    return null
  }

  created() {
    this.populatePeople()
  }

  clear() {
    this.invalidMax = false
    this.min = null
    this.max = null
    this.$emit('input', null)
    if (this.isModalOpen) this.isModalOpen = false
  }
}
