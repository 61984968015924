






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ShowText from '@/components/suppliers/ShowText.vue'
import Button from '../core/Button.vue'

@Component({
  components: {
    ShowText,
    Button
  }
})
export default class TalkToUs extends Vue {
  @Prop() buttonText: string
  @Prop() descriptionText: string

  get henry() {
    return require('@/assets/henry.png')
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  goToForm() {
    this.$emit('goToForm')
  }

  talkToUs() {
    this.$router.push({ name: 'concierge' })
  }
}
