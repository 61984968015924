







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class DropdownContentBox extends Vue {
  @Prop() open: boolean
  @Prop() close: () => void
}
