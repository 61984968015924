


































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ServicesApi from '@/shared/services/api/servicesApi'
import Services from '@/store/modules/services'
import { IServiceResponse } from '@/shared/services/api.interface'
import LeadText from '@/components/legacy_components/LeadText.vue'
import ShowText from '@/components/suppliers/ShowText.vue'
import CategorySuppliers from '@/components/explore/CategorySuppliers.vue'
import TalkToUs from '@/components/explore/TalkToUs.vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'

const servicesState = getModule(Services)
@Component({
  components: { LeadText, ShowText, CategorySuppliers, TalkToUs, Space, Heading }
})
export default class CategoryPage extends Vue {
  allServices: { [key: string]: IServiceResponse }
  servicesData: IServiceResponse | null = null
  servicesState = servicesState
  componentKey = 'initial'
  id = ''

  @Watch('$route')
  onRouteUpdate() {
    this.id = this.$route.params.id
    this.servicesData = this.allServices[this.id]
  }

  get name() {
    if (!this.servicesData) return ''
    return (this.servicesData as IServiceResponse).name
  }
  get description() {
    if (!this.servicesData) return ''
    return (this.servicesData as IServiceResponse).description
  }
  get longDescription() {
    if (!this.servicesData) return ''
    return (this.servicesData as IServiceResponse).longDescription
  }
  get ctaLabel() {
    if (!this.servicesData) return ''
    return (this.servicesData as IServiceResponse).ctaLabel
  }
  get suppliers() {
    if (!this.servicesData) return []
    return (this.servicesData as IServiceResponse).suppliers
  }
  get heroImage() {
    if (!this.servicesData) return ''
    return (this.servicesData as IServiceResponse).heroImage
  }
  get hasSuppliers() {
    if (!this.servicesData) return false
    return !!(this.servicesData as IServiceResponse).suppliers.length
  }
  goToForm() {
    this.$router.push({ name: 'request' })
  }
  async mounted() {
    this.id = this.$route.params.id
    if (!Object.keys(this.servicesState.allServices).length) {
      let services = await ServicesApi.getServices()
      await this.servicesState.getServices(services)
    }
    this.allServices = this.servicesState.allServices
    this.servicesData = this.allServices[this.id]
    this.componentKey = Math.random().toString()
  }
}
