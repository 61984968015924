



import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class FormattedPrice extends Vue {
  @Prop({ required: true }) readonly price!: number
  @Prop({ required: true }) readonly currency!: string
  @Prop({ required: true }) readonly locale!: string
  localeOptions = {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  get formatted_price() {
    const price = this.price
    return price.toLocaleString(this.locale, { ...this.localeOptions, currency: this.currency })
  }
}
