import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'
import {
  IAgreementCategoryV2,
  IAgreementV2,
  IOfferingCategory,
} from '@/shared/components/interfaces/agreements.interface'
import AgreementsApi from '@/shared/services/api/agreementsApi'

@Module({
  name: 'AgreementsV2',
  dynamic: true,
  store: store,
})
export default class AgreementsV2 extends VuexModule {
  initialCategories: IOfferingCategory[] = []
  initialOrders: IAgreementV2[] = []
  loadedAreaCategories = ''
  orders: IAgreementV2[] = [...this.initialOrders]
  categories: IOfferingCategory[] = [...this.initialCategories]

  subscriptionCategories: IAgreementCategoryV2[] = []
  adhocCategories: IAgreementCategoryV2[] = []
  offeringCategories: IOfferingCategory[] = []

  get allSubscriptionCategories() {
    return this.subscriptionCategories
  }

  get allAdhocCategories() {
    return this.adhocCategories
  }

  get allOfferingCategories() {
    return this.offeringCategories
  }

  get allCategories() {
    return this.categories
  }

  get allOrders() {
    return this.orders
  }

  get loadedArea() {
    return this.loadedAreaCategories
  }

  @Mutation
  public setCategories(payload: IAgreementCategoryV2[]) {
    this.categories = [...JSON.parse(JSON.stringify(payload))]
  }

  @Mutation
  public setAreaLoaded(payload: string) {
    this.loadedAreaCategories = payload
  }

  @Action({ commit: 'setCategories' })
  public async getCategories(serviceArea: string) {
    return AgreementsApi.getCategories(serviceArea).then((res) => res)
  }

  @Mutation
  public setSubscriptionCategories(payload: IAgreementCategoryV2[]) {
    this.subscriptionCategories = [...JSON.parse(JSON.stringify(payload))]
  }

  @Action({ commit: 'setSubscriptionCategories' })
  public async getSubscriptionCategories() {
    return AgreementsApi.getAgreementCategories(true).then((res) => res)
  }
  @Mutation
  public setAdhocCategories(payload: IAgreementCategoryV2[]) {
    this.adhocCategories = [...JSON.parse(JSON.stringify(payload))]
  }

  @Action({ commit: 'setAdhocCategories' })
  public async getAdhocCategories() {
    return AgreementsApi.getAgreementCategories(false).then((res) => res)
  }

  @Mutation
  public setOrders(payload: IAgreementV2[]) {
    this.orders = [...JSON.parse(JSON.stringify(payload))].filter((agreement) => agreement.invoiceSchedule === 'once')
  }

  @Action({ commit: 'setOrders' })
  public async getOrders() {
    return AgreementsApi.getAgreementsV2().then((res) => res)
  }

  @Mutation
  public setOfferingCategories(payload: IAgreementV2[]) {
    this.offeringCategories = [...JSON.parse(JSON.stringify(payload))]
  }

  @Action({ commit: 'setOfferingCategories' })
  public async getOfferingCategories() {
    return AgreementsApi.getOfferingCategories().then((res) => res)
  }
}
