







































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import InlineSvg from 'vue-inline-svg'
import Auth from '@/shared/storeModules/auth'
import { IServicePageResponse } from '@/shared/services/api.interface'
import ServicesApi from '@/shared/services/api/servicesApi'
import NavigationLink from '@/components/NavigationLink.vue'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import HeroSection from '@/components/services/HeroSection.vue'
import CtaSection from '@/components/services/CtaSection.vue'
import OptionsSection from '@/components/services/OptionsSection.vue'
import BenefitsSection from '../../components/services/BenefitsSection.vue'
import JourneySection from '../../components/services/JourneySection.vue'
import Button from '@/components/core/Button.vue'
import ClientStateStore from '@/store/modules/clientStateStore'

const UserState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

@Component({
  components: {
    NavigationLink,
    InlineSvg,
    ButtonPrimary,
    HeroSection,
    JourneySection,
    OptionsSection,
    BenefitsSection,
    CtaSection,
    Button
  }
})
export default class ServicePage extends Vue {
  userState = UserState
  clientStateStore = clientStateStore
  pageData?: IServicePageResponse | null = null
  country: string

  onClose() {
    this.clientStateStore.hideShowModal(false)
  }
  async created() {
    const slug = this.$route.path.replace('/services/', '')
    this.country = this.userState.user.serviceArea === 'CPH' ? 'DK' : 'UK'
    this.pageData = await ServicesApi.getServiceType(this.$locale, this.country, slug)
    this.$amplitude.logEvent('Marketplace - Service Page', { Service: this.pageData?.title })
  }

  getSectionData(sectionId: string) {
    return this.pageData?.sections.find(section => section.sectionId === sectionId)
  }

  get overdueNotificationLevel(): number | null {
    return clientStateStore.notificationLvl
  }

  handleClick() {
    if (this.overdueNotificationLevel! >= 3) {
      this.clientStateStore.hideShowModal(true)
    } else {
      this.$router.push({ name: 'request', query: { service: this.pageData?.serviceType } })

      // this.$router.push({ name: 'request' })
      this.$amplitude.logEvent('Marketplace - Request', { Service: this.pageData?.title })
    }
  }
}
