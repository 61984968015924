


















import Vue from 'vue'

export default Vue.extend({
  name: 'NotificationBar',
  props: {
    message: {
      type: String,
      required: true
    },
    dismissable: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      show: true as boolean
    }
  },
  methods: {
    close() {
      this.show = false
    }
  },
  computed: {}
})

// import Vue from 'vue'
// import Component from 'vue-class-component'
// import { Prop } from 'vue-property-decorator'
// @Component({
//   components: {}
// })
// export default class NotificationBar extends Vue {
//   @Prop({ required: true }) message: string
//   @Prop({ default: true }) dismissable: boolean
//   @Prop({ required: true }) type: 'success' | 'error'
//   show = true

//   close() {
//     this.show = false
//   }
// }
