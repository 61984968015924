








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IDropdownMessage } from '../../shared/components/interfaces/messages.interface'

@Component({
  components: {}
})
export default class DropdownRow extends Vue {
  @Prop() clickCallback: (item: IDropdownMessage) => void
  @Prop() item: IDropdownMessage
  @Prop() mouseoverItem: any
  changeActiveIndex() {
    this.$emit('mouseover', this.mouseoverItem)
  }
}
