














































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IUser } from '@/shared/storeModules/auth'
import LabelField from '@/components/legacy_components/LabelField.vue'
import Button from '../core/Button.vue'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'
import Auth from '@/shared/storeModules/auth'
import { getModule } from 'vuex-module-decorators'
import RadioInput from '@/components/legacy_components/RadioInput.vue'
import { TranslateResult } from 'vue-i18n'
import TextareaInput from '@/components/legacy_components/TextareaInput.vue'
import BModal from '@/components/BModal.vue'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'

const UserState = getModule(Auth)

@Component({
  components: { Space, LabelField, Button, RadioInput, TextareaInput, BModal, Heading }
})
export default class DeleteUserModal extends Vue {
  @Prop({ required: true }) modalShow: boolean
  @Prop({ required: true }) user: IUser | IInvitee
  @Prop() cancelCallback: () => void
  @Prop() deleteModalConfirm: (user: IUser | IInvitee, key: string | undefined, reason: string) => void
  UserState = UserState
  deleteReasons: { key: string; value: TranslateResult }[] = []
  deleteYourselfReasons: { key: string; value: TranslateResult }[] = []
  selectedReason = ''
  deleteReasonOpenText = ''
  @Watch('modalShow')
  onModalShowChange() {
    setTimeout(() => {
      if (this.modalShow) {
        //@ts-ignore
        this.$refs.deleteUserModal.show()
        this.selectedReason = ''
        this.deleteReasonOpenText = ''
      } else {
        //@ts-ignore
        this.$refs.deleteUserModal.hide()
      }
    }, 0)
  }

  get selectedReasonKey() {
    return this.deleteReasons.find(x => x.value === this.selectedReason)?.key
  }

  created() {
    this.deleteReasons = [
      {
        key: 'notEmployed',
        value: this.isDeletingYourself
          ? this.$t('user.account.deleteYourselfReasons.notEmployed')
          : this.$t('user.account.deleteReasons.notEmployed')
      },
      {
        key: 'userTemporary',
        value: this.isDeletingYourself
          ? this.$t('user.account.deleteYourselfReasons.userTemporary')
          : this.$t('user.account.deleteReasons.userTemporary')
      },
      {
        key: 'tooManyEmails',
        value: this.isDeletingYourself
          ? this.$t('user.account.deleteYourselfReasons.tooManyEmails')
          : this.$t('user.account.deleteReasons.tooManyEmails')
      },
      {
        key: 'other',
        value: this.isDeletingYourself
          ? this.$t('user.account.deleteYourselfReasons.other')
          : this.$t('user.account.deleteReasons.other')
      }
    ]
  }

  get isDeletingYourself() {
    return this.UserState.user.id === this.user?.id
  }

  get userIdentification() {
    //@ts-ignore
    return this.user.invitedAt ? this.user.email : this.user.firstName + ' ' + this.user.lastName
  }

  get title() {
    return this.isDeletingYourself
      ? this.$t('user.account.deleteYourselfModalTitle')
      : `${this.$t('user.account.deleteUserModalTitle')} ${this.userIdentification}?`
  }

  get loggedOutText() {
    return this.isDeletingYourself ? this.$t('user.account.loggedOutImmediately') : ''
  }
}
