






































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import ForgotPassword from '@/pages/auth/ForgotPassword.vue'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'
import NotificationBar from '@/components/legacy_components/NotificationBar.vue'

@Component({
  components: { ButtonPrimary, LabelNormal, ForgotPassword, Heading, Space, NotificationBar }
})
export default class LoginForm extends Vue {
  $refs: { email: HTMLInputElement }

  user = { email: '', password: '', strategy: 'local' }
  @Prop() loginText!: string
  @Prop() forgotPasswordText!: string
  @Prop() rememberMeText!: string
  @Prop() resetMessage!: string
  @Prop() error?: { message: string }
  @Prop() loading!: boolean
  @Prop() login!: (strategy: { strategy: string; email: string; password: string }) => void
  sent = false

  mounted() {
    if (this.$refs.email) {
      this.$refs.email.focus()
    }
  }
  triggerSubmit() {
    //@ts-ignore
    this.$refs.submit?.click()
  }

  submitForm() {
    if (this.user.email && this.user.password) {
      this.login({
        strategy: 'local',
        email: this.user.email.trim(),
        password: this.user.password
      })
    }
  }

  onResetSuccess() {
    this.$router.push('/login')
    this.sent = true
  }

  navigateToGoodMonday() {
    window.location.href = 'https://www.goodmonday.io/'
  }

  navigateToSignup() {
    window.location.href = 'https://www.goodmonday.io/signup'
  }
}
