

























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Modal from '@/components/core/modal/Modal.vue'
import ModalStep from '@/components/core/modal/ModalStep.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import Button from '@/components/core/Button.vue'
import Flex from '@/components/layout/Flex.vue'
import Heading from '@/components/core/Heading.vue'
import MinMax from '@/components/requests/MinMax.vue'
import RequestInputTab from '@/components/requests/RequestInputTab.vue'
import { IBudget } from '@/shared/components/interfaces/request.interface'

@Component({
  components: { Modal, ModalStep, ModalHeader, Heading, Space, Label, Button, Flex, MinMax, RequestInputTab }
})
export default class BudgetModal extends Vue {
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) value: IBudget | null
  @Prop({ required: true }) min: any
  @Prop({ required: true }) max: any
  @Prop({ required: true }) isShowing: boolean
  @Prop({ required: false, default: false }) invalidMax: boolean
  @Prop() type: 'total' | 'perPerson' | 'perHour' | 'weekly' | 'monthly'

  selectedMin: null | number = null
  selectedMax: null | number = null

  handleDone() {
    this.$emit('setMinMaxInput', { min: this.selectedMin, max: this.selectedMax })
    this.onClose()
  }

  minMaxInput(minMax: { min: number | null; max: number | null }) {
    this.selectedMin = minMax.min
    this.selectedMax = minMax.max
    this.$emit('isValid', { min: this.selectedMin, max: this.selectedMax })
  }

  created() {
    this.selectedMin = this.min
    this.selectedMax = this.max
  }
}
