import _Vue from 'vue'
import * as _Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import jwt from 'jsonwebtoken'

interface SentryOptions {
  token: string
}

export default function Sentry(Vue: typeof _Vue, options: SentryOptions): void {
  if (process.env.NODE_ENV === 'production') {
    _Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
      release: 'GIT_COMMIT_ID',
      integrations: [
        new Integrations.BrowserTracing(),
        new _Sentry.Integrations.InboundFilters({
          ignoreErrors: [
            // Network error coming from third party Web Delighted survey
            'ResizeObserver loop limit exceeded',
            /NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\/\/web\.delighted\.com\/t\/.*'\./,
          ],
        }),
      ],
      attachProps: true,
      logErrors: true,
      tracesSampleRate: 1,
    })

    if (Vue.prototype.$token) {
      const decodedToken = jwt.decode(options.token) as { email: string; sub: string }

      _Sentry.configureScope((scope) => {
        scope.setUser({ email: decodedToken!.email, id: decodedToken!.sub })
      })
    }
  }
}
