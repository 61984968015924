





























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import Button from '@/components/core/Button.vue'
import FrequencyInput from '@/components/requests/FrequencyInput.vue'
import type { IFrequency } from '@/shared/billie/api/serviceRequests'

@Component({
  components: { Modal, ModalHeader, Button, FrequencyInput }
})
export default class FrequencyModal extends Vue {
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) value: IFrequency
  @Prop({ required: true }) isShowing: boolean

  selectedValue: IFrequency | null = null

  setValues(value: any) {
    this.selectedValue = value
  }

  handleDone() {
    this.$emit('setValues', this.selectedValue)
    this.onClose()
  }

  created() {
    this.selectedValue = this.value
  }
}
