





























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import LabelForm from '@/components/legacy_components/LabelForm.vue'
import TextField from '@/components/legacy_components/TextField.vue'
import LabelSmallWithIcon from '@/components/legacy_components/LabelSmallWithIcon.vue'
import CircularProgressBar from '@/components/legacy_components/CircularProgressBar.vue'
import FormWizardNav from '@/components/signup/FormWizardNav.vue'
import Button from '../../components/core/Button.vue'
import Heading from '@/components/core/Heading.vue'

@Component({
  components: {
    TextField,
    LabelForm,
    LabelSmallWithIcon,
    CircularProgressBar,
    FormWizardNav,
    Button,
    Heading
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs('password') }
    }
  }
})
export default class FormWizardSetPassword extends Vue {
  @Prop({ default: '' }) loadingState: 'started' | 'done'
  @Prop({ required: true }) tabSize: number
  @Prop({ required: true }) activeTab: number
  $t: (str: string) => ''
  form = {
    password: '',
    confirmPassword: ''
  }
  confirmPasswordValidationMessage = ''
  touched = false

  get buttonText(): string {
    if (this.loadingState === 'started') return this.$t('labels.saving')
    if (this.loadingState === 'done') return this.$t('labels.saved')
    return this.$t('labels.continue')
  }

  get disableSaveButton(): boolean {
    return !(
      this.hasAtLeastOneUpperCaseLetter &&
      this.hasAtLeastOneNumber &&
      this.hasAtLeastEightCharacters &&
      this.form.password === this.form.confirmPassword
    )
  }

  get passwordValidationMessage(): string {
    let error = ''
    if (!this.$v.form.password!.$dirty) error = ''
    else if (!this.$v.form.password!.required) error = this.$t('labels.signup.passwordRequiredMessage')
    return error
  }

  get hasAtLeastOneUpperCaseLetter(): boolean {
    const re = new RegExp('[A-Z]')
    return re.test(this.form.password)
  }

  get hasAtLeastOneNumber(): boolean {
    const re = new RegExp('[0-9]')
    return re.test(this.form.password)
  }

  get hasAtLeastEightCharacters(): boolean {
    return this.form.password.length >= 8
  }

  onPasswordInput(): void {
    this.$v.form.password!.$touch()
    this.touched = true
  }

  onPasswordConfirmationInput(): void {
    if (this.confirmPasswordValidationMessage) {
      this.onPasswordConfirmationBlur()
    }
  }

  onPasswordConfirmationBlur(): void {
    this.$v.form.confirmPassword!.$touch()
    let error = ''
    if (!this.$v.form.confirmPassword!.$dirty) error = ''
    else if (!this.$v.form.confirmPassword!.required) {
      error = this.$t('labels.signup.passwordConfirmationRequiredMessage')
    } else if (!this.$v.form.confirmPassword!.sameAsPassword) {
      error = this.$t('labels.signup.passwordMatchRequiredMessage')
    }
    this.confirmPasswordValidationMessage = error
  }

  saveChanges(e: any): void {
    e.preventDefault()
    e.stopPropagation()
    if (!this.disableSaveButton) {
      this.$emit('continue', { password: this.form.password })
    }
  }
}
