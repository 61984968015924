import moment from 'moment'
import { format } from 'timeago.js'

export function extractExpiresIn(dateValue: string) {
  return (
    moment(dateValue)
      .add(7, 'd')
      .diff(new Date(), 'd') +
    ' days' +
    ' - ' +
    moment(dateValue)
      .add(7, 'd')
      .format('DD/MM')
  )
}

export function formatTimeAgo(dateTime: string, locale: string) {
  switch (locale) {
    case 'da':
    case 'da-DK':
      locale = 'da-DK'
      break
    case 'en':
    case 'en-US':
    case 'en-GB':
      locale = 'en-US'
      break
    default:
      locale = 'en-US'
  }
  return format(dateTime, locale)
}

export function getInitials(name: string) {
  if (!name) return ''
  const nameArray: string[] = name.split(' ')
  if (nameArray.length === 1) return `${nameArray[0].charAt(0).toUpperCase()}${nameArray[0].charAt(1).toUpperCase()}`
  return `${nameArray[0].charAt(0).toUpperCase()}${nameArray[1].charAt(0).toUpperCase()}`
}

export function screenResizeHandler(this: any, e: Event) {
  if (e) {
    this.isMobileDevice = (e.target as Window).outerWidth <= 1024
    this.screenSize = (e.target as Window).outerWidth
  }
}
