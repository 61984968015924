





































































import Vue from 'vue'
import Component from 'vue-class-component'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import ModalStep from '@/components/core/modal/ModalStep.vue'
import Space from '@/components/layout/Space.vue'
import Button from '@/components/core/Button.vue'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import ClientsApi from '@/shared/services/api/ClientsApi'
import { IClientAccount } from '@/shared/components/interfaces/clients.interface'
import { Prop } from 'vue-property-decorator'
import Grid from '../layout/Grid.vue'
const authState = getModule(Auth)

@Component({
  components: { Modal, ModalHeader, ModalStep, Space, Button, LabelSmall, Grid }
})
export default class BlockUserModal extends Vue {
  @Prop() client: IClientAccount | null
  authState = authState
  isUserBlocked = true
  step = 1
  phone = ''
  touched = false

  get error() {
    var regExp = /[a-zA-Z]/g
    return (this.phone && this.phone.length < 6) || regExp.test(this.phone)
  }

  async mounted() {
    this.phone = this.client?.phone ?? this.authState.user.mobile
  }

  async requestReactivation() {
    this.touched = true
    if (!this.error) {
      await ClientsApi.requestReactivation(this.authState.user.clientId + '', this.phone)
      this.step = 2
    }
  }

  logout() {
    window.location.href = '/logout'
  }

  moveToStep2() {
    this.step = 2
  }
}
