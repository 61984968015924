/*********************************************************************************************************************************************
 * IMPORTANT!                                                                                                                                *
 * Before changing anything in this file, please read: https://storybook.conval.goodmonday.io/?path=/story/guidelines-routing-and-urls--page *
 *********************************************************************************************************************************************/

import Vue from 'vue'
import Router from 'vue-router'
import { getModule } from 'vuex-module-decorators'
import UrlHelper from './services/helpers/url.helper'
import ClientsApi from '@/shared/services/api/ClientsApi'

/***********
 * Layouts *
 ***********/
import AuthLayout from './pages/layouts/Auth.vue'
import MainLayout from './pages/layouts/Main.vue'

/*****************
 * Setting Pages *
 *****************/
import Account from './pages/settings/Account.vue'
import Info from './pages/settings/Info.vue'
import Preferences from './pages/settings/Preferences.vue'
import Profile from './pages/settings/Profile.vue'
import Users from './pages/settings/Users.vue'

/******************
 * Authentication Pages *
 ******************/
import Login from './pages/auth/Login.vue'
import Logout from './pages/auth/Logout.vue'
import Signup from './pages/auth/Signup.vue'

/**********************
 * Marketplaces Pages *
 **********************/
import Category from './pages/explore/Category.vue'
import Collection from './pages/explore/Collection.vue'
import Explore from './pages/explore/Explore.vue'
import Offering from './pages/explore/Offering.vue'
import Service from './pages/explore/Service.vue'
import Supplier from './pages/explore/Supplier.vue'

/***************
 * Other Pages *
 ***************/
import Flags from './pages/Flags.vue'
import Dashboard from './pages/Dashboard.vue'
import Concierge from './pages/Concierge.vue'
import Invoices from './pages/Invoices.vue'
import Request from './pages/Request.vue'
import Agreement from './pages/Agreement.vue'

/***********
 * SUNRISE *
 ***********/

import SunriseOffering from './pages/explore/sunrise/Offering.vue'
import Promo from './pages/explore/sunrise/Promo.vue'
import {
  SUPPLIER_DK_ID,
  SUPPLIER_UK_ID,
  OFFERING_DK_ID,
  OFFERING_UK_ID,
  PROMO_TAG,
} from '@/pages/explore/sunrise/sunriseData'

/*****************
 * Store Modules *
 *****************/
import ClientStateStore from './store/modules/clientStateStore'
import Auth from '@/shared/storeModules/auth'

const Calendar = () => import(/* webpackChunkName: "chunk-calendar", webpackMode: "lazy" */ './pages/Calendar.vue')

const userState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

Vue.use(Router)
const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: '/dashboard',
      },
      {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
          breadCrumb: 'Logout',
        },
      },
      {
        path: '/requests/:id?',
        name: 'request',
        meta: { fullScreenLayout: true, hideMainAside: true },
        component: Request,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { breadCrumb: 'Dashboard' },
      },
      {
        path: '/concierge',
        name: 'concierge',
        component: Concierge,
        meta: { fullScreenLayout: true, hideMainAside: true },
      },
      {
        path: '/marketplace',
        redirect: '/explore',
      },
      {
        path: '/explore',
        name: 'explore',
        component: Explore,
        meta: { breadCrumb: 'Explore' },
      },
      {
        path: '/explore/category/:id',
        name: 'category',
        component: Category,
      },
      {
        path: '/:url/collection/:id',
        name: 'collections.page',
        component: Collection,
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: Calendar,
        meta: {
          breadCrumb: 'Calendar',
        },
      },
      {
        path: '/agreements/:id',
        name: 'agreements',
        meta: { fullScreenLayout: true, hideMainAside: true },
        component: Agreement,
      },
      {
        path: '/invoices',
        name: 'invoices',
        component: Invoices,
        meta: {
          breadCrumb: 'Invoices',
        },
      },
      {
        path: '/client-info',
        name: 'clientInfo',
        component: Info,
        meta: {
          breadCrumb: 'Client Info',
        },
        redirect: '/client-info/profile',
        children: [
          {
            path: 'profile',
            name: 'clientProfile',
            component: Profile,
            meta: {
              breadCrumb: 'My Profile',
            },
          },
          {
            path: 'preferences',
            name: 'clientPreferences',
            component: Preferences,
            meta: {
              breadCrumb: 'My preferences',
            },
          },
          {
            path: 'account',
            name: 'clientAccount',
            component: Account,
            meta: {
              breadCrumb: 'My Account',
            },
          },
          {
            path: 'users',
            name: 'clientUsers',
            component: Users,
            meta: {
              breadCrumb: 'My Users',
            },
          },
        ],
      },

      /**
       * Sunrise Routes
       */
      {
        path: `/supplier/${SUPPLIER_DK_ID}/${OFFERING_DK_ID}`,
        name: 'sunrise.offeringDK',
        component: SunriseOffering,
        meta: {
          supplierId: SUPPLIER_DK_ID,
          offeringId: OFFERING_DK_ID,
        },
      },
      {
        path: `/supplier/${SUPPLIER_UK_ID}/${OFFERING_UK_ID}`,
        name: 'sunrise.offeringUK',
        component: SunriseOffering,
        meta: {
          supplierId: SUPPLIER_UK_ID,
          offeringId: OFFERING_UK_ID,
        },
      },
      {
        path: `/promo`,
        name: 'sunrise.promo',
        component: Promo,
        meta: {
          breadCrumb: 'Promo',
          offeringId: PROMO_TAG,
        },
      },

      {
        path: '/supplier/:supplierId',
        name: 'supplier.supplierId',
        component: Supplier,
        meta: {
          breadCrumb: 'Supplier',
        },
      },
      {
        path: '/supplier/:supplierId/:serviceId',
        name: 'supplier.service',
        component: Offering,
        meta: {
          breadCrumb: 'Suppliers',
        },
      },
      {
        path: '/flags',
        name: 'FlagsPanel',
        component: Flags,
      },

      {
        path: '/services/:slug',
        name: 'service.page',
        component: Service,
      },
    ],
  },
  {
    path: '/',
    name: 'AuthLayout',
    component: AuthLayout,
    children: [
      {
        path: '/signmein',
        name: 'SignMeIn',
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'Login - Good Monday' },
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: Login,
      },
      {
        path: '/reset-password/:token?',
        name: 'ResetPassword',
        component: Login,
      },
      {
        path: '/invitations/:inviteId',
        name: 'Signup',
        component: Signup,
        props: true,
      },
    ],
  },
  /**
   * THESE ARE ROUTES ONLY EXISTING IN V4
   * We need to add them here to be able to force a hard refresh,
   * so that we can route to V4.
   */
	{
	path: '/explore/service/:area/:slug',
	name: 'service',
	component: Dashboard,
	},
  {
    path: '/lunch/:id',
    name: 'SpaceLunch',
    component: Dashboard,
  },
  {
    path: '/lunch-order',
    name: 'LunchOrder',
    component: Dashboard,
  },
]
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    let position = { x: 0, y: 0 }

    if (savedPosition) {
      position = savedPosition
    }

    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        resolve(position)
      }, 175)
    })
  },
})

const hardRefreshRoutes: Array<string> = ['SpaceLunch', 'LunchOrder', 'service']

router.beforeEach((to, from, next) => {
  if (from.name !== null && to.name !== from.name && hardRefreshRoutes.includes(to.name as string)) {
    window.location.href = to.fullPath
  } else {
    next()
  }
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth) {
    if (userState.hasToken) {
      if (!userState.hasUser) {
        userState
          .getUser()
          .then(async () => {
            const status = await ClientsApi.getLockStatus(userState.user.clientId + '')
            clientStateStore.mutateState(status)
            next()
          })
          .catch(() => {
            userState.logout().then(() => {
              UrlHelper.redirect(to.fullPath)
            })
          })
        return
      } else {
        userState.setArea()
      }
      next()
      return
    }
    UrlHelper.redirect(to.fullPath)
  } else {
    if (to.name === 'SignMeIn') {
      userState
        .tokenAuthenticate({ token: to.query.token as string, fileToken: to.query.fileToken as string })
        .then(
          (_token) => {
            if (window.location.href.includes('/signmein')) {
              window.location.href = (to.query.redirect as string) || '/'
            }
            next()
          },
          (_error: any) => {
            next()
            // Convert the error to a plain object and add a message.
          }
        )
        .catch((_e: Error) => {
          window.location.href = (to.query.redirect as string) || '/'
        })
    }
    if (userState.hasToken) {
      if (to.name === 'Signup') {
        userState.logout().then(() => {
          next()
        })
        return
      }
      next('/')
      return
    } else {
      next()
    }
  }
})

const DEFAULT_TITLE = 'Good Monday - The Workspace Management Platform'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title || DEFAULT_TITLE
  })
})
export default router
