

























import Vue from 'vue'
import Component from 'vue-class-component'
import Chat from '@/components/chat/Chat.vue'
import { Prop } from 'vue-property-decorator'
import { Channel, Socket } from 'phoenix'
import { IConversationMessage, NewMessage } from '@/shared/components/interfaces/messages.interface'
import Auth from '@/shared/storeModules/auth'
import { getModule } from 'vuex-module-decorators'
import { IFileResponseData } from '../../shared/components/interfaces/fileHandler.interface'
import throttle from 'lodash/throttle'
import ChatStore from '@/store/modules/chatStore'

const authState = getModule(Auth)
const chatState = getModule(ChatStore)

@Component({
  components: { Chat }
})
export default class ChatModule extends Vue {
  @Prop() chatId: string
  @Prop({ required: false, default: false }) sendingMessageDisabled: boolean
  socket: Socket | null = null
  channel: Channel | null = null
  messages: IConversationMessage[] = []
  uploadedFiles: IFileResponseData[] = []
  uploadInProgress = false
  loading = true
  /**
   * TODO: Implement visual state for being disconnected
   */
  isConnected = false

  get hasUnreadMessages() {
    return this.messages.length > 0 && this.messages[this.messages.length - 1].unread
  }

  get chatSubjectId() {
    return this.chatId.split(':')[1]
  }
  get chatSubjectType() {
    return this.chatId.split(':')[0]
  }

  onMessage(message: IConversationMessage) {
    if (message.user.id == authState.user.id) {
      message.unread = false
    }

    this.messages.push(message)
  }

  onHistory({ messages }: { messages: IConversationMessage[] }) {
    this.messages = messages
    this.loading = false
    this.readConversation()
  }

  onError(_reason: string) {
    this.isConnected = this.socket!.isConnected()
  }

  onInputFocus() {
    this.readConversation()
  }

  async onAddFile(attachments: File[]) {
    this.uploadInProgress = true
    const uploads = attachments.map(file => this.$billie.fileUpload.upload(file))
    const response = await Promise.all(uploads)
    this.uploadedFiles = response
    this.uploadInProgress = false
  }

  removeFile(file: IFileResponseData) {
    this.uploadedFiles = this.uploadedFiles.filter(uploadedFile => uploadedFile !== file)
  }

  async sendMessage(message: NewMessage) {
    if (this.channel) {
      let payload: { text: string; attachment?: string } = { text: message.text }
      this.uploadInProgress = true
      payload = message.attachments.length > 0 ? { ...payload, attachment: message.attachments[0].id } : payload
      this.uploadedFiles = []
      this.uploadInProgress = false
      this.channel.push('message:post', payload)
    }
  }

  readConversation = throttle(this._readConversation, 2000, { leading: true, trailing: false })

  async _readConversation() {
    if (this.hasUnreadMessages) {
      await this.$billie.chat.read(this.chatSubjectId, authState.user.id)
      chatState.setUnreadMessages(chatState.unreadMessages.filter((id: string) => id !== this.chatSubjectId))

      // Instead of looping through all messages to find those that are marked as unread, we start from
      // the end going backwards, only looping until the point where messages are no logner unread.
      for (let i = this.messages.length; i > 0; i--) {
        if (!this.messages[i - 1].unread) break
        this.messages[i - 1].unread = false
      }
    }
  }

  created() {
    /**
     * TODO: Maintain a socket connection on the Vue instance. It can hold multiple channels, so there's no need in starting new sockets everywhere.
     * Eg. channel = this.$socket.channel
     * Would also alliviate the need to configure URL's like here.
     */
    this.socket = new Socket(this.$baseSocketURL, { params: { token: this.$token } })
    this.socket.connect()

    this.channel = this.socket.channel(`thread:${this.chatId}`, { token: this.$token })
    this.channel.on('message:new', this.onMessage)
    this.channel.on('message:history', this.onHistory)
    this.channel.onError(this.onError)

    this.channel
      .join()
      .receive('ok', () => {
        this.isConnected = this.socket!.isConnected()
      })
      /**
       * TODO: Think about error scenarios
       */
      .receive('error', this.onError)
      .receive('timeout', this.onError)
  }

  beforeDestroy() {
    if (this.channel) this.channel.leave()
    if (this.socket) this.socket.disconnect()
  }
}
