























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconButton from '@/components/core/IconButton.vue'

@Component({
  components: { IconButton }
})
export default class Modal extends Vue {
  @Prop({ required: false, default: false }) isShowing: boolean
  @Prop({ required: false }) readonly onClose: () => void
  @Prop({ required: false, default: false }) center: boolean
  @Prop({ required: false, default: false }) middle: boolean
  @Prop({ required: false, default: true }) hasClose: boolean
}
