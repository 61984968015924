








































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMenuItem } from '@/shared/components/interfaces/menuItems'
import NavigationHelper from '@/services/helpers/navigationHelper'
import MenuItem from './MenuItem.vue'
import { PAGES } from '@/globals'
//@ts-ignore
import ClickOutside from 'vue-click-outside'

@Component({
  components: { MenuItem },
  directives: { ClickOutside }
})
export default class Footer extends Vue {
  @Prop() menuItems!: IMenuItem[]
  @Prop() routeName!: string
  @Prop() toggleSearch: void
  noFooterRoutes: string[] = ['supplier.contactDetails', 'requests', 'supplier.proposalDetails']
  secondaryMenuOpen = false
  footerMenuItems: IMenuItem[]
  mainMenuItems: string[]
  secondaryMenuItems: IMenuItem[]

  created() {
    this.mainMenuItems = [PAGES.DASHBOARD, PAGES.PROPOSALS, PAGES.SUBSCRIPTIONS]
    this.secondaryMenuItems = [
      ...this.menuItems.filter(item => !this.mainMenuItems.includes(item.key)),
      { key: PAGES.PROFILE, icon: 'eva eva-person', link: 'client-info/profile' }
    ]
    this.footerMenuItems = [...this.menuItems.filter(item => this.mainMenuItems.includes(item.key))]
  }

  toggleSecondaryMenu() {
    this.secondaryMenuOpen = !this.secondaryMenuOpen
  }

  clickSearch() {
    this.$emit('toggleSearch', true)
  }

  isActive(item: IMenuItem) {
    return this.navigationHelper.isActive(this.routeName, item.link)
  }

  closeSecondaryMenu() {
    this.secondaryMenuOpen = false
  }

  navigationHelper = NavigationHelper
}
