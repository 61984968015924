
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'

@Component({
  components: { Tooltip }
})
export default class IconButton extends Vue {
  @Prop() icon: string
  @Prop() tooltip: string
  @Prop() disabled: boolean
  @Prop() theme: string
  @Prop() size: number
  @Prop({ default: true }) isAnimated: boolean
}
