import _Vue from 'vue'
import amplitude from 'amplitude-js'

interface AmplitudeOptions {
    apiKey: string
    userId?: string
    config?: amplitude.Config
}

export default function Amplitude(Vue: typeof _Vue, options: AmplitudeOptions): void {
    Vue.prototype.$amplitude = amplitude.getInstance()
    Vue.prototype.$amplitude.init(
        options.apiKey,
        options.userId,
        options.config
    )
}