

































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import RequestProperty from '@/components/requests/RequestProperty.vue'
import InputDropdown from '@/components/core/dropdown/InputDropdown.vue'
import FrequencyModal from '@/components/requests/FrequencyModal.vue'
import DropdownSelectItem from '@/components/core/dropdown/DropdownSelectItem.vue'
import { IFrequency } from '@/shared/billie/api/serviceRequests'
import CheckboxGroup from '@/components/core/CheckboxGroup.vue'
import Space from '@/components/layout/Space.vue'
import FrequencyInput from '@/components/requests/FrequencyInput.vue'

@Component({
  components: {
    RequestProperty,
    InputDropdown,
    FrequencyModal,
    DropdownSelectItem,
    CheckboxGroup,
    Space,
    FrequencyInput
  }
})
export default class FrequencyProperty extends Vue {
  @Prop({ required: false }) value: IFrequency | null
  @Prop({ required: false, default: true }) isEditable: boolean
  @Prop({ default: true }) isNewRequest: boolean
  isModalOpen = false
  isDropdownOpen = false
  frequencyType: null | string = null
  frequencyValue: null | string = null

  isMobileDevice: boolean = document.body.clientWidth <= 768

  inputEmiter(value: IFrequency) {
    this.$emit('input', value)
  }

  setDropdown(isDropdownOpen: boolean) {
    this.isDropdownOpen = isDropdownOpen
  }

  get isMobile() {
    return this.isMobileDevice
  }

  toggleModal() {
    this.isModalOpen = !this.isModalOpen
  }

  @Watch('value')
  inFrequencyChange(value: IFrequency | null) {
    this.populateFrequency(value)
  }

  populateFrequency(value = this.value) {
    if (value) {
      this.frequencyType = value.type
      this.frequencyValue = value.value ? value.value.toString() : value.days ? value.days.toString() : null
    }
  }

  get displayValue() {
    if (this.frequencyType && !this.frequencyValue) {
      return this.$t(`requests.form.properties.frequency.selectOptions.${this.frequencyType}`)
    }
    if (this.frequencyType && this.frequencyValue) {
      switch (this.frequencyType) {
        case 'perMonth':
        case 'perQuarter':
        case 'perYear':
          return this.frequencyValue + this.$t(`requests.form.properties.frequency.optionsToText.${this.frequencyType}`)
        case 'perWeek':
          return this.$t('requests.form.properties.frequency.optionsToText.every') + this.translateWeekDays
        case 'other':
          return this.frequencyValue
        default:
          return null
      }
    }
    return null
  }

  get translateWeekDays() {
    if (this.frequencyType === 'perWeek' && this.frequencyValue) {
      let result = this.frequencyValue.split(',').map(day => this.$t(`requests.form.properties.frequency.days.${day}`))
      return result.length > 1
        ? result
            .reduce(
              (prev, curr, i) =>
                //@ts-ignore
                prev +
                curr +
                (i === result.length - 2
                  ? ` ${this.$t('requests.form.properties.frequency.optionsToText.and')} `
                  : ', '),
              ''
            )
            //@ts-ignore
            .slice(0, -2)
        : result[0]
    }
    return ''
  }

  created() {
    this.populateFrequency()
  }

  closeDropdown() {
    if (this.isDropdownOpen) {
      //@ts-ignore
      this.$refs['frequency-dropdown'].closeDropdown()
    }
  }

  clear() {
    this.frequencyType = null
    this.frequencyValue = null
    this.$emit('input', null)
    if (this.isModalOpen) this.isModalOpen = false
    if (this.isDropdownOpen) {
      //@ts-ignore
      this.$refs['frequency-dropdown'].closeDropdown()
    }
  }
}
