












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ButtonIcon extends Vue {
  @Prop() text: string
  @Prop() iconName: string
  @Prop() callback!: (e: Event) => void

  btnClicked(e: Event) {
    e.preventDefault()
    this.callback(e)
  }
}
