// @ts-ignore
import variables from '@/assets/scss/variables/_colors.scss'

interface Itheme {
  '--primary': string
  '--secondary': string
  '--light': string
  '--lighter': string
  '--boxBackground': string
  '--active': string
  '--textPrimary': string
  '--borderColor': string
  '--selectedColor': string
  '--subtitleColor': string
}
export default {
  setTheme(name?: string) {
    if (!name) {
      const themes = ['red', 'green', 'orange']
      name = themes[Math.floor(Math.random() * themes.length)] as 'red' | 'green' | 'orange'
    }

    const theme: Itheme = this.getTheme(name)
    const root = document.documentElement
    for (const key in theme) {
      if (Object.prototype.hasOwnProperty.call(theme, key)) {
        const element = (theme as any)[key]
        root.style.setProperty(key, element)
      }
    }
  },

  getTheme(name: string): Itheme {
    const themeMap: { [key: string]: Itheme } = {
      red: {
        '--primary': variables.red100,
        '--secondary': variables.red75,
        '--light': variables.red50,
        '--lighter': variables.red25,
        '--boxBackground': variables.white,
        '--active': variables.blue,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      green: {
        '--primary': variables.green100,
        '--secondary': variables.green75,
        '--light': variables.green50,
        '--lighter': variables.green25,
        '--boxBackground': variables.white,
        '--active': variables.blue,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      orange: {
        '--primary': variables.orange100,
        '--secondary': variables.gray100,
        '--light': variables.orange50,
        '--lighter': variables.orange25,
        '--boxBackground': variables.white,
        '--active': variables.orange100,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      cobalt: {
        '--primary': variables.cobalt100,
        '--secondary': variables.cobalt75,
        '--light': variables.cobalt50,
        '--lighter': variables.cobalt50,
        '--boxBackground': variables.white,
        '--active': variables.cobalt100,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      purple: {
        '--primary': variables.purple100,
        '--secondary': variables.purple75,
        '--light': variables.purple50,
        '--lighter': variables.purple50,
        '--boxBackground': variables.white,
        '--active': variables.purple100,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      magenta: {
        '--primary': variables.magenta100,
        '--secondary': variables.magenta75,
        '--light': variables.magenta50,
        '--lighter': variables.magenta50,
        '--boxBackground': variables.white,
        '--active': variables.magenta100,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
      rose: {
        '--primary': variables.rose100,
        '--secondary': variables.rose75,
        '--light': variables.rose50,
        '--lighter': variables.rose50,
        '--boxBackground': variables.white,
        '--active': variables.rose100,
        '--textPrimary': variables.gray100,
        '--borderColor': variables.gray5,
        '--selectedColor': variables.red50,
        '--subtitleColor': variables.gray50,
      },
    }

    return themeMap[name]
  },
}
