

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PeopleInput extends Vue {
  @Prop({ required: true }) min: any
  @Prop({ required: true }) max: any
  @Prop({ required: false, default: false }) invalidMax: boolean

  @Watch('min')
  onMinChanged() {
    this.populateMinMax()
  }

  @Watch('max')
  onBudgetChange() {
    this.populateMinMax()
  }

  selectedMin: null | number = null
  selectedMax: null | number = null

  populateMinMax() {
    this.selectedMin = this.min
    this.selectedMax = this.max
  }

  mounted() {
    this.populateMinMax()
    //@ts-ignore
    this.$refs.max.focus()
  }

  isNumberKey(e: KeyboardEvent) {
    if (!/^[0-9]$/i.test(e.key)) {
      e.preventDefault()
    }
  }
}
