import { render, staticRenderFns } from "./SupplierAboutSection.vue?vue&type=template&id=666da1da&scoped=true&"
import script from "./SupplierAboutSection.vue?vue&type=script&lang=ts&"
export * from "./SupplierAboutSection.vue?vue&type=script&lang=ts&"
import style0 from "./SupplierAboutSection.vue?vue&type=style&index=0&id=666da1da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666da1da",
  null
  
)

export default component.exports