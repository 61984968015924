







import Vue from 'vue'

const Label = Vue.extend({
  components: {},
  props: {
    theme: { type: String, default: '' }
  }
})
export default Label
