







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { ISearchResult } from '../../shared/components/interfaces/search.interface'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
//@ts-ignore
import ClickOutside from 'vue-click-outside'
import DropdownContentBox from './DropdownContentBox.vue'
import DropdownRowTitle from './DropdownRowTitle.vue'
import DropdownRow from './DropdownRow.vue'
import SearchApi from '../../shared/services/api/searchApi'
import SearchNothingFound from './SearchNothingFound.vue'
import SearchPopularServices from './SearchPopularServices.vue'
import SearchTalkToUs from './SearchTalkToUs.vue'
import SearchResults from './SearchResults.vue'
import SearchStore from '@/store/modules/searchStore'

const searchStore = getModule(SearchStore)

interface IResultOption {
  hasMore: boolean
  isHidden: boolean
  hiddenItemCount: number
  index: number
}

@Component({
  components: {
    LabelSmall,
    DropdownContentBox,
    DropdownRowTitle,
    DropdownRow,
    SearchNothingFound,
    SearchPopularServices,
    SearchTalkToUs,
    SearchResults
  },
  directives: { ClickOutside }
})
export default class SearchBarV2 extends Vue {
  @Prop() toggleSearch: void
  searchResults: ISearchResult | undefined = {}
  searchValue = ''
  timerId: number
  toggleResults = false
  searchStore = searchStore
  services: string[] = []
  isFocused = false
  searching = false
  resultsOptions: {
    offering: IResultOption
    supplier: IResultOption
    service: IResultOption
  } = {
    offering: {
      hasMore: false,
      isHidden: true,
      hiddenItemCount: 0,
      index: 0
    },
    supplier: {
      hasMore: false,
      isHidden: true,
      hiddenItemCount: 0,
      index: 0
    },
    service: {
      hasMore: false,
      isHidden: true,
      hiddenItemCount: 0,
      index: 0
    }
  }
  $t: (str: string) => ''

  get search() {
    return this.searchValue
  }

  set search(newValue: string) {
    this.isFocused = true
    this.searchValue = newValue
    this.debounceSearch(newValue)
  }

  closeDropdown() {
    this.toggleResults = false
  }

  resetSearch() {
    this.resultsOptions.offering.isHidden = true
    this.resultsOptions.offering.hiddenItemCount = 0
    this.resultsOptions.supplier.hiddenItemCount = 0
    this.resultsOptions.supplier.isHidden = true
    this.resultsOptions.service.hiddenItemCount = 0
    this.resultsOptions.service.isHidden = true
  }

  mounted() {
    this.setDefaultServices()
    if (this.$mq !== 'mobile') {
      //@ts-ignore
      this.$refs.search.focus()
      this.$root.$el.classList.add('no-scroll')
    }
  }

  unmounted() {
    this.$root.$el.classList.remove('no-scroll')
  }

  talkToUsClicked() {
    this.disableSearchResult()
    this.$emit('toggleSearch')
    this.$router.push({ name: 'concierge' })
  }

  setDefaultServices() {
    this.services = [
      this.$t('labels.searchbox.defaultServices.officeCleaning'),
      this.$t('labels.searchbox.defaultServices.handyman'),
      this.$t('labels.searchbox.defaultServices.coffee'),
      this.$t('labels.searchbox.defaultServices.fruit')
    ]
    this.searchStore.setTotalIndexes(this.services.length)
    //   this.totalIndexes = this.services.length
  }

  scrollActiveItemToView() {
    setTimeout(() => {
      //@ts-ignore
      this.$el.querySelector('.search-results').scroll(0, this.$el.querySelector('.active-item').offsetTop - 100)
    }, 10)
  }

  nextItem(event: KeyboardEvent) {
    // on esc
    if (event.keyCode === 27) this.$emit('toggleSearch')
    // on arrow down
    if (event.keyCode === 40) {
      if (this.searchStore.activeIndex < this.searchStore.lastIndex) {
        this.searchStore.setActiveIndex(this.searchStore.activeIndex + 1)
      } else {
        this.searchStore.setActiveIndex(0)
      }
      this.scrollActiveItemToView()
    }
    // on arrow up
    if (event.keyCode === 38) {
      if (this.searchStore.activeIndex < 1) {
        this.searchStore.setActiveIndex(this.searchStore.lastIndex)
      } else {
        this.searchStore.setActiveIndex(this.searchStore.activeIndex - 1)
      }
      this.scrollActiveItemToView()
    }
    // on enter
    if (event.keyCode === 13) {
      if (this.searchStore.activeIndex === this.searchStore.lastIndex) {
        //@ts-ignore
        this.$el.querySelector('.active-item')?.click()
      }
      if (JSON.stringify(this.searchResults) !== '{}') {
        //@ts-ignore
        if (this.$el.querySelector('.active-item a')) {
          //@ts-ignore
          this.$el.querySelector('.active-item a').click()
        } else {
          //@ts-ignore
          this.$el.querySelector('.active-item')?.click()
        }
      } else {
        //@ts-ignore
        this.$el.querySelector('.active-item')?.click()
      }
    }
  }
  disableSearchResult() {
    this.toggleResults = false
    this.isFocused = false
    this.searchValue = ''
    this.searchResults = {}
    this.searchStore.setTotalIndexes(this.services.length)
    this.searchStore.setActiveIndex(0)
  }

  cleanSearch() {
    //@ts-ignore
    this.$refs.search.focus()
    this.searchValue = ''
    this.searchResults = {}
    this.searchStore.setTotalIndexes(this.services.length)
    this.searchStore.setActiveIndex(0)
  }

  searchService(service: string, e: any) {
    e.stopPropagation()
    this.isFocused = false
    this.searchValue = service
    this.searchStore.setActiveIndex(0)

    this.findSearchItem(service)
  }

  debounceSearch(value: string) {
    this.toggleResults = true
    this.isFocused = false
    if (this.timerId) {
      window.clearTimeout(this.timerId)
    }
    this.timerId = window.setTimeout(
      (
        value => () =>
          this.findSearchItem(value)
      )(value),
      400
    )
  }

  async findSearchItem(item: string) {
    this.resetSearch()
    if (item === '') {
      this.isFocused = true
      this.searchResults = {}
      this.searchStore.setTotalIndexes(this.services.length)
      return
    }
    this.searching = true
    this.searchResults = await SearchApi.search(item)
    this.searchStore.setActiveIndex(0)

    this.isFocused = true
    this.searching = false
  }

  @Watch('$route')
  onRouteChange() {
    this.$emit('toggleSearch')
    this.$root.$el.classList.remove('no-scroll')
  }
}
