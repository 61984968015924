












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import DropdownRow from './DropdownRow.vue'
import DropdownRowTitle from './DropdownRowTitle.vue'
import {
  ISearchResult,
  ISearchSupplier,
  ISearchOffering,
  ISearchService
} from '../../shared/components/interfaces/search.interface'
import { getModule } from 'vuex-module-decorators'
import SearchStore from '@/store/modules/searchStore'

const searchStore = getModule(SearchStore)

interface IResultOption {
  hasMore: boolean
  isHidden: boolean
  hiddenItemCount: number
  index: number
}
@Component({
  components: { DropdownRow, DropdownRowTitle }
})
export default class SearchResults extends Vue {
  @Prop() searchResults: ISearchResult
  @Prop() resultsOptions: {
    offering: IResultOption
    supplier: IResultOption
    service: IResultOption
  }
  searchStore = searchStore
  searchIndex = 0
  initialLimit = 2
  @Prop() searchQuery: string

  @Watch('filteredResult', { deep: true })
  onResultsUpdate() {
    this.searchIndex = 0
    for (const key in this.filteredResult) {
      if (Object.prototype.hasOwnProperty.call(this.filteredResult, key)) {
        const filtered = this.filteredResult[key as 'service' | 'supplier' | 'offering']
        const original = this.searchResults[key as 'service' | 'supplier' | 'offering']
        const resultOption = this.resultsOptions[key as 'service' | 'supplier' | 'offering']
        if (original && filtered) {
          resultOption.hiddenItemCount = filtered.length < original.length ? original.length - filtered.length : 0
          for (const limited of filtered) {
            limited.index = this.searchIndex
            this.searchIndex++
          }
          if (filtered.length < original.length) {
            resultOption.index = this.searchIndex
            this.searchIndex++
          }
        }
      }
    }
    searchStore.setTotalIndexes(this.searchIndex)
  }

  get filteredResult(): ISearchResult {
    const grouped: ISearchResult = {}
    grouped.service = this.manageResultGroup('service') as ISearchService[]
    grouped.offering = this.manageResultGroup('offering') as ISearchOffering[]
    grouped.supplier = this.manageResultGroup('supplier') as ISearchSupplier[]
    return grouped
  }

  resultsLabel(hiddenCount: number) {
    return hiddenCount > 1
      ? hiddenCount + ' ' + this.$t('labels.searchbox.results')
      : hiddenCount + ' ' + this.$t('labels.searchbox.result')
  }
  label(groupName: string) {
    if (groupName === 'supplier') {
      return this.$t('supplier.supplier')
    }
    if (groupName === 'offering') {
      return this.$t('supplier.offerings')
    }
    if (groupName === 'service') {
      return this.$t('serviceCategories.categories')
    }
  }
  searchResultLink(groupName: string, searchData: ISearchSupplier | ISearchOffering | ISearchService) {
    if (groupName === 'offering') {
      return `/supplier/${(searchData as ISearchOffering).supplier.id}/${searchData.id}`
    }
    if (groupName === 'service') {
      return `/explore/category/${searchData.id}`
    }
    return `/supplier/${searchData.id}`
  }
  navigate(link: string, event: any) {
    this.$amplitude.logEvent('Marketplace - Search', {
      Query: this.searchQuery,
      Result: event.target.textContent,
      Url: link
    })
    this.$router.push(link)
  }
  icon(groupName: string) {
    if (groupName === 'supplier') {
      return ['eva', 'eva-car-outline']
    }
    if (groupName === 'offering') {
      return ['eva', 'eva-pricetags-outline']
    }
    if (groupName === 'service') {
      return ['eva', 'eva-folder-outline']
    }
  }
  showItems(item: IResultOption, event: any) {
    event.stopPropagation()
    item.isHidden = false
  }

  shouldHideResults(name: keyof ISearchResult) {
    return this.resultsOptions[name!].isHidden && this.initialLimit < this.searchResults[name!]!.length
  }

  manageResultGroup(name: keyof ISearchResult | undefined) {
    let tmp = null
    if (name && this.searchResults?.[name]?.length) {
      tmp = []
      const limit = this.shouldHideResults(name!) ? this.initialLimit : this.searchResults[name!]!.length
      for (let i = 0; i < limit; i++) {
        const result = { ...this.searchResults[name!]![i] }
        tmp.push(result)
      }
    }
    return tmp
  }
}
