


















import { Vue, Component, Prop } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import NavigationLink from '../NavigationLink.vue'
import { IServicePageHero } from '@/shared/services/api.interface'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'
import Label from '@/components/core/Label.vue'
import Button from '@/components/core/Button.vue'

@Component({
  components: { Heading, Label, InlineSvg, Button, NavigationLink, Space }
})
export default class HeroSection extends Vue {
  @Prop({ required: true }) sectionData: IServicePageHero
  @Prop({ required: true }) formId: string
  @Prop() onClick: () => void
}
