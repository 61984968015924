





































































import Vue from 'vue'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import Heading from '@/components/core/Heading.vue'
import Button from '@/components/core/Button.vue'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
// import { IFavorite } from '@/shared/billie/api/favorites'
//   import { SunriseOffering, augmentOffering, fetchFeaturedId } from '@/pages/explore/sunrise/sunriseData'
import {
  SunriseOfferingProperties,
  getPromo,
  fetchFeaturedId,
  fetchSupplierId
} from '@/pages/explore/sunrise/sunriseData'

type Nullable<T> = T | null

const userState = getModule(Auth)

const FeatureHeroBanner = Vue.extend({
  components: { Space, Label, Heading, Button, Modal, ModalHeader },

  data() {
    return {
      featuredOffering: null as Nullable<SunriseOfferingProperties>,
      //favorites: [] as IFavorite[] | [],
      featuredOfferingId: '',
      featuredSupplierId: '',
      showModal: false,
      userState: userState
    }
  },

  // computed: {
  //   isFavorited: function (): boolean {
  //     return this.favorites.filter(item => item.offeringId === this.featuredOffering!.id).length > 0
  //   }
  // },

  methods: {
    //   async handleSaveForLater() {
    //     this.$amplitude.logEvent('Explore: Featured Offering - Sunrise', {
    //       Action: 'Favourited',
    //       Offering: this.featuredOffering?.name,
    //       OfferingID: this.featuredOffering?.id
    //     })
    //     this.showModal = !this.showModal
    //     // const favorite = await this.$billie.favorites.create(this.userState.user.id, this.featuredOffering!.id)
    //     //this.favorites = [...this.favorites, favorite]
    //   },
    readMore() {
      this.$amplitude.logEvent('Explore: Featured Offering - Sunrise', {
        Action: 'Read more', //@ts-ignore
        Offering: this.featuredOffering?.heroPromoHeading
      })
      //this.$router.push(`supplier/${this.featuredOffering?.supplier.id}/${this.featuredOffering?.id}`)
      //this.$router.push(`supplier/${this.featuredSupplierId}/${this.featuredOfferingId}`)
      this.featuredOffering!.isPromo
        ? this.$router.push({ name: 'sunrise.promo' })
        : this.$router.push(`explore/collection/loveyouroffice`)
    }
  },

  async mounted() {
    this.featuredOfferingId = fetchFeaturedId(this.userState.user.client!.countryCode as string)
    this.featuredSupplierId = fetchSupplierId(this.userState.user.client!.countryCode as string)
    this.featuredOffering = getPromo(this.userState.user.client!.countryCode!, this.$locale)
    //this.favorites = await this.$billie.favorites.list(this.userState.user.id)
  }
})

export default FeatureHeroBanner
