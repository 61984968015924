









import Vue from 'vue'
import Component from 'vue-class-component'
import Button from './core/Button.vue'
import { Prop } from 'vue-property-decorator'

@Component({ components: { Button } })
export default class InvoiceReminder extends Vue {
  @Prop({ required: false, default: 1 }) notificationLevel: 1 | 2 | 3
  @Prop({ required: true }) headline: string
  @Prop({ required: true }) bodyText: string
}
