












import Vue from 'vue'
import Space from '@/components/layout/Space.vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: { Space }
})
export default class CtaBubble extends Vue {
  @Prop() icon: string
  @Prop({ required: false }) theme: string
  @Prop() title: string
}
