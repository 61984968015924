








































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import Button from '@/components/core/Button.vue'
import RequestProposalList from '@/components/requests/RequestProposalList.vue'
import { IRequest, IRequestFormData } from '@/shared/components/interfaces/request.interface'
import moment from 'moment'
import Flex from '@/components/layout/Flex.vue'
import RequestProperty from '@/components/requests/RequestProperty.vue'
import BudgetInput from '@/components/requests/BudgetInput.vue'
import TimeframeInput from '@/components/requests/TimeframeInput.vue'
import PeopleInput from '@/components/requests/PeopleInput.vue'
import FrequencyProperty from '@/components/requests/FrequencyProperty.vue'
import CtaBubble from '@/components/core/CtaBubble.vue'
import InputDropdown from '@/components/core/dropdown/InputDropdown.vue'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'
import AttachmentItem from '@/components/core/AttachmentItem.vue'
import AddAttachmentItem from '@/components/core/AddAttachmentItem.vue'

@Component({
  components: {
    Space,
    Flex,
    Label,
    Button,
    RequestProperty,
    RequestProposalList,
    BudgetInput,
    TimeframeInput,
    PeopleInput,
    CtaBubble,
    InputDropdown,
    AttachmentItem,
    Tooltip,
    AddAttachmentItem,
    FrequencyProperty
  }
})
export default class RequestForm extends Vue {
  @Prop({ required: true }) request: IRequest | null
  @Prop({ required: false }) requestPageLabel: string
  @Prop({ required: true }) isEditable: boolean
  @Prop({ required: true }) isCancelled: boolean
  @Prop({ required: true }) formData: IRequestFormData
  @Prop({ required: false }) validationErrors: any
  @Prop({ required: true }) uploadInProgress: boolean

  get sortedProposals() {
    return this.request?.proposals.sort((a, b) => {
      //@ts-ignore
      return moment(b.revisions[0].createdAt) - moment(a.revisions[0].createdAt)
    })
  }

  get ctaBubbleText() {
    if (this.isCancelled) {
      return this.$t('requests.cancelledRequest', {
        newRequest: ` <a href="/requests">${this.$t('requests.newRequest')}</a>`
      })
    } else {
      return this.$t('requests.completedRequest', {
        yourOffice: ` <a href="/dashboard">${this.$t('navigation.home')}</a>`
      })
    }
  }
  get isNewRequest() {
    return this.$route.params.id ? false : true
  }

  addFile(fileInput: File) {
    this.$emit('addFile', fileInput)
  }

  removeFile(index: number) {
    this.$emit('removeFile', index)
  }

  inputChange(e: InputEvent) {
    const target = e.target as HTMLTextAreaElement
    const parent = target.parentNode as HTMLDivElement
    parent.dataset.value = target.value
  }

  isNumberKey(e: KeyboardEvent) {
    if (!/^[0-9]$/i.test(e.key)) {
      e.preventDefault()
    }
  }
}
