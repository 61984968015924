import { IOfferingCategory, IAgreementV2, IClientOffering } from '@/shared/components/interfaces/agreements.interface'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import AgreementsV2 from '@/store/modules/agreementsV2'

const agreementsState = getModule(AgreementsV2)

export default class SubscriptionsHelper {
  static categoryIcons(): string[] {
    return [
      'breakfast',
      'candy',
      'catering',
      'cleaning',
      'coffee',
      'coffee_machine',
      'coffee_tea',
      'consumables',
      'drinks',
      'employee_development',
      'events',
      'fridaybar',
      'fruit',
      'fruit_snacks',
      'handyman',
      'interior_design',
      'it_support',
      'juice',
      'linen',
      'lunch',
      'massage',
      'maintenance',
      'missing',
      'office_assistant',
      'office_supplies',
      'office_yoga',
      'pest_control',
      'plants',
      'flowers',
      'printer_service',
      'shopping',
      'snacks',
      'snacks_nuts',
      'soap',
      'soda',
      'storage',
      'waste_disposal',
      'wellness',
      'window_cleaning',
    ]
  }

  static async subscriptionCategories() {
    if (!agreementsState.offeringCategories.length) {
      const subscriptionCategories = await agreementsState.getOfferingCategories()
      return subscriptionCategories.filter((item: IOfferingCategory) => item.enableSubscriptions)
    }
    return agreementsState.allOfferingCategories.filter((item: IOfferingCategory) => item.enableSubscriptions)
  }

  static subscriptionStatus(agreementEnd: string | null, agreementStart: string | null) {
    if (agreementEnd && moment(agreementEnd).isBefore(moment())) return 'terminated'
    if (agreementStart && moment(agreementStart).isAfter(moment())) return 'pending'
    if (agreementEnd && moment(agreementEnd).isAfter(moment())) return 'ending'
    return 'active'
  }

  static clientSubscriptions(subscriptions: IAgreementV2[]): IClientOffering[] {
    return subscriptions.reduce((acc: any, agreement: IAgreementV2) => {
      if (agreement.invoiceSchedule !== 'once') {
        acc.push({
          id: agreement.id,
          slug: agreement.revisions[0].category,
          title: agreement.revisions[0].title.toLowerCase(),
          priority: 0,
          agreementEnd: agreement.agreementEnd,
          agreementStart: agreement.agreementStart,
          current: !agreement.agreementEnd ? true : !moment(agreement.agreementEnd).isBefore(moment()),
          status: this.subscriptionStatus(agreement.agreementEnd, agreement.agreementStart),
          supplier: agreement.supplier,
        })
      }
      return acc
    }, [])
  }

  static categoriesWithoutSubscription(categories: IOfferingCategory[], currentSubscriptions: IClientOffering[]) {
    const categoriesWithoutSubscription = categories.filter((item) => {
      return !currentSubscriptions.some((f) => {
        return item.slug === f.slug
      })
    })

    return categoriesWithoutSubscription
      .filter((category) => category.priority > 0)
      .sort((a, b) => a.priority - b.priority)
  }

  static getCategoryIcon(category: IClientOffering) {
    category.slug !== null ? category.slug.toLowerCase() : null
    return category.slug && this.categoryIcons().includes(category.slug)
      ? `/icons/${category.slug}.svg`
      : '/icons/missing.svg'
  }

  static getTitle(agreement: IAgreementV2 | null, categories: IOfferingCategory[]): string {
    if (agreement?.revisions[0].title) {
      return agreement.revisions[0].title
    } else {
      for (const category of categories) {
        if (category.slug === agreement?.revisions[0].category) {
          return category.title
        }
      }
      return ''
    }
  }
}
