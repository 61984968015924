








import { Component, Prop, Vue } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'

@Component({
  components: { LabelSmall }
})
export default class FormWizardNav extends Vue {
  @Prop({ required: true }) size: number
  @Prop({ required: true }) active: number
}
