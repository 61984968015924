import axios from 'axios'
import Vue from 'vue'
import { IStoryblokPayload } from '@/shared/components/interfaces/storyblok.interface'

declare global {
  interface Window {
    storyblok: {
      init: (param: { accessToken: string }) => void
      on: (param1: string, param2: () => void) => void
      pingEditor: (param: () => void) => void
    }
  }
}

export default {
  getStory(slug: string, version: 'draft' | 'published'): Promise<IStoryblokPayload> {
    return axios
      .get(`cdn/stories/${slug}`, {
        baseURL: 'https://api.storyblok.com/v1/',
        params: {
          version,
          token: Vue.prototype.$storyblokKey,
        },
        transformRequest: [
          (data, headers) => {
            delete headers.common
            return data
          },
        ],
      })
      .then((response) => response.data.story)
  },

  initializeStoryBlok(storyUrl: string, callback: (story: IStoryblokPayload) => void) {
    if (window.storyblok) {
      window.storyblok.init({
        accessToken: Vue.prototype.$storyblokKey,
      })

      window.storyblok.on('change', () => {
        this.getStory(storyUrl, 'draft').then((story) => {
          callback(story)
        })
      })

      window.storyblok.pingEditor(() => {
        this.getStory(storyUrl, 'draft').then((story) => {
          callback(story)
        })
      })
    }
  },
}
