







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore
import variables from '@/assets/scss/variables/_sizes.scss'

interface IGridProp {
  col: number
  direction?: 'row' | 'column'
  justify?: 'start' | 'center' | 'end' | 'between' | 'around'
  align?: 'top' | 'middle' | 'bottom'
  reverse?: boolean
  order?: number
}

@Component({
  components: {}
})
export default class Grid extends Vue {
  @Prop() xxs: IGridProp
  @Prop() xs: IGridProp
  @Prop() sm: IGridProp
  @Prop() md: IGridProp
  @Prop() lg: IGridProp
  @Prop() xl: IGridProp
  @Prop() xxl: IGridProp
  @Prop({ default: () => [1] }) gap: number[]

  get classes() {
    let classValues = ''
    const gridProps = { xxs: this.xxs, xs: this.xs, sm: this.sm, md: this.md, lg: this.lg, xl: this.xl, xxl: this.xxl }
    Object.entries(gridProps).forEach(([key, value]) => {
      if (value !== undefined) {
        Object.entries(value).forEach(([k, v]) => {
          if (k === 'col') classValues += `grid-${v}-col-${key} `
          else classValues += `${v}-${key} `
        })
      }
    })
    return classValues
  }

  get responsiveSpacing() {
    switch (this.$mq) {
      case 'desktop':
        return variables.defaultSpacing * 5
      case 'desktopLg':
        return variables.defaultSpacing * 5
      case 'desktopXl':
        return variables.defaultSpacing * 5
      default:
        return variables.defaultSpacing * 4
    }
  }

  get computedSpacing() {
    const spacing = this.gap.reduce((acc, val): any => {
      return `${acc * this.responsiveSpacing}px ${val * this.responsiveSpacing}px`
    })
    return this.gap.length > 1 ? spacing : this.gap[0] * this.responsiveSpacing + 'px'
  }
}
