




























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Flex from '@/components/layout/Flex.vue'
import Label from '@/components/core/Label.vue'
import CalendarEventModel from '@/shared/models/CalendarEventModel'
import type { ICalendarEvent } from '@/shared/billie/api/calendar/events'

@Component({
  components: { Flex, Label }
})
export default class AgreementEvent extends Vue {
  @Prop({ required: true }) event: ICalendarEvent

  get startDateDayName() {
    return CalendarEventModel.startTime(this.event).format('dddd')
  }

  get startDateDay() {
    return CalendarEventModel.startTime(this.event).format('D')
  }

  get startDateMonth() {
    return CalendarEventModel.startTime(this.event).format('MMM')
  }

  get humanReadableRecurrence() {
    return CalendarEventModel.humanReadableRecurrence(this.event, this.$locale)
  }

  get isAllDay() {
    return this.event.editable ? CalendarEventModel.isAllDay(this.event) : false
  }

  get isMultiDayEvent() {
    return this.event.editable ? CalendarEventModel.isMultiDayEvent(this.event) : false
  }

  get eventTime() {
    return this.event.editable ? CalendarEventModel.getEventTime(this.event) : ''
  }

  get multiDayLabel() {
    return this.event.editable
      ? this.$t('subscriptions.multiDayLabel', {
          start: CalendarEventModel.startTime(this.event).format('dddd D'),
          end: CalendarEventModel.endTime(this.event).subtract(1).format('dddd D')
        })
      : ''
  }
}
