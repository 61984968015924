






















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import agreementsApi from '@/shared/services/api/agreementsApi'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'
import IconButton from '@/components/core/IconButton.vue'
import Label from '@/components/core/Label.vue'
import FormattedPrice from '@/components/FormattedPrice.vue'
import Element from '@/services/rpa/element'
import { IElement } from '@/shared/services/api.interface'
import Space from '@/components/layout/Space.vue'
import Tag from '@/components/ui/Tag.vue'
import Button from '@/components/core/Button.vue'
import WhatV2 from '@/components/agreements/WhatV2.vue'
import moment from 'moment'
import { ICalendarEvent } from '@/shared/billie/api/calendar/events'
import SubscriptionsHelper from '@/services/helpers/subscriptionsHelper'
import AgreementEvent from '@/components/agreements/AgreementEvent.vue'
import AttachmentItem from '@/components/core/AttachmentItem.vue'
import CtaBubble from '@/components/core/CtaBubble.vue'
import ChatPageTemplate from '@/components/ChatPageTemplate.vue'
import routerGoBack from '@/mixins/routerGoBack'

const userState = getModule(Auth)

@Component({
  components: {
    Space,
    Flex,
    ChatPageTemplate,
    Heading,
    Tag,
    Label,
    Button,
    IconButton,
    AgreementEvent,
    FormattedPrice,
    WhatV2,
    AttachmentItem,
    CtaBubble
  },
  mixins: [routerGoBack]
})
export default class Agreement extends Vue {
  agreementData: IAgreementV2 | null = null
  events: ICalendarEvent[] = []
  agreementStatus = ''
  agreementType: any = ''

  get isTerminated() {
    return this.agreementStatus === 'terminated' ? true : false
  }

  get getAgreementTag() {
    const subcriptionString = this.$t('subscriptions.subscription')
    this.agreementType = this.agreementData!.invoiceSchedule === 'once' ? this.$t('supplier.order') : subcriptionString

    if (this.agreementData) {
      this.agreementStatus = SubscriptionsHelper.subscriptionStatus(
        this.agreementData.agreementEnd,
        this.agreementData.agreementStart
      )
      switch (this.agreementStatus) {
        case 'ending':
          return `${this.agreementType} ${this.$t('common.dates.ending')} ${moment(
            this.agreementData.agreementEnd
          ).format('MMM. Do')}`
        case 'terminated':
          return `${this.agreementType} ${this.$t('subscriptions.terminated')} ${moment(
            this.agreementData.agreementEnd
          ).fromNow()}`
        case 'pending':
          return `${this.agreementType} ${this.$t('common.dates.starting')} ${moment(
            this.agreementData.agreementStart
          ).format('MMM. Do')}`
      }
    }

    if (this.agreementData!.revisions.find(({ type }) => type === 'termination')) {
      this.agreementStatus = 'terminated'

      //@ts-ignore
      return `${this.$t('requests.status.cancelled')} ${this.$t('supplier.order').toLowerCase()}`
    }
    return this.agreementType === subcriptionString
      ? `${this.$t('subscriptions.activeSubscription')}`
      : this.agreementType
  }

  total(elements: IElement[]) {
    return elements ? elements.map(Element.total).reduce((sum, amount) => sum + amount, 0) : null
  }

  get ctaBubbleText() {
    if (this.agreementType === 'Order' || this.agreementType === 'Ordre') {
      return this.$t('orders.cancelledOrder', {
        newRequest: ` <a href="/requests">${this.$t('requests.newRequest')}</a>`
      })
    } else {
      return this.$t('subscriptions.cancelledSubscription', {
        newRequest: ` <a href="/requests">${this.$t('requests.newRequest')}</a>`
      })
    }
  }

  async created() {
    this.agreementData = await agreementsApi.getAgreementByIdV2ClientView(this.$route.params.id as string)
    this.events = await this.$billie.calendar.events.list(userState.clientId, `agreement:${this.$route.params.id}`)
    this.events.reverse()
  }
}
