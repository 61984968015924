








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

interface IFlexProp {
  wrap?: 'wrap' | 'nowrap'
  direction?: 'row' | 'column'
  justify?: 'start' | 'center' | 'end' | 'between' | 'around'
  align?: 'top' | 'middle' | 'bottom'
  reverse?: 'reverse' | 'default'
  order?: number
}

@Component({
  components: {}
})
export default class Flex extends Vue {
  @Prop() xs: IFlexProp
  @Prop() sm: IFlexProp
  @Prop() md: IFlexProp
  @Prop() lg: IFlexProp
  @Prop() xl: IFlexProp
  @Prop() gap: number[] | number

  get styles() {
    return Array.isArray(this.gap)
      ? this.gap
          .map(val => {
            return val + 'px'
          })
          .join(' ')
      : `${this.gap}px`
  }

  get classes() {
    let classValues = ''
    const rowProps = { xs: this.xs, sm: this.sm, md: this.md, lg: this.lg, xl: this.xl }
    Object.entries(rowProps).forEach(([key, value]) => {
      if (value !== undefined) {
        classValues += ` ${Object.values(value)
          .map(v => v + '-' + key)
          .join(' ')}`
      }
    })
    return classValues
  }
}
