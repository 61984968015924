















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ISuppliersService } from '@/shared/components/interfaces/supplierData.interface'

@Component({
  components: {}
})
export default class SupplierProfilePicture extends Vue {
  @Prop() serviceData: ISuppliersService
  @Prop() link: string

  navigateToSupplierPage() {
    if (this.$route.path !== '/supplier/' + this.$route.params.supplierId && this.$route.params.supplierId) {
      this.$router.push('/supplier/' + this.$route.params.supplierId)
    }
  }
}
