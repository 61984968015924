






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import Modal from '@/components/core/modal/Modal.vue'
import Button from '@/components/core/Button.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import { getModule } from 'vuex-module-decorators'
import ClientStateStore from '@/store/modules/clientStateStore'

const clientStateStore = getModule(ClientStateStore)

@Component({
  components: {
    Modal,
    ModalHeader,
    Button
  }
})
export default class LockedClientModal extends Vue {
  @Prop({ required: true }) isShowing: boolean
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) level: number

  navigate(level: number) {
    clientStateStore.hideShowModal(false)
    if (level > 3) {
      this.$router.push(`/concierge`)
    } else {
      this.$router.push(`/invoices`)
    }
  }
}
