
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Emoji from '@/components/core/Emoji.vue'
import { IFileResponseData } from '../../shared/components/interfaces/fileHandler.interface'
import AttachmentItem from '@/components/core/AttachmentItem.vue'

@Component({
  components: { Emoji, AttachmentItem }
})
export default class MessageInput extends Vue {
  @Prop() uploadInProgress: boolean
  @Prop() uploadedFiles: IFileResponseData[]
  @Prop({ default: false }) showDisconnected: boolean

  text = ''
  attachments: File[] = []
  enterClicked = false

  @Watch('text')
  onTextUpdate() {
    this.emit()
  }

  inputFocus() {
    this.$emit('onInputFocus')
  }

  onKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case 'Enter':
        this.handleEnterKeyDown(e)
        break
      case 'Escape':
      default:
        return
    }
  }

  handleEnterKeyDown(e: KeyboardEvent) {
    if (e.shiftKey) {
      this.enterClicked = true
      e.preventDefault()
      this.sendMessage()
    }
  }

  get fileToken() {
    return localStorage.getItem('file_token')
  }

  removeFile(file: IFileResponseData) {
    this.$emit('removeFile', file)
    this.attachments = []
  }

  sendMessage() {
    if (!this.uploadInProgress) {
      this.trackClickEvent()
      this.$emit('sendMessage')
      this.text = ''
      this.attachments = []
    }
  }

  trackClickEvent() {
    this.$amplitude.logEvent('Message - Send Message', {
      chatId: this.$route.path,
      hasText: this.text.length > 0,
      hasAttachments: this.attachments.length > 0
    })
  }

  filesSelected(event: Event) {
    const target = event.target as HTMLInputElement
    const targetFiles = target.files

    if (targetFiles) {
      this.attachments = [...targetFiles]
      // TODO: Support multiple files
      // this.attachments = [...this.attachments, ...files]
      this.$emit('addFile', this.attachments)
      target.value = ''
    }
  }

  emit() {
    this.$emit('input', {
      text: this.text,
      attachments: this.uploadedFiles
    })
  }

  @Watch('uploadedFiles')
  onFileChange() {
    this.emit()
    this.attachments = []
  }
}
