
























import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { flags } from '../../flags'
import AppKey from '@/shared/storeModules/appKey'
import SwitchInputDynamic from '@/components/legacy_components/SwitchInputDynamic.vue'
import FeatureFlags from '@/shared/FeatureFlags'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'

const appState = getModule(AppKey)
@Component({
  components: { Space, Heading, SwitchInputDynamic }
})
export default class FlagsPanel extends Vue {
  flags = flags
  flagsValues = flags
  switchModelValue = false

  created() {
    for (var flagName of Object.keys(flags)) {
      const enabled = FeatureFlags.isEnabled(flagName)

      if (enabled) {
        this.flagsValues[flagName] = true
      } else {
        this.flagsValues[flagName] = false
      }
    }
  }

  enableDisableFlag(enabled: boolean, flag: string) {
    if (this.flagsValues[flag] !== enabled) {
      this.flagsValues[flag] = enabled

      if (enabled) {
        FeatureFlags.enable(flag)
      } else {
        FeatureFlags.disable(flag)
      }

      this.refreshApp()
    }
  }

  refreshApp() {
    appState.updateKey()
  }
}
