







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Emoji extends Vue {
  @Prop() emoji: string
  @Prop() size: number
  @Prop() padding: number
  @Prop() margin: number
  @Prop() backgroundColor: string
}
