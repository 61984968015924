




import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'

const authState = getModule(Auth)

@Component({
  components: {}
})
export default class Logout extends Vue {
  mounted() {
    authState.logout().then(() => {
      this.$router.push({ name: 'Login' })
    })
  }
}
