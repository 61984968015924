












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMessage } from './IMessage'
import DateSeparator from './DateSeparator.vue'
import Message from './Message.vue'
import moment from 'moment'
import Emoji from '@/components/core/Emoji.vue'

@Component({
  components: {
    DateSeparator,
    Message,
    Emoji
  }
})
export default class Button extends Vue {
  @Prop({ required: true }) messages: IMessage[]
  @Prop({ required: true }) loading: boolean
  @Prop({ required: false, default: true }) isActive: boolean

  time(time: string) {
    return moment(time).format('HH:mm')
  }

  checkTimeDifference(a: string, b: string) {
    let duration = moment.duration(moment(b).diff(moment(a)))
    return duration.asMinutes()
  }
}
