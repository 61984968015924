



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ISupplierData } from '../../shared/components/interfaces/supplierData.interface'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import Avatar from '@/components/core/Avatar.vue'

@Component({
  components: { LabelNormal, LabelSmall, Avatar }
})
export default class MeetThePeople extends Vue {
  @Prop({ required: true }) companyData: ISupplierData

  initials(name: string) {
    const nameArr = name.split(' ')
    return nameArr[0] && nameArr[1]
      ? { firstName: nameArr[0].charAt(0).toUpperCase(), lastName: nameArr[1].charAt(0).toUpperCase() }
      : {}
  }
}
