





























import Vue from 'vue'
import Component from 'vue-class-component'
import SupplierApi from '@/shared/services/api/supplierApi'
import StoryblockApi from '@/services/api/storyblockApi'
import { ICarouselItem, ICarousels, IStoryblokPayload } from '@/shared/components/interfaces/storyblok.interface'
import { ISuppliersService } from '@/shared/components/interfaces/supplierData.interface'
import ContentPlaceholder from '@/components/ContentPlaceholder.vue'
import CardElement from '@/components/explore/CardElement.vue'
import AmplitudeHelper from '../../services/helpers/amplitudeHelper'
import Grid from '@/components/layout/Grid.vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'

@Component({
  components: { Grid, Space, Heading, ContentPlaceholder, CardElement }
})
export default class CollectionPage extends Vue {
  carouselData: ICarouselItem | null = null
  offerings: ISuppliersService[] = []
  story: IStoryblokPayload | null = null
  carousel: ICarousels

  goBack() {
    this.$router.push({ path: `/${this.$route.params.url}` })
  }

  async mounted() {
    this.offerings = await SupplierApi.getOfferings()
    if (this.story?.content.carousels) {
      this.carousel = this.story?.content.carousels.filter(
        carousel => carousel.carousel_tag === this.$route.params.id
      )[0]
      if (this.carousel) {
        this.carouselData = {
          carousel_name: this.carousel.carousel_name,
          carousel_tag: this.carousel.carousel_tag,
          carousel_style: this.carousel.carousel_style
        }
        this.carouselData.items = this.offerings.filter(
          offering => offering.tags && offering.tags.includes(this.carousel.carousel_tag)
        )
      }
    }
    AmplitudeHelper.amplitude
      .getInstance()
      .logEvent('Marketplace - Collection', { Collection: this.carouselData?.carousel_name })
  }
  get storyUrl() {
    if (this.$route.params.url === 'explore') {
      return this.exploreUrl
    } else return this.dashboardUrl
  }

  get exploreUrl() {
    switch (this.$locale) {
      case 'da':
      case 'da-DK':
        return 'da/explore/service-categories'
      case 'en':
      case 'en-US':
      case 'en-GB':
        return 'en/explore/service-categories'
      default:
        return 'explore/service-categories'
    }
  }

  get dashboardUrl() {
    switch (this.$locale) {
      case 'da':
      case 'da-DK':
        return 'da/dashboard'
      case 'en':
      case 'en-US':
      case 'en-GB':
        return 'en/dashboard'
      default:
        return 'dashboard'
    }
  }

  created() {
    StoryblockApi.initializeStoryBlok(this.storyUrl, story => {
      this.story = story
    })
  }
}
