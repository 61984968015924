















import { Component, Prop, Vue } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import { IServicePageCta } from '@/shared/services/api.interface'
import Heading from '@/components/core/Heading.vue'
import Button from '@/components/core/Button.vue'
import Flex from '@/components/layout/Flex.vue'

@Component({
  components: { Flex, Heading, Button, InlineSvg }
})
export default class CtaSection extends Vue {
  @Prop({ required: true }) sectionData: IServicePageCta
  @Prop({ required: true }) formId: string
  @Prop() onClick: () => void
}
