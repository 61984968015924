import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'

declare module 'vue/types/vue' {
  interface Vue {
    fromRoute: null | Route
    goBack(): any
  }
}

@Component({
  //@ts-ignore
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromRoute = from
    })
  },
})
class routerGoBack extends Vue {
  fromRoute: null | Route = null

  goBack(): any {
    if (this.fromRoute && this.fromRoute.name) {
      this.$router.back()
    } else {
      this.$router.push('/')
    }
  }
}
export default routerGoBack
