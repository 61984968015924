



































import { Component, Prop, Vue } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import { IServicePageBennefit } from '@/shared/services/api.interface'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'

@Component({
  components: { Heading, Space, InlineSvg }
})
export default class BenefitsSection extends Vue {
  @Prop({ required: true }) sectionData: IServicePageBennefit
}
