
































































































































































import Vue from 'vue'
import Auth from '@/shared/storeModules/auth'
import ClientStateStore from '@/store/modules/clientStateStore'
import CardElement from '@/components/explore/CardElement.vue'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'
import Grid from '@/components/layout/Grid.vue'
import Button from '@/components/core/Button.vue'
import Flex from '@/components/layout/Flex.vue'
import Label from '@/components/core/Label.vue'
import FloatingCta from '@/components/core/FloatingCta.vue'
import { getModule } from 'vuex-module-decorators'
import ImagePreviewModal from '@/components/core/modal/ImagePreviewModal.vue'
import IconButton from '@/components/core/IconButton.vue'
import { IFavorite } from '@/shared/billie/api/favorites'
import routerGoBack from '@/mixins/routerGoBack'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import { SunriseOffering, augmentOffering } from '@/pages/explore/sunrise/sunriseData'

type Nullable<T> = T | null

const userState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

const Offering = Vue.extend({
  components: {
    Grid,
    Flex,
    Space,
    Heading,
    Button,
    FloatingCta,
    CardElement,
    Label,
    ImagePreviewModal,
    IconButton,
    Modal,
    ModalHeader
  },
  mixins: [routerGoBack],

  data() {
    return {
      offering: null as Nullable<SunriseOffering>,
      favorites: [] as IFavorite[],
      supplierInfoOpened: false,
      showImageModal: false,
      currentImagePreviewIndex: null as Nullable<number> | null,
      showFavoriteModal: false
    }
  },

  computed: {
    clientStateStore: () => clientStateStore,
    userState: () => userState,
    supplierId: function (): string {
      return this.$route.meta!.supplierId
    },
    offeringId: function (): string {
      return this.$route.meta!.offeringId
    },
    isFavorited: function (): boolean {
      return this.favorites.filter(item => item.offeringId === this.offeringId).length > 0
    }
  },

  methods: {
    toggleImageModal(index: number) {
      this.currentImagePreviewIndex = index
      this.showImageModal = !this.showImageModal
      if (this.showImageModal) {
        this.$amplitude.logEvent('Offering: Gallery Image Opened (Sunrise)', {
          Offering: this.offering?.name,
          Supplier: this.offering?.supplier.name
        })
      }
    },

    goToRequest(buttonClicked: string) {
      this.$amplitude.logEvent('Offering (Sunrise)', {
        Action: 'Create Request - via ' + buttonClicked,
        Offering: this.offering?.name,
        Supplier: this.offering?.supplier.name
      })
      clientStateStore.functionalityBlocked
        ? clientStateStore.hideShowModal(true)
        : this.$router.push({ name: 'request', query: { offering: this.offeringId } })
    },

    async handleSaveForLater() {
      this.$amplitude.logEvent('Offering (Sunrise)', {
        Action: 'Favourited',
        Offering: this.offering?.name,
        Supplier: this.offering?.supplier.name
      })
      this.showFavoriteModal = !this.showFavoriteModal
      const favorite = await this.$billie.favorites.create(this.userState.user.id, this.offeringId)
      this.favorites = [...this.favorites, favorite]
    },

    navigateToSupplier() {
      this.$amplitude.logEvent('Offering (Sunrise)', {
        Action: 'Go To Supplier',
        Offering: this.offering?.name,
        Supplier: this.offering?.supplier.name
      })
      this.$router.push('/supplier/' + this.offering?.supplier.id)
    }
  },

  async mounted() {
    const offering = await this.$billie.offerings.get(this.offeringId)
    this.offering = augmentOffering(offering, this.$locale)
    this.favorites = await this.$billie.favorites.list(this.userState.user.id)

    this.$amplitude.logEvent('Marketplace - Supplier Offering', {
      Offering: this.offering.name,
      Supplier: this.offering.supplier.name
    })
  }
})

export default Offering
