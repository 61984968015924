

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import LabelField from '@/components/legacy_components/LabelField.vue'
// @ts-ignore
import Switches from 'vue-switches'

@Component({
  components: { LabelSmall, LabelField, Switches }
})
export default class SwitchInput extends Vue {
  @Prop({ default: '' }) label: string
  @Prop({ default: '' }) description: string
  @Prop({ default: false }) value: boolean
  @Prop({ default: false }) minimal: boolean

  get enabled() {
    return this.value
  }

  set enabled(value: boolean) {
    this.$emit('input', value)
  }

  handleClick() {
    this.enabled = !this.enabled
  }
}
