const DANISH = {
  dayNames: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
  monthNames: [
    'januar',
    'februar',
    'marts',
    'april',
    'maj',
    'juni',
    'july',
    'august',
    'september',
    'oktober',
    'november',
    'december',
  ],
  tokens: {
    'one': 'en',
    'two': 'to',
    'three': 'tre',
    'every': 'hver',
    'day': 'dag',
    'days': 'dage',
    'weekday': 'ugedag',
    'weekdays': 'ugedage',
    'week': 'uge',
    'weeks': 'uger',
    'hour': 'time',
    'hours': 'timer',
    'minute': 'minut',
    'minutes': 'minutter',
    'month': 'måned',
    'months': 'måneder',
    'year': 'år',
    'years': 'år',
    'on': 'på',
    'in': 'i',
    'at': 'på',
    'the': 'den',
    'on the': 'på den',
    'first': 'første',
    'second': 'anden',
    'third': 'tredje',
    'last': 'sidste',
    'for': 'for',
    'time': 'gang',
    'times': 'gange',
    'until': 'indtil',
    'and': 'og',
    'or': 'eller'
  }
}

export default DANISH
