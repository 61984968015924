




































import { Vue, Component, Prop } from 'vue-property-decorator'
import ImagePreviewModal from '@/components/core/modal/ImagePreviewModal.vue'
import Emoji from '@/components/core/Emoji.vue'
import { IFileResponseData } from '@/shared/components/interfaces/fileHandler.interface'

@Component({
  components: { ImagePreviewModal, Emoji }
})
export default class AttachmentItem extends Vue {
  @Prop({ required: true }) src: string
  @Prop({ required: false }) backgroundColor: string
  @Prop({ default: false }) isEditMode: boolean
  @Prop() index: IFileResponseData

  showModal = false

  openModal() {
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }

  removeFile(index: IFileResponseData) {
    this.$emit('removeFile', index)
  }

  get fileToken() {
    return localStorage.getItem('file_token')
  }

  get fileExtension() {
    return this.src.split('.')[this.src.split('.').length - 1].toUpperCase()
  }

  get isImage() {
    return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic'].includes(this.fileExtension.toLowerCase())
  }
}
