import axios from './interceptor'
import { IServiceResponse } from '../api.interface'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'

const userState = getModule(Auth)
class ServicesApi {
  static async getServices(): Promise<IServiceResponse[]> {
    return axios.get(`/api/area/${userState.user.serviceArea}/services`).then((res) => res.data)
  }

  static async getServiceType(locale: string, country: string, slug: string) {
    const localeLang = country === 'UK' ? 'en' : locale.split('-')[0]
    return await import(`./servicePageContentJSON/${localeLang}-${country}/${slug}`).then((module) => module.default)
  }

  // TODO: Get Service / Service Type content from API
  //   static async getServiceType(slug: string): Promise<IServicePageResponse> {
  //     return axios.get(`/api/area/${userState.user.serviceArea}/services/${slug}`).then((res) => res.data)
  //   }
}
export default ServicesApi
