


































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ChatModule from '@/components/chat/ChatModule.vue'
import Heading from '@/components/core/Heading.vue'
import IconButton from '@/components/core/IconButton.vue'

@Component({
  components: { Heading, IconButton, ChatModule }
})
export default class ChatPageTemplate extends Vue {
  @Prop({ required: true }) chatId: string
  @Prop({ required: false, default: false }) sendingMessageDisabled: boolean
  showMessages = false

  toggleMessages() {
    this.showMessages = !this.showMessages
  }
}
