














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import DropdownRow from './DropdownRow.vue'
import { ISearchResult } from '../../shared/components/interfaces/search.interface'

@Component({
  components: { DropdownRow }
})
export default class SearchNothingFound extends Vue {
  @Prop() searching: boolean
  @Prop() searchResults: ISearchResult
  @Prop() searchValue: string
}
