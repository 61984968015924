






import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class LabelSmallWithIcon extends Vue {
  @Prop({ default: '' }) text: string
}
