export default class NavigationHelper {
  static isActive(routeName: string, link: string) {
    switch (link) {
      case 'contacts':
        return ['contacts', 'supplier.contactDetails'].includes(routeName)
      case 'proposals':
        return ['proposals', 'proposals.page', 'requests'].includes(routeName)
      case 'subscriptions':
        return ['subscriptions', 'subscriptions.category', 'subscriptions.page'].includes(routeName)
      case 'profile':
        return ['clientProfile', 'clientPreferences', 'clientAccount', 'clientUsers'].includes(routeName)
      case 'orders':
        return ['orders', 'orders.page'].includes(routeName)
      case 'explore':
        return [
          'explore',
          'service.page',
          'category',
          'collections.page',
          'supplier.supplierId',
          'supplier.service',
          'supplier.offering',
        ].includes(routeName)
      default:
        return routeName.endsWith(link)
    }
  }
}
