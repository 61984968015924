





























import Vue from 'vue'
import Component from 'vue-class-component'
import NavigationHelper from '@/services/helpers/navigationHelper'
import { IMenuItem } from '@/shared/components/interfaces/menuItems'
import { Prop } from 'vue-property-decorator'
import RouterStore from '@/store/modules/routerStore'
import { getModule } from 'vuex-module-decorators'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'
const routerState = getModule(RouterStore)

@Component({
  components: { Tooltip }
})
export default class MenuItem extends Vue {
  @Prop({ required: true }) menuItem!: IMenuItem
  @Prop({ required: true }) routeName!: string
  navigationHelper = NavigationHelper
  routerState = routerState

  get isActive() {
    if (this.menuItem.link === this.routerState.currentAgreementRoute && this.$route.name!.includes('agreements')) {
      return true
    } else {
      return this.navigationHelper.isActive(this.routeName, this.menuItem.link)
    }
  }

  get getItemLabel() {
    return this.$t(`navigation.${this.menuItem.key}`)
  }
}
