import _Vue from 'vue'
import axios from '@/shared/services/api/interceptor'

interface HttpOptions {
    baseURL: string
    baseSocketURL: string,
    token: string
}

export default function Http(Vue: typeof _Vue, options: HttpOptions): void {
    axios.interceptors.request.use(
        function(config) {
            const toExpant = config.params ?? {}
            config.params = { ...toExpant, lang: Vue.prototype.$locale }
            return config
        },
        function(error) {
            return Promise.reject(error)
        }
    )

    Vue.prototype.$baseURL = options.baseURL
    Vue.prototype.$baseSocketURL = options.baseSocketURL

    Vue.prototype.$axios = axios
    Vue.prototype.$axios.defaults.baseURL = options.baseURL
    Vue.prototype.$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    if (Vue.prototype.$token) {
        Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + options.token
    }
}