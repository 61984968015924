














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Tooltip extends Vue {
  @Prop({ default: 'mouseenter focus' }) trigger: string
  @Prop() interactive: boolean
  @Prop() distance: number | string
  @Prop({ default: 'bottom' }) placement: string
  @Prop({ default: 'gm-dark' }) theme: string

  get popperOptions() {
    return {}
  }
}
