
















import { Component, Prop, Vue } from 'vue-property-decorator'
import SectionTitle from '@/components/legacy_components/SectionTitle.vue'
import { ISupplier } from '@/shared/components/interfaces/agreements.interface'
import SupplierCategoryCard from './SupplierCategoryCard.vue'

@Component({
  components: { SectionTitle, SupplierCategoryCard }
})
export default class CategorySuppliers extends Vue {
  @Prop({ required: true }) suppliers!: ISupplier[]
}
