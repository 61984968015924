












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Button from '@/components/core/Button.vue'
import ShowText from './ShowText.vue'
import { ISuppliersService } from '@/shared/components/interfaces/supplierData.interface'

@Component({
  components: {
    Button,
    ShowText
  }
})
export default class GetQuote extends Vue {
  @Prop() serviceData: ISuppliersService | null
  @Prop() buttonText: string
  @Prop() descriptionText: string
  @Prop() navigationLink: string
  @Prop() serviceName: string
  @Prop() companyName: string

  requestProposal() {
    this.$amplitude.logEvent('Marketplace - Request Offering', {
      Supplier: this.companyName,
      Service: this.serviceName
    })

    this.$router.push({ name: 'request', query: { offering: this.serviceData?.id } })
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
}
