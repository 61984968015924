












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class CheckboxGroup extends Vue {
  @Prop({ default: [] }) items: string[]
  @Prop({ default: false }) multiple: boolean
  @Prop() translationPath: string | null
  @Prop() initial: string | null
  state: any[] = []

  handleClick(e: Event) {
    const value = (e.target as HTMLElement).getAttribute('data-value')
    if (this.state.includes(value)) {
      this.state.splice(this.state.indexOf(value), 1)
    } else {
      this.multiple ? this.state.push(value) : (this.state = [value])
    }
    this.emitResult(this.state)
  }

  emitResult(result: any[]) {
    this.$emit('checkbox', result)
  }

  created() {
    if (this.initial) {
      this.state = this.initial.split(',')
    }
  }
}
