
















import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Auth from '@/shared/storeModules/auth'
import { screenResizeHandler } from './shared/services/Functions'
import moment from 'moment'
import 'moment/locale/da'
import { getModule } from 'vuex-module-decorators'
import AppKey from '@/shared/storeModules/appKey'
import OverlayStore from '@/store/modules/overlay'
import { delightedSurvey } from './services/delightedSurvey'
import ChatStore from '@/store/modules/chatStore'
import VueNotifications from '@/components/VueNotifications.vue'
import Modal from '@/components/core/modal/Modal.vue'
import BlockUserModal from './components/users/BlockUserModal.vue'
import ClientStateStore from './store/modules/clientStateStore'
import LockedClientModal from '@/components/LockedClientModal.vue'

const userState = getModule(Auth)
const appState = getModule(AppKey)
const chatState = getModule(ChatStore)
const overlayState = getModule(OverlayStore)
const clientStateStore = getModule(ClientStateStore)

@Component({
  components: { VueNotifications, Modal, BlockUserModal, LockedClientModal }
})
export default class App extends Vue {
  screenResizeHandler = screenResizeHandler
  delightedSurvey = delightedSurvey
  isMobileDevice: boolean = document.body.clientWidth <= 768
  userState = userState
  clientStateStore = clientStateStore
  user = userState.user
  appState = appState
  overlayState = overlayState
  $logger: { error: (s1: string, s2: any, s3: any) => void }
  //@ts-ignore
  debaunche: Timeout | null = null
  isTerminated = false
  get appKey() {
    return appState.appKeyValue.value
  }

  @Watch('clientStateStore.notificationLvlOriginal', { deep: true })
  onFunctionalityBlockedChange(_newVal: number | null, oldVal: number | null | undefined) {
    if (clientStateStore.functionalityBlocked && oldVal !== undefined) {
      clientStateStore.hideShowModal(true)
    }
  }

  @Watch('user', { deep: true })
  onUserChanged() {
    if (process.env.NODE_ENV === 'production') {
      if (this.user.client) {
        this.delightedSurvey('GEWc8uCPhPIwChXz', userState.user)
      }
    }
    this.isTerminated = this.userState?.user?.client?.status === 'terminated'
    this.$amplitude.setUserId(this.user.clientId as string)
    this.$amplitude.identify(
      new this.$amplitude.Identify().setOnce('Service Area', userState.user.serviceArea as string)
    )

    if (this.user.shouldConsent === 'true') {
      overlayState.setShowUpdatedTerms(true)
    }
  }

  async created() {
    window.addEventListener('resize', this.screenResizeHandler.bind(this))
    const params = new URLSearchParams(window.location.search)
    window.addEventListener('focus', () => {
      chatState.setTabFocused(true)
    })
    window.addEventListener('blur', () => {
      chatState.setTabFocused(false)
    })
    if (params.get('openConcierge') === 'true') {
      this.overlayState.setShowOverlayConcierge(true)
    }

    moment.locale('en')
    if (['da', 'da-DK'].includes(this.$locale)) {
      moment.updateLocale('da', {
        months: 'Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_Maj_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
        weekdays: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag'.split('_'),
        weekdaysShort: 'Søn_Man_Tir_Ons_Tor_Fre_Lør'.split('_'),
        weekdaysMin: 'Sø_Ma_Ti_On_To_Fr_Lø'.split('_'),
        week: {
          dow: 1,
          doy: 4
        }
      })
      moment.locale('da')
    } else {
      moment.locale('en')
    }

    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      this.$logger.error('unhandled promise rejection: ', event, event.reason)
      event.preventDefault()
    }

    window.addEventListener('resize', () => {
      clearTimeout(this.debaunche)
      this.debaunche = setTimeout(() => {
        this.setVH()
      }, 50)
    })
    this.setVH()
  }

  onClose() {
    clientStateStore.hideShowModal(false)
  }

  setVH() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  destroyed() {
    window.removeEventListener('resize', this.screenResizeHandler.bind(this))
  }
}
