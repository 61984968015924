






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import { getModule } from 'vuex-module-decorators'
import OverlayStore from '@/store/modules/overlay'
import Auth from '@/shared/storeModules/auth'
import ClientsApi from '@/shared/services/api/ClientsApi'
const overlayState = getModule(OverlayStore)
const userState = getModule(Auth)

@Component({ components: { ButtonPrimary } })
export default class UpdatedTermsPopUp extends Vue {
  @Prop({ required: true }) messageMode: boolean
  overlayState = overlayState
  userState = userState

  accept() {
    ClientsApi.acceptTerms(userState.user.clientId as string)
      .then(() => {
        this.userState.setTermsAccepted()
        overlayState.setShowUpdatedTerms(false)
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }
}
