





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SupplierCategoryCard extends Vue {
  @Prop({ required: true }) supplier!: { [key: string]: string }

  goToSupplier() {
    this.$router.push(`/supplier/${this.supplier.id}`)
  }
}
