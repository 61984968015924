


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import InlineSvg from 'vue-inline-svg'
import ServicesApi from '@/shared/services/api/servicesApi'
import { IServicePageResponse } from '../../shared/services/api.interface'
import StoryblockApi from '@/services/api/storyblockApi'
import { IStoryblokPayload } from '@/shared/components/interfaces/storyblok.interface'
import ServiceCollections from '@/components/explore/ServiceCategory.vue'
import Grid from '@/components/layout/Grid.vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'
import Label from '@/components/core/Label.vue'
import FeatureHeroBanner from '@/components/explore/FeatureHeroBanner.vue'
import Flex from '@/components/layout/Flex.vue'
import FloatingCta from '@/components/core/FloatingCta.vue'
import ClientStateStore from '@/store/modules/clientStateStore'

const userState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

@Component({
  components: {
    Grid,
    Flex,
    Space,
    Heading,
    Label,
    InlineSvg,
    ServiceCollections,
    FeatureHeroBanner,
    FloatingCta
  }
})
export default class Explore extends Vue {
  userState = userState
  clientStateStore = clientStateStore
  scrollTrigger: any
  loading = true
  country: string
  story: IStoryblokPayload | null = null
  serviceTypes: IServicePageResponse[] = []
  services = [
    'coffee',
    'cleaning',
    'handyman',
    'consumables',
    'virtual_events',
    'drinks',
    'lunch',
    'wellness',
    'it_support',
    'fruit_snacks',
    'plants',
    'gifts',
    'assistant',
    'events'
  ]

  getServices = async () => {
    this.country = this.userState.user.serviceArea === 'CPH' ? 'DK' : 'UK'
    if (this.country === 'UK') {
      this.services = this.services.filter(service => service !== 'office_assistant' && service !== 'consumables')
    } else if (this.country === 'DK') {
      this.services = this.services.filter(service => service !== 'virtual_events')
    }
    return Promise.all(this.services.map(service => ServicesApi.getServiceType(this.$locale, this.country, service)))
  }

  get storyUrl() {
    switch (this.$locale) {
      case 'da':
      case 'da-DK':
        return 'da/explore/service-categories'
      case 'en':
      case 'en-US':
      case 'en-GB':
        return 'en/explore/service-categories'
      default:
        return 'explore/service-categories'
    }
  }

  goToRequest() {
    this.$amplitude.logEvent('Explore: Go To Request (Sunrise)')
    clientStateStore.functionalityBlocked
      ? clientStateStore.hideShowModal(true)
      : this.$router.push({ name: 'request' })
  }

  async mounted() {
    StoryblockApi.initializeStoryBlok(this.storyUrl, story => {
      this.story = story
    })

    this.getServices().then(data => {
      this.serviceTypes = data
    })

    this.loading = false
  }
}
