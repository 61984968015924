






























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { IClientAccount } from '@/shared/components/interfaces/clients.interface'
import { IMessageDropdownSections, IDropdownMessage } from '@/shared/components/interfaces/messages.interface'
import { Channel, Socket } from 'phoenix'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import ChatStore from '@/store/modules/chatStore'
import DropdownRow from '@/components/dropdowns/DropdownRow.vue'
import DropdownRowTitle from '@/components/dropdowns/DropdownRowTitle.vue'
import moment from 'moment'
import Label from '@/components/core/Label.vue'
import Space from '@/components/layout/Space.vue'
import Notification from '@/components/Notification.vue'
import Button from '@/components/core/Button.vue'
import ClientDetailsModel from '@/shared/models/ClientDetailsModel'

const userState = getModule(Auth)
const chatState = getModule(ChatStore)

@Component({
  components: { Space, Label, DropdownRow, DropdownRowTitle, Notification, Button }
})
export default class Inbox extends Vue {
  tooltip = false
  channel: Channel
  socket: Socket | null = null
  userState = userState
  chatState = chatState
  arrayOfMessages: IDropdownMessage[] = []
  unreadMessages: IMessageDropdownSections = {
    title: 'unreadMessages',
    dropdownItems: []
  }
  supportMessages: IMessageDropdownSections = {
    title: 'generalSupport',
    dropdownItems: []
  }
  olderMessages: IMessageDropdownSections = {
    title: 'olderConversations',
    dropdownItems: []
  }
  isSocketConnected = false
  user = userState.user
  client: IClientAccount | null = null

  orderByTime(messages: IDropdownMessage[]) {
    messages.sort((a, b) => {
      if (!a.lastMessage) {
        return 1
      } else if (!b.lastMessage) {
        return -1
      }
      return (new Date(b.lastMessage.createdAt) as any) - (new Date(a.lastMessage.createdAt) as any)
    })
  }

  get allCombinedMessages() {
    this.supportMessages.dropdownItems = []
    this.unreadMessages.dropdownItems = []
    this.olderMessages.dropdownItems = []
    this.orderByTime(this.arrayOfMessages)
    for (let message of this.arrayOfMessages) {
      if (message.subjectType === 'client') {
        this.supportMessages.dropdownItems.push(message)
      } else if (message.numberUnread > 0) {
        this.unreadMessages.dropdownItems.push(message)
      } else if (message.lastMessage) {
        this.olderMessages.dropdownItems.push(message)
      }
    }
    const arr = []
    arr.push(this.unreadMessages, this.supportMessages, this.olderMessages)

    return arr
  }

  @Watch('arrayOfMessages')
  onMessageUpdate(messages: IDropdownMessage[]) {
    let unread = 0
    if (messages) {
      for (let message of messages) {
        unread += message.numberUnread
      }
    }
    this.$emit('unread', unread)
    this.chatState.setUnreadMessages(this.unreadMessages.dropdownItems.map(message => message.subjectId))
  }

  @Watch('user', { deep: true })
  async onUserChangeddd() {
    if (this.user.client && !this.channel) {
      this.handleSocketConnection()
      this.joinChannel()
      this.handleInboundChannelMessages()
      await this.getClientData()
    }
  }

  async getClientData() {
    this.client = await this.$billie.clients.get(this.userState.clientId)
    return this.client
  }

  get overdueNotificationLevel(): number | null {
    return ClientDetailsModel.mostRecentNotification(this.client!)
  }

  async mounted() {
    await this.getClientData()
    this.handleSocketConnection()
    this.joinChannel()
    this.handleInboundChannelMessages()
  }

  beforeDestroy() {
    if (this.channel) this.channel.leave()
    if (this.socket) this.socket.disconnect()
  }

  handleSocketConnection() {
    this.socket = new Socket(this.$baseSocketURL, { params: { token: this.$token } })
    this.socket.connect()
    this.channel = this.socket.channel(`inbox:${this.userState.id}`, { token: this.$token })
  }

  joinChannel() {
    this.channel.join()
  }

  handleInboundChannelMessages() {
    this.channel.on('inbox:list', (payload: any) => {
      this.arrayOfMessages = payload.inbox
      this.arrayOfMessages = payload.inbox.filter((msg: IDropdownMessage) => msg.subjectType !== 'service_request')
    })
    this.channel.onError((_reason: string) => {
      this.isSocketConnected = this.socket!.isConnected()
    })
  }

  messageClick(notification: IDropdownMessage) {
    this.trackClickEvent(notification)
    if (
      notification.subjectType === 'proposal' ||
      notification.subjectType === 'request' ||
      notification.subjectType === 'service_request'
    ) {
      if (this.$router.currentRoute.path !== `/${notification.subjectType}s/${notification.subjectId}`) {
        const name = notification.subjectType === 'proposal' ? 'proposals.page' : 'request'
        this.$router.push({ name: name, params: { id: notification.subjectId } })
      }
    }
    if (notification.subjectType === 'client' && this.$router.currentRoute.path !== `/concierge`) {
      this.$router.push({ name: 'concierge' })
    }
    if (
      (notification.subjectType === 'agreement' || notification.subjectType === 'subscription') &&
      this.$router.currentRoute.path !== `/agreements/${notification.subjectId}`
    ) {
      this.$router.push({ name: 'agreements', params: { id: notification.subjectId } })
    }
  }

  timeDiff(date: string) {
    if (moment(date).year() === moment().year()) {
      return moment(date).format('MMM D')
    } else {
      return moment(date).format('MMM D, YYYY')
    }
  }

  trackClickEvent(notification: any) {
    this.$amplitude.logEvent('Your Office - Sidebar - Message', {
      title: notification.title,
      type: notification.subjectType,
      hasNotifications: notification.numberUnread > 0
    })
  }
}
