























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICarouselItem, ICarousels } from '@/shared/components//interfaces/storyblok.interface'
import SupplierApi from '@/shared/services/api/supplierApi'
import { ISuppliersService } from '@/shared/components/interfaces/supplierData.interface'
import SectionTitle from '@/components/legacy_components/SectionTitle.vue'
import ContentPlaceholder from '@/components/ContentPlaceholder.vue'
import CarouselSection from './CarouselSection.vue'
import Space from '@/components/layout/Space.vue'

@Component({
  components: { CarouselSection, Space, SectionTitle, ContentPlaceholder }
})
export default class ServiceCollections extends Vue {
  @Prop() carousels!: ICarousels[]
  @Prop() url!: 'dashboard' | 'explore'
  carouselData: ICarouselItem[] = []
  offerings: ISuppliersService[] = []

  async mounted() {
    this.offerings = await SupplierApi.getOfferings()
    if (this.carousels) {
      this.carousels.forEach((carousel: ICarousels) => {
        const item: ICarouselItem = {
          carousel_tag: carousel.carousel_tag,
          carousel_name: carousel.carousel_name,
          carousel_style: carousel.carousel_style
        }
        item.items = this.offerings.filter(offering => offering.tags && offering.tags.includes(carousel.carousel_tag))
        this.carouselData.push(item)
      })
    }
  }
}
