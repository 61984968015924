import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'RouterStore',
  dynamic: true,
  store: store,
})
export default class RouterStore extends VuexModule {
  agreementType: 'orders' | 'subscriptions' | null = null

  get currentAgreementRoute() {
    return this.agreementType
  }

  @Mutation
  public setAgreementRoute(payload: 'orders' | 'subscriptions' | null) {
    this.agreementType = payload
  }

  @Action({ commit: 'setAgreementRoute' })
  public async setRoute(agreementType: 'orders' | 'subscriptions' | null) {
    return agreementType
  }
}
