










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class NavigationLink extends Vue {
  @Prop() showOnDesktop: boolean
  @Prop() linkText: string
  @Prop() linkTo: string
  @Prop({ default: true }) primary: boolean
  @Prop() linkToFn: () => void

  navigate() {
    if (typeof this.linkToFn === 'function') {
      this.linkToFn()
      return
    }
    this.$router.push(this.linkTo)
  }
}
