











































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import SupplierApi from '@/shared/services/api/supplierApi'
import { ISuppliersService, ISupplierData } from '@/shared/components/interfaces/supplierData.interface'
import { IServiceResponse } from '@/shared/services/api.interface'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import LeadText from '@/components/legacy_components/LeadText.vue'
import SupplierProfilePicture from '@/components/legacy_components/SupplierProfilePicture.vue'
import CardElement from '@/components/explore/CardElement.vue'
import GetQuote from '@/components/suppliers/GetQuote.vue'
import ShowText from '@/components/suppliers/ShowText.vue'
import SupplierAboutSection from '@/components/suppliers/SupplierAboutSection.vue'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'
import Grid from '@/components/layout/Grid.vue'
import FloatingCta from '@/components/core/FloatingCta.vue'
import { getModule } from 'vuex-module-decorators'
import ClientStateStore from '@/store/modules/clientStateStore'

const clientStateStore = getModule(ClientStateStore)

@Component({
  components: {
    Grid,
    Space,
    Heading,
    CardElement,
    GetQuote,
    LabelNormal,
    LeadText,
    ShowText,
    SupplierAboutSection,
    SupplierProfilePicture,
    FloatingCta
  }
})
export default class SupplierService extends Vue {
  clientStateStore = clientStateStore
  serviceData: ISuppliersService | IServiceResponse | null = null
  companyData: ISupplierData | null = null
  offeringResponseData: [] = []
  dummyData: string[] = ['Flatpack assembly', 'Mount Stuff', 'Foo bar', 'Custom requests', 'Odd jobs']
  dummyHighlights = [
    { header: 'Flatpack assembly', text: '' },
    { header: 'Flatpack assembly', text: '' },
    { header: 'Flatpack assembly', text: '' },
    { header: 'Flatpack assembly', text: '' }
  ]

  @Watch('$route')
  onPropertyChanged() {
    this.loadData()
  }

  async mounted() {
    this.loadData()
  }

  async loadData() {
    this.serviceData = await SupplierApi.getSupplierServiceData(this.$route.params.serviceId)
    this.companyData = await SupplierApi.getSupplierData(this.$route.params.supplierId)
    this.$amplitude.logEvent('Marketplace - Supplier Offering', {
      Supplier: this.companyData?.name,
      Service: this.serviceData?.name
    })
    this.$forceUpdate()
  }
  get filteredSpecialties() {
    return this.companyData!.offerings.filter(service => {
      return this.$route.params.serviceId !== service.id
    })
  }

  navigateToSupplier() {
    this.$router.push('/supplier/' + this.$route.params.supplierId)
  }
}
