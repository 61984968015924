import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'SearchStore',
  dynamic: true,
  store: store,
})
export default class SearchStore extends VuexModule {
  totalIndexesData = 0
  activeIndexData = 0

  get totalIndexes() {
    return this.totalIndexesData
  }

  get lastIndex() {
    return this.totalIndexesData
  }

  get activeIndex() {
    return this.activeIndexData
  }

  @Mutation
  public setTotalIndexesMutation(payload: number) {
    this.totalIndexesData = payload
  }

  @Action({ commit: 'setTotalIndexesMutation' })
  public setTotalIndexes(total: number) {
    return total
  }

  @Mutation
  public setActiveIndexMutation(payload: number) {
    this.activeIndexData = payload
  }

  @Action({ commit: 'setActiveIndexMutation' })
  public setActiveIndex(activeIndex: number) {
    return activeIndex
  }
}
