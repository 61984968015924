


















































































































































import Vue from 'vue'
import Auth from '@/shared/storeModules/auth'
import ClientStateStore from '@/store/modules/clientStateStore'
import Heading from '@/components/core/Heading.vue'
import Space from '@/components/layout/Space.vue'
import Button from '@/components/core/Button.vue'
import Flex from '@/components/layout/Flex.vue'
import Label from '@/components/core/Label.vue'
import FloatingCta from '@/components/core/FloatingCta.vue'
import { getModule } from 'vuex-module-decorators'
import ImagePreviewModal from '@/components/core/modal/ImagePreviewModal.vue'
import IconButton from '@/components/core/IconButton.vue'
import { IFavorite } from '@/shared/billie/api/favorites'
import routerGoBack from '@/mixins/routerGoBack'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import { SunriseOfferingProperties, getPromo } from '@/pages/explore/sunrise/sunriseData'

type Nullable<T> = T | null

const userState = getModule(Auth)
const clientStateStore = getModule(ClientStateStore)

const Promo = Vue.extend({
  components: {
    Flex,
    Space,
    Heading,
    Button,
    FloatingCta,
    Label,
    ImagePreviewModal,
    IconButton,
    Modal,
    ModalHeader
  },
  mixins: [routerGoBack],

  data() {
    return {
      offering: null as Nullable<SunriseOfferingProperties>,
      favorites: [] as IFavorite[],
      supplierInfoOpened: false,
      showImageModal: false,
      currentImagePreviewIndex: null as Nullable<number> | null,
      showFavoriteModal: false
    }
  },

  computed: {
    clientStateStore: () => clientStateStore,
    userState: () => userState,
    //   supplierId: function (): string {
    //     return this.$route.meta!.supplierId
    //   },
    offeringId: function (): string {
      return this.$route.meta!.offeringId
    },
    isFavorited: function (): boolean {
      return this.favorites.filter(item => item.offeringId === this.offeringId).length > 0
    }
  },

  methods: {
    toggleImageModal(index: number) {
      this.currentImagePreviewIndex = index
      this.showImageModal = !this.showImageModal
      if (this.showImageModal) {
        this.$amplitude.logEvent('Promo: Gallery Image Opened (Sunrise)', {
          Supplier: this.offeringId
        })
      }
    },

    goToRequest(buttonClicked: string) {
      this.$amplitude.logEvent('Promo (Sunrise)', {
        Action: 'Create Request - via ' + buttonClicked,
        Offering: this.offeringId
      })
      clientStateStore.functionalityBlocked
        ? clientStateStore.hideShowModal(true)
        : this.$router.push({ name: 'request', query: { offering: this.offeringId } })
    }

    //   navigateToSupplier() {
    //     // this.$amplitude.logEvent('Offering (Sunrise)', {
    //     //   Action: 'Go To Supplier',
    //     //   Offering: this.offering?.name,
    //     //   Supplier: this.offering?.supplier.name
    //     // })
    //     this.$router.push('/supplier/' + this.offering?.supplier.id)
    //   }
  },

  async mounted() {
    //console.log(this.userState.user.client?.countryCode)
    //const offering = await this.$billie.offerings.get(this.offeringId)
    //this.offering = augmentOffering(offering, this.$locale)
    this.offering = getPromo(this.userState.user.client!.countryCode!, this.$locale)
    //this.favorites = await this.$billie.favorites.list(this.userState.user.id)

    this.$amplitude.logEvent('Marketplace - Promo', {
      Offering: this.offeringId
    })
  }
})

export default Promo
