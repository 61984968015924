import moment from 'moment'
import DANISH from '../i18n/RRULE-DANISH'
import { RRule } from 'rrule'
import { ICalendarEvent } from '@/shared/billie/api/calendar/events'

/**
 * This is a model working over the ICalendarEvent interface
 *
 * Functions should be pure, static and accept the ICalendarEvent
 * interface as the first parameter.
 *
 * Example:
 * public static from_form(form: ICalendarEvent): ICalendarEvent
 */

export default abstract class CalendarEventModel {
  public static isAllDay(event: ICalendarEvent): boolean {
    return (
      CalendarEventModel.startTime(event).format('HH:mm:ss') === '00:00:00' &&
      CalendarEventModel.endTime(event).format('HH:mm:ss') === '00:00:00'
    )
  }

  public static isTimeMatches(event: ICalendarEvent): boolean {
    return CalendarEventModel.startTime(event).format('HH:mm:ss') === CalendarEventModel.endTime(event).format('HH:mm:ss')
  }

  public static getEventTime(event: ICalendarEvent): string {
    return `${CalendarEventModel.startTime(event).format('HH:mm')}${
      !CalendarEventModel.isTimeMatches(event) ? ' - ' + CalendarEventModel.endTime(event).format('HH:mm') : ''
    }`
  }

  public static startTime(event: ICalendarEvent): moment.Moment {
    const match = event.ical.match(/DTSTART(?:;[a-zA-Z\W]*)*:([0-9TZ]*)/)
    return moment(match![1])
  }

  public static endTime(event: ICalendarEvent): moment.Moment {
    const match = event.ical.match(/DTEND(?:;[a-zA-Z\W]*)*:([0-9TZ]*)/)
    return moment(match![1])

  }

  public static isMultiDayEvent(event: ICalendarEvent): boolean {
    return CalendarEventModel.isAllDay(event)
      ? !CalendarEventModel.startTime(event).isSame(CalendarEventModel.endTime(event).subtract(1, 'day'), 'day')
      : !CalendarEventModel.startTime(event).isSame(CalendarEventModel.endTime(event), 'day')
  }

  // We extract only the Recurrence rule from the ical string,
  // because some properties from the iCalendar (RFC 5545) spec are not supported by the rrule library
  // These unsuppored properties like DTEND and EXDATE prevent the RRule fromString method from working.
  public static humanReadableRecurrence(event: ICalendarEvent, locale: string) {
    if (event.isRecurring) {
      let text = ''

      const regex = /RRULE:.*/
      const rgx = regex.exec(event.ical)
      const rrule = rgx![0]

      switch (locale) {
        case 'da':
        case 'da-DK':
          text = RRule.fromString(rrule).toText((id) => (DANISH as any).tokens[id.toString()], DANISH as any)
          break
        default:
          text = RRule.fromString(rrule).toText()
          break
      }
      return text.charAt(0).toUpperCase() + text.slice(1)
    } else {
      return ''
    }
  }
}
