








































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { ITimeframe } from '@/shared/components/interfaces/request.interface'
import RequestProperty from './RequestProperty.vue'
import TimeframeModal from '@/components/requests/TimeframeModal.vue'
import InputDropdown from '@/components/core/dropdown/InputDropdown.vue'
import Flex from '@/components/layout/Flex.vue'
import Space from '@/components/layout/Space.vue'
import RequestInputTab from '@/components/requests/RequestInputTab.vue'
import ModalStep from '@/components/core/modal/ModalStep.vue'
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Emoji from '@/components/core/Emoji.vue'
import SwitchInput from '@/components/legacy_components/SwitchInput.vue'

@Component({
  components: {
    RequestProperty,
    TimeframeModal,
    InputDropdown,
    RequestInputTab,
    Space,
    Flex,
    ModalStep,
    DatePicker,
    Emoji,
    SwitchInput
  }
})
export default class TimeframeInput extends Vue {
  @Prop({ required: true }) value: ITimeframe | null
  @Prop({ required: false, default: true }) isEditable: boolean
  isShowing = false
  stepIndex = 1
  rangeClick = 0
  selectedDates: any = null
  type: 'date' | 'between' | 'asap' | 'before' | 'after' = 'date'
  startDate: Date | null = null
  endDate: Date | null = null
  time: string | null = null

  setDate(value: ITimeframe | null) {
    this.$emit('input', value)
  }

  get locale() {
    if (['da', 'da-DK'].includes(this.$locale)) {
      return 'da'
    }
    return 'en'
  }

  setStep(index: number) {
    this.stepIndex = index
    this.clear()
    this.startDate = null
    this.endDate = null
    switch (this.stepIndex) {
      case 1:
        this.type = 'date'
        break
      case 2:
        this.type = 'asap'
        this.$emit('input', { type: this.type })
        break
      case 3:
        this.type = 'between'
        break
      case 4:
        this.type = 'before'
        break
      case 5:
        this.type = 'after'
        break
    }
  }

  dateValueChanged(value: any) {
    this.startDate = this.endDate = new Date(value)
    if (this.$mq !== 'mobile') {
      this.$emit('input', {
        type: this.type,
        date: moment(value).format('YYYY-MM-DD'),
        time: this.time
      })
    }
  }

  rangeDateValueChanged(value: any) {
    this.type = 'between'
    this.rangeClick++
    // Because we have to use "dayclick" event handler in the date picker so it is more mobile friendly to select a single date
    // There will be an instance in which the user will select a date and drag the range picker backwards.
    // in which case the start date and end date will be reversed, the following logic is to fix that
    if (this.rangeClick === 2) {
      if (this.startDate && this.startDate > value.date) {
        this.endDate = this.startDate
        this.startDate = value.date
      } else {
        this.endDate = new Date(value.date)
      }
      this.rangeClick = 0
    } else {
      this.startDate = new Date(value.date)
      this.endDate = new Date(value.date)
    }

    if (this.$mq !== 'mobile') {
      this.$emit('input', {
        type: this.type,
        from: moment(this.startDate!).format('YYYY-MM-DD'),
        to: moment(this.endDate!).format('YYYY-MM-DD'),
        time: this.time
      })
    }
  }

  handleTimeChange(e: any) {
    this.time = e.target.value
    if (this.$mq !== 'mobile') {
      this.type === 'between'
        ? this.$emit('input', {
            type: this.type,
            from: moment(this.startDate).format('YYYY-MM-DD'),
            to: moment(this.endDate).format('YYYY-MM-DD'),
            time: e.target.value
          })
        : this.$emit('input', {
            type: this.type,
            date: moment(this.startDate).format('YYYY-MM-DD'),
            time: e.target.value
          })
    }
  }

  formatDate(date: string | Date) {
    return moment(date).format('MMMM Do YYYY')
  }

  displayValue(value = this.value) {
    if (value) {
      switch (value?.type) {
        case 'date':
          return `${this.formatDate(value.date)} ${value.time ? ' - ' + value.time : ''}`
        case 'between':
          if (this.formatDate(value.from) === this.formatDate(value.to))
            return `${this.formatDate(value.from)}  ${value.time ? ' - ' + value.time : ''}`
          return `${this.formatDate(value.from)} - ${this.formatDate(value.to)} ${value.time ? ' - ' + value.time : ''}`
        case 'asap':
          return this.$t('requests.form.properties.timeframe.asapLabel')
        case 'before':
          return `${this.$t('requests.form.properties.timeframe.beforeLabel')} ${this.formatDate(value.date)} ${
            value.time ? ' - ' + value.time : ''
          }`
        case 'after':
          return `${this.$t('requests.form.properties.timeframe.afterLabel')} ${this.formatDate(value.date)} ${
            value.time ? ' - ' + value.time : ''
          }`
        default:
          return ''
      }
    } else {
      return this.$t(`requests.form.properties.timeframe.placeholder`)
    }
  }

  clear() {
    this.$emit('input', null)
    this.selectedDates = null
    this.startDate = null
    this.endDate = null
    this.time = null
    this.rangeClick = 0
  }

  toggleModal() {
    this.isShowing = !this.isShowing
  }

  populateDate(value = this.value) {
    if (value) {
      this.type = value.type
      switch (value.type) {
        case 'date':
          this.stepIndex = 1
          this.startDate = this.endDate = new Date(value.date)
          this.time = value.time
          this.selectedDates = new Date(value.date)
          break
        case 'asap':
          this.stepIndex = 2
          break
        case 'between':
          this.stepIndex = 3
          this.startDate = new Date(value.from)
          this.endDate = new Date(value.to)
          this.time = value.time
          this.selectedDates = {
            start: new Date(value.from),
            end: new Date(value.to)
          }
          break
        case 'before':
          this.stepIndex = 4
          this.startDate = this.endDate = new Date(value.date)
          this.time = value.time
          this.selectedDates = new Date(value.date)
          break
        case 'after':
          this.stepIndex = 5
          this.startDate = this.endDate = new Date(value.date)
          this.time = value.time
          this.selectedDates = new Date(value.date)
          break
      }
    }
  }

  contentStyleOrange = {
    color: 'white',
    backgroundColor: '#ff561c',
    borderRadius: '12px',
    fontWeight: 700,
    width: '36px',
    height: '36px'
  }

  selectAttribute = {
    highlight: {
      color: '',
      contentStyle: this.contentStyleOrange
    }
  }

  selectRangeAttribute = {
    highlight: {
      start: {
        color: '',
        contentStyle: this.contentStyleOrange
      },
      base: {
        style: {
          color: 'white',
          backgroundColor: '#ff561c',
          opacity: 0.2,
          fontWeight: 700,
          height: '32px'
        }
      },
      end: {
        color: '',
        contentStyle: this.contentStyleOrange
      }
    }
  }

  @Watch('value')
  onChange(value: ITimeframe | null) {
    this.populateDate(value)
  }

  async created() {
    await this.populateDate()
  }
}
