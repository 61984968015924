


































import { Component, Prop, Vue } from 'vue-property-decorator'
import smoothscroll from 'smoothscroll-polyfill'
import { IService } from '@/shared/components//interfaces/storyblok.interface'
import ArrowButton from '@/components/legacy_components/ArrowButton.vue'
import CardElement from './CardElement.vue'
import Heading from '@/components/core/Heading.vue'
import Flex from '@/components/layout/Flex.vue'
import Space from '@/components/layout/Space.vue'

@Component({
  components: { Flex, Space, Heading, CardElement, ArrowButton }
})
export default class CarouselSection extends Vue {
  @Prop() carouselItems!: IService[]
  @Prop() url: 'dashboard' | 'explore'
  @Prop() carouselName!: string
  @Prop() carouselTag!: string
  @Prop() title?: string
  rand = Math.random()

  get carouselTitle() {
    return this.$mq === 'mobile' || this.$mq === 'tablet'
      ? `${this.carouselName} (${this.carouselItems.length})`
      : this.carouselName
  }

  mounted() {
    smoothscroll.polyfill()
  }

  goToCollection() {
    this.$router.push({
      path: `/${this.url}/collection/${this.carouselTag}`
    })
  }
  scroll(direction: 'left' | 'right') {
    const scrollDiv = document.getElementById('section' + this.rand)
    const cards = document.getElementsByClassName('card-container')
    const cardWidth = (cards[0] as any).offsetWidth
    const scrollAmount = direction === 'left' ? scrollDiv!.scrollLeft - cardWidth : scrollDiv!.scrollLeft + cardWidth
    scrollDiv!.scrollTo({
      left: scrollAmount,
      behavior: 'smooth'
    })
  }
}
