import { render, staticRenderFns } from "./TaskCard.vue?vue&type=template&id=541fdb51&scoped=true&"
import script from "./TaskCard.vue?vue&type=script&lang=ts&"
export * from "./TaskCard.vue?vue&type=script&lang=ts&"
import style0 from "./TaskCard.vue?vue&type=style&index=0&id=541fdb51&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541fdb51",
  null
  
)

export default component.exports