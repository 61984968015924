





















import { Component, Prop, Vue } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'
import { IServicePageOption } from '@/shared/services/api.interface'
import Heading from '@/components/core/Heading.vue'

@Component({
  components: { Heading, InlineSvg }
})
export default class OptionsSection extends Vue {
  @Prop({ required: true }) sectionData: IServicePageOption
}
