

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import { MenuCategory } from '@/shared/components/interfaces/menuItems'
import MenuItem from '@/components/MenuItem.vue'
import Avatar from '@/components/core/Avatar.vue'
import NavigationHelper from '@/services/helpers/navigationHelper'

const authState = getModule(Auth)

@Component({
  components: { MenuItem, Avatar }
})
export default class LayoutFooterNav extends Vue {
  @Prop() toggleSearch: void
  @Prop({ required: true }) menuItems!: MenuCategory[]
  @Prop({ required: true }) routeName!: string
  navigationHelper = NavigationHelper

  get user() {
    return authState.user
  }

  get isActive() {
    return this.navigationHelper.isActive(this.routeName, 'profile')
  }

  clickSearch() {
    this.$emit('toggleSearch', true)
  }
}
