



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore
import ClickOutside from 'vue-click-outside'
import DropdownContentBox from './DropdownContentBox.vue'
import DropdownRow from './DropdownRow.vue'
import { IDropdownMenu } from '@/shared/components/interfaces/dropdownMenu.interface'
@Component({
  components: { DropdownContentBox, DropdownRow },
  directives: { ClickOutside }
})
export default class DropdownMenu extends Vue {
  @Prop({ required: true }) menuItems: IDropdownMenu[]
  @Prop({ required: true }) data: any
  @Prop() menuItemClickedCallback: (item: IDropdownMenu, user: any) => void
  showMenu = false

  toggleMenu() {
    this.showMenu = !this.showMenu
  }

  menuItemClicked(item: IDropdownMenu) {
    this.menuItemClickedCallback(item, this.data)
    this.showMenu = false
  }

  closeDropdownMenu() {
    this.showMenu = false
  }
}
