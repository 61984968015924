import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'Overlay',
  dynamic: true,
  store: store,
})
export default class Overlay extends VuexModule {
  showOverlayData = false
  showConciergeOverlayData = false
  showUpdatedTermsData = false

  get showOverlay() {
    return this.showOverlayData
  }

  get showConciergeOverlay() {
    return this.showConciergeOverlayData
  }

  get showUpdateTermsBanner() {
    return this.showUpdatedTermsData
  }

  @Mutation
  public setShowUpdatedTermsMutation(show: boolean) {
    this.showUpdatedTermsData = show
  }

  @Action({ commit: 'setShowUpdatedTermsMutation' })
  public async setShowUpdatedTerms(show: boolean) {
    return show
  }

  @Mutation
  public setShowOverlayMutation(show: boolean) {
    this.showOverlayData = show
  }

  @Action({ commit: 'setShowOverlayMutation' })
  public async setShowOverlay(show: boolean) {
    return show
  }

  @Mutation
  public setShowOverlayMutationConcierge(show: boolean) {
    this.showConciergeOverlayData = show
  }

  @Action({ commit: 'setShowOverlayMutationConcierge' })
  public async setShowOverlayConcierge(show: boolean) {
    return show
  }
}
