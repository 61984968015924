


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'

@Component({
  components: { LabelNormal }
})
export default class ShowText extends Vue {
  @Prop({ default: false }) hideExpansion?: boolean
  @Prop() hideFrom?: number
  @Prop() text: string
  hide = true

  toggleHide(e: any) {
    e.stopPropagation()
    this.hide = !this.hide
  }

  showTrimmed() {
    if (this.hideFrom && this.text && this.text.length > this.hideFrom) {
      return this.text.slice(0, this.hideFrom) + '...'
    } else {
      return this.text
    }
  }
}
