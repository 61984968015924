






















































































































import Component from 'vue-class-component'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import Heading from '@/components/core/Heading.vue'
import Carousel from '@/components/ui/Carousel.vue'
import SubscriptionsHelper from '@/services/helpers/subscriptionsHelper'
import { IAgreementV2, IClientOffering, ISupplier } from '@/shared/components/interfaces/agreements.interface'
import AgreementsV2 from '@/store/modules/agreementsV2'
import agreementsApi from '@/shared/services/api/agreementsApi'
import { IProposal } from '@/shared/services/api.interface'
import ChatStore from '@/store/modules/chatStore'
import Space from '@/components/layout/Space.vue'
import Button from '../components/core/Button.vue'
import TaskCard from '@/components/ui/TaskCard.vue'
import Tag from '@/components/ui/Tag.vue'
import Grid from '@/components/layout/Grid.vue'
import Flex from '@/components/layout/Flex.vue'
import PageHeader from '@/components/ui/PageHeader.vue'
import FloatingCta from '@/components/core/FloatingCta.vue'
import SubscriptionCarouselCard from '@/components/ui/SubscriptionCarouselCard.vue'
import { IRequest } from '@/shared/components/interfaces/request.interface'
import moment from 'moment'
import ClientStore, { IClientData } from '@/store/modules/client'
import ClientStateStore from '@/store/modules/clientStateStore'
import ServiceCollections from '@/components/explore/ServiceCategory.vue'
import { IStoryblokPayload } from '@/shared/components//interfaces/storyblok.interface'
import StoryblockApi from '@/services/api/storyblockApi'
import ArrowButton from '@/components/legacy_components/ArrowButton.vue'
import { config } from '@/config'

const userState = getModule(Auth)
const ClientState = getModule(ClientStore)
const chatState = getModule(ChatStore)
const agreementsState = getModule(AgreementsV2)
const clientStateStore = getModule(ClientStateStore)

export interface IOrder {
  id: string
  status: 'current' | 'invoiced' | 'cancelled'
  title: string
  supplier: ISupplier
}

@Component({
  components: {
    Space,
    PageHeader,
    Heading,
    Button,
    Grid,
    Flex,
    Carousel,
    SubscriptionCarouselCard,
    TaskCard,
    Tag,
    FloatingCta,
    ServiceCollections,
    ArrowButton
  }
})
export default class Dashboard extends Vue {
  $locale: string
  clientStateStore = clientStateStore
  chatState = chatState
  agreementsState = agreementsState
  proposals: IProposal[] = []
  requests: IRequest[] = []
  agreements: IAgreementV2[] = []
  adHocAgreements: IAgreementV2[] = []
  client: IClientData | null = null
  isLockedModalShowing = false

  story: IStoryblokPayload | null = null

  carouselScrollTarget: string | null = null

  get storyUrl() {
    switch (this.$locale) {
      case 'da':
      case 'da-DK':
        return 'da/dashboard'
      case 'en':
      case 'en-US':
      case 'en-GB':
        return 'en/dashboard'
      default:
        return 'dashboard'
    }
  }

  get currentSubscriptions(): IClientOffering[] {
    return SubscriptionsHelper.clientSubscriptions(this.agreements).sort((a, b) => {
      if (a.status === 'terminated') return 1
      else if (b.status === 'terminated') return -1
      return 0
    })
  }

  get notificationLevel() {
    return clientStateStore.notificationLvl ?? 0
  }

  navigateSubscription(sub: IClientOffering) {
    if (sub.slug === 'lunch' && config.LUNCH_CLIENTS.includes(userState.clientId)) {
      return `/lunch/${sub.id}`
    } else {
      return `/agreements/${sub.id}`
    }
  }

  setRequestsScrollTarget(direction: 'left' | 'right') {
    this.carouselScrollTarget = 'request'
    this.scrollIt(direction)
  }

  setOrdersScrollTarget(direction: 'left' | 'right') {
    this.carouselScrollTarget = 'agreement'
    this.scrollIt(direction)
  }

  setSubscriptionsScrollTarget(direction: 'left' | 'right') {
    this.carouselScrollTarget = 'subscription'
    this.scrollIt(direction)
  }

  scrollIt(direction: 'left' | 'right') {
    const scrollCarousel = document.getElementById(this.carouselScrollTarget + '-carousel')
    const scrollItems = document.getElementsByClassName(this.carouselScrollTarget + '-card')
    const cardWidth = (scrollItems[0] as any).offsetWidth
    const scrollAmount =
      direction === 'left' ? scrollCarousel!.scrollLeft - cardWidth : scrollCarousel!.scrollLeft + cardWidth
    scrollCarousel!.scrollTo({
      left: scrollAmount,
      behavior: 'smooth'
    })
  }

  openRequest(e: any, request: IRequest) {
    e.preventDefault()
    e.stopPropagation()
    if (this.clientStateStore.functionalityBlocked) {
      this.clientStateStore.hideShowModal(true)
    } else {
      this.$router.push({ name: 'request', params: { id: request.id } })
    }
  }

  onClose() {
    this.isLockedModalShowing = false
  }

  goToRequest() {
    this.$amplitude.logEvent('Your Office: Go To Request (Sunrise)')
    clientStateStore.functionalityBlocked
      ? clientStateStore.hideShowModal(true)
      : this.$router.push({ name: 'request' })
  }

  hasUnreadMessages(id: string) {
    return this.chatState.unreadMessages.includes(id)
  }

  newRequest(request: IRequest) {
    return moment().diff(moment(request.createdAt), 's') < 30
  }

  showNewBadge(request: IRequest) {
    return moment().diff(moment(request.createdAt), 'm') < 5
  }

  creationDate(date: string) {
    return moment(date).isSame(moment(), 'day') ? 'today' : moment(date).format('MMM DD, Y')
  }

  requestStatus(request: IRequest) {
    switch (request.status) {
      case 'created':
        return { status: 'created', theme: 'yellow', text: 'CREATED' }
      case 'in_progress':
        return { status: 'in_progress', theme: 'blue', text: 'IN PROGRESS' }
      case 'completed':
        return { status: 'completed', theme: 'green', text: 'COMPLETED' }
      case 'cancelled':
        return { status: 'canceled', theme: 'red', text: 'CANCELLED' }
      default:
        return { status: 'ready', theme: 'orange', text: 'Pending approval' }
    }
  }

  get sortedRequests() {
    return this.sortRequests(this.requests).filter(
      (request: IRequest) => request.status !== 'cancelled' && request.status !== 'completed'
    )
  }

  sortRequests(allRequests: IRequest[]) {
    return allRequests.sort((a: IRequest, b: IRequest) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  }

  async created() {
    if (!userState.clientId) {
      userState.logout()
    }
    //   this.client = await this.$billie.clients.get(this.userState.clientId)
    await ClientState.getClient(userState.clientId)
    this.client = ClientState.client
    this.agreements = await agreementsApi.getAgreementsByClientId(userState.clientId)
    // this.adHocAgreements = await agreementsApi.getOrders(userState.clientId)
    this.adHocAgreements = this.agreements.filter(agreement => agreement.invoiceSchedule === 'once' && agreement.revisions[0].type !== 'termination').sort((a, b) => Date.parse(b.created!) - Date.parse(a.created!))
    this.requests = await this.$billie.serviceRequests.listByClient(userState.clientId)
    //   await RequestState.setAllRequests(allRequests)
    StoryblockApi.initializeStoryBlok(this.storyUrl, story => {
      this.story = story
    })
  }
}
