







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import moment from 'moment'
import Label from '@/components/core/Label.vue'

@Component({
  components: { Label }
})
export default class Button extends Vue {
  @Prop({ required: true }) date: moment.Moment

  get formattedDate() {
    return this.date.isSame(moment()) ? this.$t('labels.calendar.today') : this.date.format('ddd D MMM YYYY')
  }
}
