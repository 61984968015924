import _Vue from 'vue'
import jwt from 'jsonwebtoken'

interface StoryblokOptions {
    token: string | null,
    accessKeyDK: string
    accessKeyUK: string
}

export default function Storyblok(Vue: typeof _Vue, options: StoryblokOptions): void {
    if (options.token) {
        const decodedToken = jwt.decode(options.token) as { email: string; sub: string; service_area: string }
        Vue.prototype.$storyblokKey = decodedToken.service_area === 'LON' ? options.accessKeyUK : options.accessKeyDK
    }
}