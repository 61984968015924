















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PillToggle extends Vue {
  @Prop({ default: [] }) options: []
  @Prop({}) value: string

  get selected(): string {
    return this.value
  }

  set selected(newValue) {
    this.$emit('input', newValue)
  }

  handleClick(value: any) {
    this.selected = value
  }
}
