






















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import LabelForm from '@/components/legacy_components/LabelForm.vue'
import LabelField from '@/components/legacy_components/LabelField.vue'

@Component({
  components: { LabelSmall, LabelForm, LabelField }
})
export default class TextField extends Vue {
  @Prop({ default: 'text' }) inputType: string
  @Prop({ default: '' }) label: string
  @Prop() labelType: 'field'
  @Prop({ default: '' }) placeholder: string
  @Prop({ default: '' }) description: string
  @Prop({ default: '' }) errorMessage: string
  @Prop({ default: '' }) value: string | null
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) autofocus: boolean

  get hasError() {
    return this.errorMessage !== ''
  }

  mounted() {
    if (this.autofocus) {
      const el = this.$refs.textInput as HTMLElement
      el.focus()
    }
  }
}
