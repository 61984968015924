






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class LabelForm extends Vue {
  @Prop({ required: true }) text: string
}
