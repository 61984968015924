




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import jwt from 'jsonwebtoken'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'
import { IUserInfo } from '@/shared/components/interfaces/userInfo.interface'
import FormWizardTabs from '@/components/signup/FormWizardTabs.vue'
import FormWizardCreateProfile from '@/components/signup/FormWizardCreateProfile.vue'
import FormWizardSetPassword from '@/components/signup/FormWizardSetPassword.vue'
import FormWizardUsePlatform from '@/components/signup/FormWizardUsePlatform.vue'
import Button from '../../components/core/Button.vue'
import Heading from '@/components/core/Heading.vue'
import { AcceptPayload } from '@/shared/billie/api/invitations'

const AuthState = getModule(Auth)

@Component({
  components: {
    FormWizardTabs,
    FormWizardCreateProfile,
    FormWizardSetPassword,
    FormWizardUsePlatform,
    Button,
    Heading
  }
})
export default class Signup extends Vue {
  @Prop({ required: true }) inviteId: string
  $t: (str: string) => ''
  tabs: string[] = []
  activeTab = 0
  inviteData: IInvitee | null = null
  signupData: AcceptPayload = {
    firstName: '',
    lastName: '',
    position: '',
    phone: '',
    password: '',
    acceptsTerms: false
  }
  loadingState = ''
  error = ''

  created(): void {
    this.tabs = [
      this.$t('labels.signup.createYourProfile'),
      this.$t('labels.signup.setYourPassword'),
      this.$t('labels.signup.useThePlatform')
    ]
    this.$billie.invitations
      .get(this.inviteId)
      .then(data => {
        this.inviteData = data
      })
      .catch(() => {
        this.error = 'Invitation error'
      })
  }

  async nextTab(data: IUserInfo): Promise<void> {
    if (this.activeTab + 1 === this.tabs.length) {
      this.$amplitude.logEvent('User management - Activate account', { Step: 'Login' })
      this.login(this.inviteData!.email, this.signupData.password)
      return
    }
    Object.assign(this.signupData, data)
    if (this.activeTab === 1) {
      this.$amplitude.logEvent('User management - Activate account', { Step: 'Set password' })
      await this.createUser()
      return
    }
    this.$amplitude.logEvent('User management - Activate account', { Step: 'Create profile' })
    this.loadingState = ''
    this.activeTab++
  }

  previousTab(): void {
    if (this.activeTab > 0) {
      this.activeTab--
    }
  }
  navigateToLogin() {
    this.$router.push({ name: 'Login' })
  }
  login(email: string, password: string): void {
    this.loadingState = 'started'
    AuthState.authenticate({ email, password, strategy: 'local' })
      .then(token => {
        if (token && (jwt as any).decode(token).gm_role !== 'superadmin') {
          this.loadingState = 'done'
          this.$nextTick(() => {
            window.location.href = (this.$route.query.redirect as string) || '/'
          })
        }
      })
      .catch(error => {
        this.error = error.response?.data?.error
        this.loadingState = ''
      })
  }

  async createUser(): Promise<void> {
    this.loadingState = 'started'
    this.$billie.invitations
      .accept(this.inviteId, this.signupData)
      .then(() => {
        this.loadingState = 'done'
        setTimeout(() => {
          this.loadingState = ''
          this.activeTab++
        }, 600)
      })
      .catch(error => {
        this.error = error.response?.data?.error
        this.loadingState = ''
        this.activeTab++
      })
  }
}
