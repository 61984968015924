


































// @ is an alias to /src
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import OverlayStore from '@/store/modules/overlay'
import { IMenuItem } from '@/shared/components/interfaces/menuItems'
import UpdatedTermsPopUp from '@/components/UpdatedTermsPopUp.vue'
import MessageMode from '@/store/modules/messageMode'
import Sidebar from '@/components/Sidebar.vue'
import LayoutSidenav from '@/components/layout/LayoutSidenav.vue'
import LayoutFooterNav from '@/components/layout/LayoutFooterNav.vue'
import FooterNav from '@/components/Footer.vue'
import { PAGES } from '@/globals'
import MainAside from '@/components/layout/MainAside.vue'
import SearchBarV2 from '@/components/dropdowns/SearchBarV2Dropdown.vue'

const messageState = getModule(MessageMode)
const overlayState = getModule(OverlayStore)
const userState = getModule(Auth)

@Component({
  components: {
    FooterNav,
    Sidebar,
    LayoutSidenav,
    LayoutFooterNav,
    UpdatedTermsPopUp,
    MainAside,
    SearchBarV2
  }
})
export default class MainLayout extends Vue {
  messageState = messageState
  overlayState = overlayState
  userState = userState
  menuClicked = true
  searchOpen = false

  menuItems: IMenuItem[] = [
    {
      key: PAGES.HOME,
      icon: 'eva eva-home',
      link: 'dashboard'
    },
    {
      key: PAGES.CALENDAR,
      icon: 'eva eva-calendar',
      link: 'calendar'
    },
    {
      key: PAGES.INVOICES,
      icon: 'eva eva-credit-card',
      link: 'invoices',
      count: 0
    },
    {
      key: PAGES.EXPLORE,
      icon: 'eva eva-eye',
      link: 'explore'
    }
  ]

  toggleSearch() {
    this.searchOpen = !this.searchOpen
  }

  get messageMode() {
    return this.messageState.messageMode
  }

  async mounted() {
    document.addEventListener('click', e => {
      e.stopPropagation()
      if (this.$refs.searchBar) {
        const target = e.target as Element
        if (target.classList.contains('close-search')) this.toggleSearch()
        else if (
          target !== this.$refs.searchBar &&
          !(this.$refs.searchBar as Element).contains(target) &&
          !target.classList.contains('search')
        ) {
          this.toggleSearch()
        }
      }
    })
  }
}
