























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore
import ClickOutside from 'vue-click-outside'

@Component({
  components: {},
  directives: { ClickOutside }
})
export default class InputDropdown extends Vue {
  @Prop({ required: false, default: true }) readonly: boolean
  @Prop({ required: false, default: true }) showClear: boolean
  @Prop({ required: false, default: true }) isEditable: boolean
  @Prop({ required: false, default: true }) disableDropdown: boolean

  isDropdownOpen = false

  trigger() {
    if (!this.disableDropdown) this.isDropdownOpen = !this.isDropdownOpen
    this.$emit('customTrigger')
    this.$emit('isDropdownOpen', this.isDropdownOpen)
  }

  closeDropdown() {
    if (this.isDropdownOpen) this.isDropdownOpen = false
    this.$emit('isDropdownOpen', this.isDropdownOpen)
  }
}
