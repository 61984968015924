
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class ImagePreviewModal extends Vue {
  @Prop({ required: true }) readonly src: string
  @Prop({ required: true }) readonly onClose: () => void

  created() {
    window.addEventListener('keydown', this.keydownListener)
  }
  keydownListener(e: any) {
    if (e.key === 'Escape') {
      this.onClose()
    }
  }
}
