

























































































































































































/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getInitials } from '../../shared/services/Functions'
import moment from 'moment'
import { IClientAccount } from '../../shared/components/interfaces/clients.interface'
import ClientsApi from '../../shared/services/api/ClientsApi'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import TextField from '@/components/legacy_components/TextField.vue'
import StepperInput from '@/components/legacy_components/StepperInput.vue'
import Price from '../../components/core/Price.vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'
import PillToggleInput from '@/components/legacy_components/PillToggleInput.vue'
import Flex from '@/components/layout/Flex.vue'

@Component({
  components: {
    ButtonPrimary,
    TextField,
    StepperInput,
    Price,
    Space,
    Flex,
    Heading,
    PillToggleInput
  }
})
export default class ClientAccount extends Vue {
  @Prop() client: IClientAccount
  getInitials = getInitials
  companyDetails: IClientAccount = {
    address: {
      addressLine: '',
      addressLine2: null,
      zipcode: '',
      country: '',
      countryCode: 'DK',
      city: ''
    }
  }

  get getTrialEndDate() {
    return moment(this.companyDetails.trialExpirationDate as string)
  }

  get getFormattedDate() {
    return moment(this.getTrialEndDate).format('DD/MM/YYYY')
  }

  get isTrial() {
    if (this.companyDetails.trialExpirationDate) {
      if (this.getTrialEndDate.isAfter(moment())) {
        return true
      }
    }
    return false
  }

  get getCurrency() {
    const currency = this.companyDetails.address?.countryCode
    switch (currency) {
      case 'UK': {
        return 'GBP'
      }
      default: {
        return 'DKK'
      }
    }
  }
  get disableSaveButton() {
    return JSON.stringify(this.companyDetails) === JSON.stringify(this.client)
  }

  mounted() {
    this.setCompanyDetails(this.client)
  }

  setCompanyDetails(company: IClientAccount) {
    this.companyDetails = JSON.parse(JSON.stringify(company))
  }

  changedProperties() {
    const param: IClientAccount = { id: this.client.id }
    Object.keys(this.companyDetails).forEach((prop: string) => {
      if (typeof this.companyDetails[prop] === 'object' && this.companyDetails[prop] !== null) {
        if (JSON.stringify(this.companyDetails[prop]) !== JSON.stringify(this.client[prop])) {
          param[prop] = this.companyDetails[prop]
        }
      } else if (this.companyDetails[prop] !== null && this.companyDetails[prop] !== this.client[prop]) {
        param[prop] = this.companyDetails[prop]
      }
    })
    return param
  }

  saveChanges() {
    const props = this.changedProperties()
    if (this.disableSaveButton || Object.keys(props).length === 1) {
      return
    }
    ClientsApi.saveClientData(this.companyDetails).then((data: IClientAccount) => {
      this.setCompanyDetails(data)
    })
  }
}
