





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelField from '@/components/legacy_components/LabelField.vue'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'

@Component({
  components: { LabelField, LabelSmall }
})
export default class CustomCheckboxInput extends Vue {
  @Prop({ required: true }) value: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: '' }) errorMessage: string
  @Prop({ default: true }) acceptClicksForLabel: boolean

  get inputModel() {
    return this.value
  }

  set inputModel(value) {
    this.emitValue(value)
  }

  get hasError() {
    return this.errorMessage !== ''
  }

  emitValue(value: boolean) {
    this.$emit('input', value)
  }

  handleInputClick() {
    if (!this.disabled) {
      this.emitValue(!this.value)
    }
  }

  handleClick() {
    if (!this.acceptClicksForLabel) {
      return
    }
    this.handleInputClick()
  }
}
