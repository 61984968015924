









































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/shared/storeModules/auth'
import BudgetModal from '@/components/requests/BudgetModal.vue'
import { IBudget } from '@/shared/components/interfaces/request.interface'
import RequestProperty from '@/components/requests/RequestProperty.vue'
import InputDropdown from '@/components/core/dropdown/InputDropdown.vue'
import MinMax from '@/components/requests/MinMax.vue'
import Flex from '@/components/layout/Flex.vue'
import Space from '@/components/layout/Space.vue'
import RequestInputTab from '@/components/requests/RequestInputTab.vue'

const userState = getModule(Auth)

@Component({
  components: { RequestProperty, BudgetModal, InputDropdown, RequestInputTab, MinMax, Flex, Space }
})
export default class BudgetInput extends Vue {
  @Prop({ required: true }) value: IBudget | null
  @Prop({ required: false, default: true }) isEditable: boolean
  userState = userState
  isModalOpen = false
  isDropdownOpen = false
  invalidMax = false
  type: 'total' | 'perPerson' | 'perHour' | 'perWeek' | 'perMonth' = 'total'
  min: null | number = 0
  max: null | number = null

  @Watch('value')
  onBudgetChange(value: IBudget | null, _oldVal: IBudget | null) {
    this.populateBudget(value)
  }

  get getCurrency() {
    const currency = this.userState.user.client?.countryCode
    switch (currency) {
      case 'UK': {
        return 'GBP'
      }
      default: {
        return 'DKK'
      }
    }
  }

  populateBudget(value = this.value) {
    if (value) {
      this.type = value.type
      this.min = value.priceFrom
      this.max = value.priceTo
    }
  }

  created() {
    this.populateBudget()
  }

  clear() {
    this.invalidMax = false
    this.type = 'total'
    this.min = 0
    this.max = null
    this.$emit('input', null)
    if (this.isModalOpen) this.isModalOpen = false
  }

  setBudget(value: IBudget) {
    this.$emit('input', { type: value.type, priceFrom: value.priceFrom, priceTo: value.priceTo })
    this.populateBudget(value)
  }

  selectType(type: 'total' | 'perPerson' | 'perHour' | 'perWeek' | 'perMonth') {
    this.type = type
    this.onValueChange()
  }

  minMaxInput(minMax: { min: number | null; max: number | null }) {
    this.min = minMax.min
    this.max = minMax.max
    this.onValueChange()
  }

  onValueChange() {
    this.isValid({ min: this.min, max: this.max })
    if (this.invalidMax === true) return this.$emit('input', null)
    return this.$emit('input', { type: this.type, priceFrom: this.min, priceTo: this.max })
  }

  isValid(minMax: { min: number | null; max: number | null }) {
    if (minMax.min && minMax.max)
      return (this.invalidMax = minMax.min >= minMax.max && minMax.min >= 0 && minMax.max >= 0)
    if (minMax.min && !minMax.max) return (this.invalidMax = true)
    return (this.invalidMax = false)
  }

  setDropdown(isDropdownOpen: boolean) {
    this.isDropdownOpen = isDropdownOpen
  }

  toggleModal() {
    this.invalidMax = false

    this.isModalOpen = !this.isModalOpen
  }
}
