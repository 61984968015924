












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

type ButtonType = 'submit' | 'button' | 'reset'

@Component({
  components: {}
})
export default class Button extends Vue {
  @Prop({ required: false, default: false }) disabled: boolean
  @Prop({ required: false, default: false }) fullWidth: boolean
  @Prop({ required: false, default: 'button' }) type: ButtonType
  @Prop({ required: false }) size: string
  @Prop({ required: false }) icon: string
  @Prop({ required: false }) reverse: boolean
  @Prop({ required: false, default: 'button-primary' }) theme: string
}
