











































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IElement } from '@/shared/services/api.interface'
import FormattedPrice from '@/components/FormattedPrice.vue'
import Element from '@/services/rpa/element'
import DANISH from '../../shared/i18n/RRULE-DANISH'
//@ts-ignore
import { RRule, RRuleSet, rrulestr } from 'rrule'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import Space from '@/components/layout/Space.vue'

@Component({ components: { FormattedPrice, LabelSmall, LabelNormal, Space } })
export default class ProposalsList extends Vue {
  @Prop({ required: true }) elements!: IElement[]
  @Prop({ required: true }) currency!: string
  @Prop({ required: true }) isMobileDevice: boolean
  @Prop({ default: false }) showRecurrence: boolean

  elementRecurrence(element: IElement) {
    const recurrence = element.schedule ? element.schedule : element.recurrence
    const rrule = rrulestr(recurrence as string)

    switch (this.$locale) {
      case 'da':
      case 'da-DK':
        return rrule.toText(id => (DANISH as any).tokens[id.toString()], DANISH as any)
      default:
        return rrule.toText()
    }
  }

  quantity(element: IElement) {
    return Element.quantity(element)
  }

  unitPrice(element: IElement) {
    return Element.clientPrice(element)
  }

  subtotal(element: IElement) {
    return Element.subtotal(element)
  }
}
