



















































import Vue from 'vue'
import Component from 'vue-class-component'
import Flex from '@/components/layout/Flex.vue'
import Heading from '@/components/core/Heading.vue'
import Inbox from '@/components/Inbox.vue'
import { Watch } from 'vue-property-decorator'
import Space from '@/components/layout/Space.vue'
import { PAGES } from '@/globals'

@Component({
  components: { Flex, Space, Heading, Inbox }
})
export default class MainAside extends Vue {
  open = false
  unread = 0

  getUnread(number: number) {
    this.unread = number
  }

  get henry() {
    return require('@/assets/henry-transparent.svg')
  }

  toggleAside() {
    this.open = !this.open
  }

  created() {
    if (this.$mq === 'desktopXl' && this.$route.name === PAGES.DASHBOARD) {
      this.open = true
      this.$root.$el.classList.add('aside-open')
    }
  }

  trackClickEvent() {
    this.$amplitude.logEvent('Your Office - Sidebar - Concierge')
  }

  @Watch('$mq')
  onMqChange() {
    if (this.$mq !== 'desktopXl') {
      this.open = false
      this.$root.$el.classList.remove('aside-open')
    }
    if (this.$mq === 'desktopXl' && this.$route.name === PAGES.DASHBOARD) {
      this.open = true
      this.$root.$el.classList.add('aside-open')
    }
  }

  @Watch('$route')
  onRouteChange() {
    if (this.$route.name !== PAGES.DASHBOARD) {
      this.$root.$el.classList.remove('no-scroll', 'aside-open')
      this.open = false
    } else {
      if (this.$mq !== 'desktopXl') {
        this.$root.$el.classList.remove('no-scroll', 'aside-open')
        this.open = false
      } else {
        this.$root.$el.classList.remove('no-scroll')
        this.open = true
      }
    }
  }

  @Watch('open')
  onToggleAside(value: boolean) {
    this.open = value
    this.open ? this.$root.$el.classList.add('aside-open') : this.$root.$el.classList.remove('aside-open')
    if (this.$mq === 'mobile' && this.open) {
      this.$root.$el.classList.add('no-scroll')
    } else {
      this.$root.$el.classList.remove('no-scroll')
    }
  }

  destroyed() {
    this.$root.$el.classList.remove('no-scroll', 'aside-open')
  }
}
