




















import { Component, Prop, Vue } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'

@Component({
  components: { LabelSmall }
})
export default class FormWizardTabs extends Vue {
  @Prop({ required: true, default: [] }) tabs: string[]
  @Prop({ required: true, default: 0 }) activeTab: number
}
