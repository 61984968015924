import { render, staticRenderFns } from "./ContentPlaceholder.vue?vue&type=template&id=21d02543&scoped=true&"
import script from "./ContentPlaceholder.vue?vue&type=script&lang=ts&"
export * from "./ContentPlaceholder.vue?vue&type=script&lang=ts&"
import style0 from "./ContentPlaceholder.vue?vue&type=style&index=0&id=21d02543&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d02543",
  null
  
)

export default component.exports