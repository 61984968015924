



































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Tag from '@/components/ui/Tag.vue'
import Avatar from '@/components/core/Avatar.vue'
import { IRequest } from '@/shared/components/interfaces/request.interface'
import { IAgreementV2 } from '@/shared/components/interfaces/agreements.interface'
import Label from '@/components/core/Label.vue'

@Component({
  components: { Tag, Avatar, Label }
})
export default class TaskCard extends Vue {
  @Prop() cardData: IRequest | IAgreementV2
  @Prop() created: string
  @Prop() tag: { status: string; theme: string; text: string }
  @Prop() hasNotifications: boolean
  @Prop() showNewBadge: boolean

  trackClickEvent() {
    const event = 'clientId' in this.cardData ? 'Your Office - Request' : 'Your Office - Order'
    this.$amplitude.logEvent(event, {
      title: this.cardData.title,
      hasNotifications: this.hasNotifications
    })
  }
}
