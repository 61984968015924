































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ISupplierData, IHighlight, IAddress } from '../../shared/components/interfaces/supplierData.interface'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import MeetThePeople from './SupplierMeetThePeople.vue'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'

@Component({
  components: {
    LabelNormal,
    MeetThePeople,
    LabelSmall
  }
})
export default class SupplierAboutSection extends Vue {
  @Prop() highlights: IHighlight[]
  @Prop() companyName: string
  @Prop() about: string
  @Prop() logo: string
  @Prop() address: IAddress
  @Prop() companyData: ISupplierData
  @Prop({ default: false }) navigate: boolean
  @Prop({ default: false }) sectionName: boolean
  @Prop({ default: false }) displayLogo: boolean

  navigateTo() {
    this.$router.push('/supplier/' + this.$route.params.supplierId)
  }

  get supplierHighlights() {
    let arrOfThree = []
    if (this.highlights) {
      for (let index = 0; index < this.highlights.length; index++) {
        if (index < 3) {
          const element = this.highlights[index]
          arrOfThree.push(element)
        }
      }
    }
    return arrOfThree
  }
}
