






















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IClient } from '@/shared/components/interfaces/clients.interface'
import Auth, { IUser } from '@/shared/storeModules/auth'
import ButtonIcon from '@/components/legacy_components/ButtonIcon.vue'
import TextField from '@/components/legacy_components/TextField.vue'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import { IInvitee } from '@/shared/components/interfaces/invitees.interface'
import DeleteUserModal from '../../components/users/DeleteUserModal.vue'
import DropdownMenu from '@/components/dropdowns/DropdownMenu.vue'
import { IDropdownMenu } from '@/shared/components/interfaces/dropdownMenu.interface'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
import BModal from '@/components/BModal.vue'
import Space from '@/components/layout/Space.vue'
import Flex from '@/components/layout/Flex.vue'
import List from '@/components/core/list/List.vue'
import ListItem from '@/components/core/list/ListItem.vue'
import Label from '@/components/core/Label.vue'
import Heading from '@/components/core/Heading.vue'
const UserState = getModule(Auth)

@Component({
  components: {
    ButtonIcon,
    TextField,
    ButtonPrimary,
    DeleteUserModal,
    DropdownMenu,
    BModal,
    Space,
    Flex,
    List,
    ListItem,
    Label,
    Heading
  }
})
export default class ClientUsers extends Vue {
  @Prop({ required: true }) client: IClient
  users: IUser[] = []
  invitees: IInvitee[] = []
  $t: (str: string) => ''
  form = {
    email: '',
    phone: '',
    invitedAt: '',
    lastEmailSent: null,
    id: '',
    clientId: '',
    trialExpirationDate: null,
    platformFee: null,
    currency: null
  }
  showDeleteUserModal = false
  selectedUser: IUser | IInvitee | null = null
  menuItems: IDropdownMenu[] = []
  inviteeMenuItems: IDropdownMenu[] = []
  UserState = UserState

  async created() {
    if (this.client && this.client.id) {
      this.users = await this.$billie.users.listByClient(this.client.id)
      this.sortByKey('lastActivityAt', this.users)
      this.invitees = await this.$billie.invitees.listByClient(this.client.id)
      this.sortByKey('invitedAt', this.invitees)
    }
    this.menuItems = [{ key: 'delete', value: this.$t('labels.clientInfo.delete') }]
    this.inviteeMenuItems = [
      { key: 'delete', value: this.$t('labels.clientInfo.delete') },
      { key: 'resend', value: this.$t('labels.clientInfo.resend') }
    ]
  }

  sortByKey(key: string, data: any[]) {
    data.sort((a, b) => {
      if (!a[key]) {
        return -1
      } else if (!b[key]) {
        return -1
      }
      //@ts-ignore
      return new Date(b[key]) - new Date(a[key])
    })
  }

  async deleteModalConfirm(user: IUser | IInvitee, reason: string, other: string) {
    try {
      if (user.invitedAt) {
        await this.$billie.invitees.delete(this.client.id, user.id!)
        this.$amplitude.logEvent('User management - Delete user', {
          Type: 'Invited user',
          Reason: reason,
          Other: other
        })
        this.invitees = this.invitees.filter(u => u.id !== user.id)
      } else {
        await this.$billie.users.delete(this.client.id, user.id!)
        this.$amplitude.logEvent('User management - Delete user', {
          Type: 'Activated user',
          Reason: reason,
          Other: other
        })
        this.users = this.users.filter(u => u.id !== user.id)
      }
      this.showDeleteUserModal = false
      this.$notify({ title: 'User deleted' })
    } catch (error) {
      //TODO: show error
    }
  }

  date(date: string | null | undefined) {
    if (date) {
      return moment(date).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  cancelDeleteModalCallback() {
    this.showDeleteUserModal = false
  }

  async menuClickedCallback(item: IDropdownMenu, data: IUser | IInvitee) {
    if (item.key === 'delete') {
      this.showDeleteUserModal = false
      this.selectedUser = data
      setTimeout(() => {
        this.showDeleteUserModal = true
      }, 0)
    } else {
      const invitee = data as IInvitee
      if (item.key === 'resend') {
        await this.$billie.invitations.resendEmail(this.client.id, invitee.id).then(() => {
          this.$amplitude.logEvent('User management - Resend invite')
          this.$notify({ title: 'Invitation sent' })
        })
      }
    }
  }

  async inviteUser() {
    this.form.clientId = this.client.id
    const payload = {
      email: this.form.email,
      phone: this.form.phone,
      clientId: this.form.clientId
    }
    const newInvitee = await this.$billie.invitees.createForClient(this.client.id, payload)
    this.invitees.push(newInvitee)
    this.sortByKey('invitedAt', this.invitees)
    this.$amplitude.logEvent('User management - Invite user')
    this.$notify({ title: 'Invitation sent!' })
    //@ts-ignore
    this.$refs['invite-modal'].hide()
  }

  openInvitationModal() {
    this.form.email = ''
    //@ts-ignore
    this.$refs['invite-modal'].show()
  }

  populateUserRole(role: any) {
    if (role === 'admin')
      return [
        {
          key: 'role',
          value: this.$t('labels.clientInfo.administrator')
        }
      ]
    else
      return [
        {
          key: 'role',
          value: this.$t('labels.clientInfo.user')
        }
      ]
  }
}
