













import Vue from 'vue'
import Component from 'vue-class-component'
import Heading from '@/components/core/Heading.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { Heading }
})
export default class PageHeader extends Vue {
  @Prop() pageTitle: string
}
