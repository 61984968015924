







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
//@ts-ignore
import variables from '@/assets/scss/variables/_sizes.scss'

@Component({
  components: {}
})
export default class Space extends Vue {
  @Prop({ required: true }) space: number[]
  @Prop() spaceXs: number[]
  @Prop() spaceSm: number[]
  @Prop() spaceMd: number[]
  @Prop() spaceLg: number[]
  @Prop() spaceXl: number[]
  // @Prop() gap?: number | number[]

  get spacing() {
    switch (this.$mq) {
      case 'mobile':
        return this.spaceXs || this.space
      case 'tablet':
        return this.spaceSm || this.space
      case 'desktop':
        return this.spaceMd || this.space
      case 'desktopLg':
        return this.spaceLg || this.space
      case 'desktopXl':
        return this.spaceXl || this.space
      default:
        return this.space
    }
  }

  get styles() {
    switch (this.spacing.length) {
      case 4:
        return {
          margin: `${this.spacing[0]}px 0px ${this.spacing[2]}px 0px`,
          padding: `0px ${this.spacing[1]}px 0px ${this.spacing[3]}px`
        }
      case 3:
        return {
          margin: `${this.spacing[0]}px 0px ${this.spacing[2]}px`,
          padding: `0px ${this.spacing[1]}px 0px`
        }
      case 2:
        return {
          margin: `${this.spacing[0]}px 0px`,
          padding: `0px ${this.spacing[1]}px`
        }
      case 1:
        return {
          margin: `${this.spacing[0]}px 0px`,
          padding: `0px ${this.spacing[0]}px`
        }
      default:
        return {
          margin: `16px 0px`,
          padding: `0px 0px`
        }
    }
  }
}
