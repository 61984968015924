








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class Label extends Vue {
  @Prop({ default: 1 }) level?: number
  @Prop({ default: 'heading-lg' }) type: 'heading-sm' | 'heading-md' | 'heading-lg'
  tag = `h${this.level}`
}
