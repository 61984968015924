












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Flex from '@/components/layout/Flex.vue'

@Component({
  components: { Flex }
})
export default class RequestProperty extends Vue {
  @Prop({ required: true }) icon: string
  @Prop({ required: false }) label: string
  @Prop({ required: false, default: false }) isClickable: boolean
  @Prop({ default: false }) showClear: boolean
}
