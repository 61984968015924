






























































import Vue from 'vue'
import Space from '@/components/layout/Space.vue'
import Heading from '@/components/core/Heading.vue'
import Button from '@/components/core/Button.vue'
import { SunriseOffering } from '@/pages/explore/sunrise/sunriseData'

const RequestGuide = Vue.extend({
  components: { Space, Heading, Button },
  props: {
    serviceData: { type: null || (Object as () => SunriseOffering), default: null }
  },
  data() {
    return {
      infoBoxOpen: false
    }
  },

  methods: {
    toggleInfoBox() {
      this.infoBoxOpen = true
      this.$amplitude.logEvent('Request: How does this work? (Sunrise)')
    }
  }
})

export default RequestGuide
