import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../store'

@Module({
  name: 'MessageMode',
  dynamic: true,
  store: store,
})
export default class MessageMode extends VuexModule {
  isMessageMode = false

  get messageMode() {
    return this.isMessageMode
  }

  @Mutation
  public setMessageModeMutation(value: boolean) {
    this.isMessageMode = value
  }

  @Action({ commit: 'setMessageModeMutation' })
  public async setMessageMode(payload: boolean) {
    return payload
  }
}
