






import Vue from 'vue'
import Component from 'vue-class-component'
import SubscriptionCarouselCard from '@/components/ui/SubscriptionCarouselCard.vue'

@Component({
  components: { SubscriptionCarouselCard }
})
export default class Carousel extends Vue {}
