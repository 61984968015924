





























































import Vue from 'vue'
import Component from 'vue-class-component'
import Space from '@/components/layout/Space.vue'
import Label from '@/components/core/Label.vue'
import { Prop } from 'vue-property-decorator'
import { IProposal } from '@/shared/services/api.interface'
import RequestProposalModal from '@/components/requests/RequestProposalModal.vue'
import Element from '@/services/rpa/element'
import FormattedPrice from '@/components/FormattedPrice.vue'
import Tooltip from '@/components/core/tooltip/Tooltip.vue'
import CtaBubble from '@/components/core/CtaBubble.vue'

@Component({
  components: { Space, Label, RequestProposalModal, FormattedPrice, Tooltip, CtaBubble }
})
export default class RequestProposalList extends Vue {
  @Prop({ required: true }) proposals: IProposal[]
  @Prop({ required: false, default: false }) isEditable: boolean
  selectedProposal: IProposal | null = null
  isShowing = false

  toggleProposalModal(proposal: IProposal) {
    if (!this.isShowing) this.trackProposalEvent(proposal)
    this.selectedProposal = proposal
    this.isShowing = !this.isShowing
  }

  closeProposalModal() {
    this.selectedProposal = null
    this.isShowing = false
  }

  proposalStatus(proposal: IProposal) {
    switch (proposal.accepted) {
      case true:
        return {
          status: 'accepted',
          icon: 'eva eva-checkmark',
          text: this.$t('proposals.status.accepted'),
          theme: 'green'
        }
      case false:
        return { status: 'rejected', icon: 'eva eva-close', text: this.$t('proposals.status.rejected'), theme: 'red' }
      default:
        return {
          status: 'pending',
          icon: 'eva eva-bell-outline',
          text: this.$t('proposals.status.pending'),
          theme: 'orange'
        }
    }
  }

  total(proposal: IProposal) {
    return proposal.revisions[0].elements.map(Element.total).reduce((sum, amount) => sum + amount, 0)
  }

  trackProposalEvent(proposal: any) {
    this.$amplitude.logEvent('Request - Proposal - Open', {
      accepted: proposal.accepted,
      title: proposal.title,
      category: proposal.category
    })
  }
}
