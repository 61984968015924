import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {},
	state: {
		headerTitle: 'clients'
	},
	mutations: {
		setHeaderTitle(state, title) {
			state.headerTitle = title
		}
	},
	actions: {
		setHeaderTitle({ commit }, title) {
			commit('setHeaderTitle', title)
		}
	},
	plugins: []
})
