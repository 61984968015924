



































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Modal from '@/components/core/modal/Modal.vue'
import ModalHeader from '@/components/core/modal/ModalHeader.vue'
import Button from '@/components/core/Button.vue'
import MinMax from '@/components/requests/MinMax.vue'

@Component({
  components: { Modal, ModalHeader, Button, MinMax }
})
export default class PeopleModal extends Vue {
  @Prop({ required: true }) readonly onClose: () => void
  @Prop({ required: true }) min: any
  @Prop({ required: true }) max: any
  @Prop({ required: true }) isShowing: boolean
  @Prop({ required: false, default: false }) invalidMax: boolean

  selectedMin: null | number = null
  selectedMax: null | number = null

  handleDone() {
    this.$emit('setPeople', { min: this.selectedMin, max: this.selectedMax })
    this.onClose()
  }

  minMaxInput(minMax: { min: number | null; max: number | null }) {
    this.selectedMin = minMax.min
    this.selectedMax = minMax.max
    this.$emit('isValid', { min: this.selectedMin, max: this.selectedMax })
  }

  created() {
    this.selectedMin = this.min
    this.selectedMax = this.max
  }
}
