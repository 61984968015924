





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'
import { IFileResponseData } from '../../shared/components/interfaces/fileHandler.interface'
import Conversation from './Conversation.vue'
import MessageInput from './MessageInput.vue'
import { IConversationMessage, NewMessage } from '@/shared/components/interfaces/messages.interface'
import { IMessage } from './IMessage'

@Component({
  components: {
    Conversation,
    MessageInput
  }
})
export default class Chat extends Vue {
  @Prop({ required: true }) readonly messages: IConversationMessage[]
  @Prop({ required: true }) readonly onSendMessage: (message: NewMessage) => void
  @Prop() readonly onInputFocus: () => void
  @Prop() uploadedFiles: IFileResponseData[]
  @Prop() uploadInProgress: boolean
  @Prop() isConnected: boolean
  @Prop() loading: boolean
  @Prop({ required: false, default: false }) disabled: boolean
  newMessage: NewMessage = { text: '', attachments: [] }

  get chatMessages(): IMessage[] {
    return this.messages.map(message => {
      return {
        id: message.id,
        text: message.text,
        attachments: message.attachment ? [message.attachment.id] : [],
        writtenAt: moment(message.createdAt),
        isReply: message.user.clientId === null,
        sender: message.user.firstName + ' ' + message.user.lastName,
        unread: message.unread
      }
    })
  }

  click() {
    this.$emit('click')
  }

  removeFile(file: IFileResponseData) {
    this.$emit('removeFile', file)
  }

  onAddFile(attachments: File[]) {
    this.$emit('addFile', attachments)
  }

  scrollToBottom() {
    setTimeout(() => {
      const el = this.$el.querySelector('#scrollHook')

      if (el) {
        el.scrollIntoView()
      }
    }, 200)
  }

  mounted() {
    this.scrollToBottom()
  }

  updated() {
    this.scrollToBottom()
  }
}
