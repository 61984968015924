


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { MenuCategory } from '@/shared/components/interfaces/menuItems'
import NavigationHelper from '@/services/helpers/navigationHelper'
import MenuItem from './MenuItem.vue'

@Component({
  components: { MenuItem }
})
export default class Sidebar extends Vue {
  @Prop({ required: true }) menuItems!: MenuCategory[]
  @Prop() forceShow!: boolean
  @Prop({ required: true }) routeName!: string
  @Prop({ required: true }) translate!: () => void
  navigationHelper = NavigationHelper

  get logo() {
    return require('@/assets/logo_beta.svg')
  }

  addedStyles() {
    return this.forceShow ? { display: 'block' } : {}
  }
}
