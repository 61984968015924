
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SupplierApi from '@/shared/services/api/supplierApi'
import { ISupplierData } from '@/shared/components/interfaces/supplierData.interface'
@Component({})
export default class Who extends Vue {
  @Prop() title: 'Who' | 'Where'
  @Prop() id: string
  data: ISupplierData | any = {}

  async created() {
    if (this.title === 'Who') {
      this.data = await SupplierApi.getSupplierData(this.id)
    }
    if (this.title === 'Where') {
      this.data = await this.$billie.clients.get(this.id)
    }
  }
}
