


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelNormal from '@/components/legacy_components/LabelNormal.vue'
import ButtonPrimary from '@/components/legacy_components/ButtonPrimary.vue'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import authApi from '@/shared/services/api/authApi'

@Component({
  components: { LabelNormal, ButtonPrimary, LabelSmall }
})
export default class ForgotPassword extends Vue {
  @Prop() user!: { email: ''; password: ''; strategy: 'local' }
  @Prop() successCallback!: () => void
  error: { message: string } | null = null

  async resetPassword(e: any) {
    e.preventDefault()
    e.stopPropagation()
    await authApi.requestResetPassword(this.user.email)
    this.successCallback()
  }

  triggerSubmit() {
    //@ts-ignore
    this.$refs.submit?.click()
  }
}
