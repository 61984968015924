import axios from './interceptor'
import { IClientPreferences } from '../../components/interfaces/clients.interface'

export default class UserPreferencesApi {
  static async getUserPrefrences(cleintId: string, userId: string): Promise<IClientPreferences> {
    return axios.get(`/api/clients/${cleintId}/users/${userId}/preferences`).then((resp) => resp.data)
  }

  static async updateUserPrefrences(cleintId: string, userId: string, data: IClientPreferences) {
    return axios.put(`/api/clients/${cleintId}/users/${userId}/preferences`, data).then((resp) => resp.data)
  }

  static async updateUserEmailPrefrences(cleintId: string, userId: string, chatNotificationsEnabled: boolean) {
    return axios
      .put(`/api/clients/${cleintId}/users/${userId}/preferences/chatNotificationsEnabled`, {
        value: chatNotificationsEnabled,
      })
      .then((resp) => resp.data)
  }
}
