


































import Vue from 'vue'
import Component from 'vue-class-component'
import Grid from '@/components/layout/Grid.vue'
import Flex from '@/components/layout/Flex.vue'

@Component({
  components: { Grid, Flex }
})
export default class ContentPlaceholder extends Vue {
  cards = [1, 2, 3, 4, 5]
  sections = [1, 2]

  created() {
    if (this.$route.path === '/dashboard') this.sections = [1]
  }
}
