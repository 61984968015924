


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LabelSmall from '@/components/legacy_components/LabelSmall.vue'
import LabelField from '@/components/legacy_components/LabelField.vue'
import LabelForm from '@/components/legacy_components/LabelForm.vue'

@Component({
  components: { LabelSmall, LabelField, LabelForm }
})
export default class TextareaInput extends Vue {
  @Prop({ default: '' }) label: string
  @Prop({ default: 'form' }) labelType: 'form' | 'field'
  @Prop({ default: '' }) placeholder: string
  @Prop({ default: '' }) description: string
  @Prop({ default: '' }) errorMessage: string
  @Prop({ default: 4 }) rows: number
  @Prop({ required: true }) value: string

  get hasError() {
    return this.errorMessage !== ''
  }

  handleInputEvent(e: InputEvent) {
    this.$emit('input', (e.target as HTMLInputElement).value)
  }
}
