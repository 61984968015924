import { config } from '@/config'
import { OfferingResponse } from '@/shared/billie/api/offerings'
import merge from 'lodash/merge'

const isProd = config.SUNRISE_AIRTABLE_DB === 'production'

export const SUPPLIER_DK_ID = isProd ? '25adb027-99b8-5246-813a-0b391c02247f' : '25adb027-99b8-5246-813a-0b391c02247f'
export const SUPPLIER_UK_ID = isProd ? '976edf64-3014-558b-844a-ae44863e20db' : '0c9e56ac-35e1-5418-b08b-1f6fa33dce0e'

export const OFFERING_DK_ID = isProd ? '9fc560ae-db84-5100-886b-f2cc57f37512' : '9fc560ae-db84-5100-886b-f2cc57f37512'
export const OFFERING_UK_ID = isProd ? 'd90ec710-d230-5835-bb8b-21c37a08f008' : 'ad02dda9-9c9f-5415-9a51-1f8632871247'

export const PROMO_TAG = 'lunch'

export interface SunriseOfferingProperties {
  isPromo?: boolean
  heroPromoName?: string
  heroPromoHeading: string
  heroImage: string
  exploreHeroImage: string
  heroImageBg: string
  heroImageObject: string
  description: string
  gallery?: string[]
  objectImage: string
  requestImage: string
  promoText: string
  supplier: {
    description: string
  }
  otherOfferings: {
    id: string
    supplier: { id: string }
    image: string
    name: string
    shortDescription: string
  }[]
}

export type SunriseOffering = OfferingResponse & SunriseOfferingProperties

const OFFERING_DK_DA: SunriseOfferingProperties = {
  isPromo: true,
  heroPromoName: 'Månedens udvalgte',
  heroPromoHeading: 'Stop madspild og spar penge',
  heroImage: '/assets/images/sunrise/lunch_hero.png',
  exploreHeroImage: '/assets/images/sunrise/lunch_hero.png',
  heroImageBg: '/assets/images/sunrise/tree_hero_background.png',
  heroImageObject: '/assets/images/sunrise/lunch_object.png',
  description: `<h3>Danmarks mest fleksible firmafrokost</h3><br/>
  Vi har gjort det nemmere end nogensinde at tilpasse firmafrokosten til din hybride arbejdsstyrke.<br/><br/>
  Spar penge, spar tid, og stop madspild!<br/><br/><br/>
  <h3>Omkostningseffektiv bæredygtig frokostordning</h3><br/>
  Lad dine ansatte bestille deres egen frokost, ud fra hvornår de er på kontoret. De får den mad, de vil have. Du får din tid tilbage.<br/>
  <ul>
  <li>Få den rigtige mængde mad – hver dag</li>
  <li>Undgå madspild, og spar penge</li>
  <li>Tag kontrol over din tid</li>
  <li>Overblik over alle bestillinger – nem redigering</li>
  <li>Sig farvel regneark</li>
  </ul>`,
  gallery: [
    '/assets/images/sunrise/lunch_gallery_1.png',
    '/assets/images/sunrise/lunch_gallery_2.png',
    '/assets/images/sunrise/lunch_gallery_3.png',
  ],
  supplier: {
    description: `<p style="flex: 1;">
      <strong>Sådan virker det:</strong><br/><br/>
      Send et link til i teamet<br/><br/>
      De vælger alle frokost for de dage, de er på kontoret<br/><br/>
      Du får fuldt overblik over og mulighed for at redigere din ordre<br/><br/>
      Din madleverandør modtager automatisk ordren, hvorefter maden leveres<br/><br/>
      Du sparer tid, du sparer penge, og så hjælper du med at reducere madspild!<br/><br/>
      </p>`,
  },
  objectImage: '/assets/images/sunrise/lunch_supplier_object.png',
  promoText:
    'En hybrid arbejdsplads skal have Danmarks mest fleksible firmafrokost. Vi har gjort det nemmere end nogensinde at tilpasse firmafrokosten',
  requestImage: '/assets/images/sunrise/tree_portrait.png',
  otherOfferings: [
    // ID's and airtable image url's needs to be changed for each. I left url to the offering on the platform before each
    {
      // https://app.goodmonday.io/supplier/a7529d44-5dc6-56c5-aa92-968785e7a6b3/c3eb0b3c-d343-5ec1-b2cf-9aa7835ebbe3
      id: isProd ? 'c3eb0b3c-d343-5ec1-b2cf-9aa7835ebbe3' : 'e3069967-ee88-5f09-b679-fd0f54c97330',
      supplier: { id: isProd ? 'a7529d44-5dc6-56c5-aa92-968785e7a6b3' : 'b1a3e831-b343-53a8-b71f-2a0374799231' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/644fbf3f990745fdbf84376f600a7b89/dc16110a',
      name: 'Cocktailbar',
      shortDescription: 'Forkæl dit team med lækre cocktails mixet af professionelle bartendere.',
    },
    {
      // https://app.goodmonday.io/supplier/f5b7c64b-35f0-5b81-8853-420cf1bcb844/3ea39130-4a61-5316-b099-7e3badfe1e78
      id: isProd ? '3ea39130-4a61-5316-b099-7e3badfe1e78' : '8c531d39-7af5-586a-9406-5840f3de945b',
      supplier: { id: isProd ? 'f5b7c64b-35f0-5b81-8853-420cf1bcb844' : '5828476b-b452-5e9c-98e4-5b6c2702dc0e' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/a711626893aadb37bbfe05a0ac37dfe8/301d2530',
      name: 'Vin',
      shortDescription: 'Fra rosé til portvin – vi har, hvad du skal bruge',
    },
    {
      // https://app.goodmonday.io/supplier/8c0bec89-9447-5400-bba8-6ee0a7d74e8f/24696e04-9846-56fb-b5f2-31cd38d80c6d
      id: isProd ? '24696e04-9846-56fb-b5f2-31cd38d80c6d' : '0b63651c-d509-5b18-a7cc-21d5315e3963',
      supplier: { id: isProd ? '8c0bec89-9447-5400-bba8-6ee0a7d74e8f' : '1d22d9ec-e235-523f-abd9-309725783323' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/8692a237e56c7c4a09d0e057ae29b7f2/3362344a',
      name: 'Julegaver',
      shortDescription: 'Gavekort, gaveæsker og oplevelsesgaver',
    },
  ],
}

const OFFERING_DK_EN: SunriseOfferingProperties = {
  isPromo: true,
  heroPromoName: 'Monthly feature',
  heroPromoHeading: 'Stop food waste and save money',
  heroImage: '/assets/images/sunrise/lunch_hero.png',
  exploreHeroImage: '/assets/images/sunrise/lunch_hero.png',
  heroImageBg: '/assets/images/sunrise/tree_hero_background.png',
  heroImageObject: '/assets/images/sunrise/lunch_object.png',
  description: `<h3>Denmark’s most flexible lunch solution</h3><br/>
  Getting the right amount of lunch for a hybrid workforce has never been easier.<br/><br/>
  Save money, save time, and stop food waste - today!<br/><br/><br/>
  <h3>Change the way you order lunch, today!</h3><br/>
  Let people choose their lunch for when they know they’ll be in the office. They get the right food, and you get your time back.<br/>
  <ul>
  <li>Get the right amount of food - every day</li>
  <li>Save money on excess orders</li>
  <li>Take back control of your time</li>
  <li>Clear and editable overview of all orders</li>
  <li>Minimise food waste</li>
  <li>Wave goodbye to spreadsheets</li>
  </ul>`,
  gallery: [
    '/assets/images/sunrise/lunch_gallery_1.png',
    '/assets/images/sunrise/lunch_gallery_2.png',
    '/assets/images/sunrise/lunch_gallery_3.png',
  ],
  supplier: {
    description: `<p style="flex: 1;">
	<strong>How does it work?</strong><br/><br/>
	Send one link to all the team<br/><br/>
	Each person chooses their lunch for when they’re in the office<br/><br/>
	You enjoy a clear and editable overview of the full order<br/><br/>
	Your supplier receives the order automatically and food is delivered<br/><br/>
	You save time, money, and help reduce food waste!<br/><br/>
	</p>`,
  },
  objectImage: '/assets/images/sunrise/lunch_supplier_object.png',
  promoText:
    'A hybrid workplace needs flexible and practical solutions. We’ve made it easier than ever before to take care of your office lunch.',
  requestImage: '/assets/images/sunrise/tree_portrait.png',
  otherOfferings: [
    // ID's and airtable image url's needs to be changed for each. I left url to the offering on the platform before each
    {
      // https://app.goodmonday.io/supplier/a7529d44-5dc6-56c5-aa92-968785e7a6b3/c3eb0b3c-d343-5ec1-b2cf-9aa7835ebbe3
      id: isProd ? 'c3eb0b3c-d343-5ec1-b2cf-9aa7835ebbe3' : 'e3069967-ee88-5f09-b679-fd0f54c97330',
      supplier: { id: isProd ? 'a7529d44-5dc6-56c5-aa92-968785e7a6b3' : 'b1a3e831-b343-53a8-b71f-2a0374799231' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/644fbf3f990745fdbf84376f600a7b89/dc16110a',
      name: 'Cocktail bar',
      shortDescription: 'Treat your team with delicious cocktails made by our professional bartenders',
    },
    {
      // https://app.goodmonday.io/supplier/f5b7c64b-35f0-5b81-8853-420cf1bcb844/3ea39130-4a61-5316-b099-7e3badfe1e78
      id: isProd ? '3ea39130-4a61-5316-b099-7e3badfe1e78' : '8c531d39-7af5-586a-9406-5840f3de945b',
      supplier: { id: isProd ? 'f5b7c64b-35f0-5b81-8853-420cf1bcb844' : '5828476b-b452-5e9c-98e4-5b6c2702dc0e' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/a711626893aadb37bbfe05a0ac37dfe8/301d2530',
      name: 'Wine',
      shortDescription: "From rosé to port, we've got it all",
    },
    {
      // https://app.goodmonday.io/supplier/8c0bec89-9447-5400-bba8-6ee0a7d74e8f/24696e04-9846-56fb-b5f2-31cd38d80c6d
      id: isProd ? '24696e04-9846-56fb-b5f2-31cd38d80c6d' : '0b63651c-d509-5b18-a7cc-21d5315e3963',
      supplier: { id: isProd ? '8c0bec89-9447-5400-bba8-6ee0a7d74e8f' : '1d22d9ec-e235-523f-abd9-309725783323' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/8692a237e56c7c4a09d0e057ae29b7f2/3362344a',
      name: 'Christmas presents',
      shortDescription: 'Gift cards, gift boxes, and gift experiences',
    },
  ],
}

const OFFERING_UK_EN: SunriseOfferingProperties = {
  heroImage: '/assets/images/sunrise/tricycle_hero.png',
  exploreHeroImage: '/assets/images/sunrise/treat_hero.png',
  heroImageBg: '/assets/images/sunrise/tricycle_hero_background.png',
  heroImageObject: '/assets/images/sunrise/tricycle_object.png',
  description: `<p>The Chestnut Tricycle 🥜  and the Mulled Wine Tricycle 🍷 will fill the room with smells of Christmas come early. <strong>Perfect for Christmas events</strong>, or if you are just looking for something to warm you up on a cold winter's night.</p><br/><br/>
    <p>Chestnuts and wine not your thing? <strong>We can also do</strong>:</p>
    <ul>
    <li>- Churros<br/></li>
    <li>- Crepes<br/></li>
    <li>- Doughnuts<br/></li>
    <li>- Coffee<br/></li>
    <li>- Snow cones<br/></li>
    <li>- Hot chocolate<br/></li>
    <li>- Gin<br/></li>
    <li>- Racelette<br/></li>
    <li>- Fish and chips<br/></li>
    <li>- Pizza<br/><br/>
    <ul/>
    <p>And so much more! <strong>Get in touch today</strong> and find out how we can help make your Christmas event the one you deserve.</p>`,
  supplier: {
    description: `<p style="flex: 1;">
      <strong>Prices</strong><br/>
      Mulled wine tricycle from £975<br/>
      Chestnut tricycle from £920<br/><br/>
      <strong>Prices include:</strong><br/>
      150 servings<br/>
      Uniformed operator<br/>
      150 mile round journey<br/><br/>
      For other tricycle pricing, please make a request</p>`,
  },
  gallery: [
    '/assets/images/sunrise/tricycle_gallery1.png',
    '/assets/images/sunrise/tricycle_gallery2.png',
    '/assets/images/sunrise/tricycle_gallery3.png',
  ],
  objectImage: '/assets/images/sunrise/xmas_object.png',
  heroPromoHeading: 'Love your office',
  promoText:
    'From sweet treats and deep cleans to greenery and gifts, make your workplace one people love coming in to.',
  requestImage: '/assets/images/sunrise/tricycle_portrait.png',
  otherOfferings: [
    // ID's and airtable image url's needs to be changed for each. I left url to the offering on the platform before each
    {
      // https://app.goodmonday.io/supplier/06b4b363-f055-533a-84b4-93953b7148c8/4239dd33-0419-5b26-b1fa-0eca2f8dd4f5
      id: isProd ? '4239dd33-0419-5b26-b1fa-0eca2f8dd4f5' : 'e3069967-ee88-5f09-b679-fd0f54c97330',
      supplier: { id: isProd ? '06b4b363-f055-533a-84b4-93953b7148c8' : 'b1a3e831-b343-53a8-b71f-2a0374799231' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/854d7f005ad9d08b96c9f3f0ff3be4e6/4c371cbd',
      name: 'Fully Decorated Christmas Tree',
      shortDescription: 'Order a fully decorated Christmas tree today!',
    },
    {
      // https://app.goodmonday.io/supplier/749350f3-6fe2-5cec-8470-c3fe9e5f1995/e5373dee-d5e5-5bc8-b9b5-3c4514aca9a2
      id: isProd ? 'e5373dee-d5e5-5bc8-b9b5-3c4514aca9a2' : '8c531d39-7af5-586a-9406-5840f3de945b',
      supplier: { id: isProd ? '749350f3-6fe2-5cec-8470-c3fe9e5f1995' : '5828476b-b452-5e9c-98e4-5b6c2702dc0e' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/d1060bc14b1f59d1a1887f97db512447/51e777d9',
      name: 'Christmas hampers',
      shortDescription: 'Treat the team with a fantastic Christmas hamper',
    },
    {
      // https://app.goodmonday.io/supplier/2f303d10-818d-54f0-9eff-facf8de0bf05/dc7f0045-8346-5ef1-be04-fcc33983eb6f
      id: isProd ? 'dc7f0045-8346-5ef1-be04-fcc33983eb6f' : '0b63651c-d509-5b18-a7cc-21d5315e3963',
      supplier: { id: isProd ? '2f303d10-818d-54f0-9eff-facf8de0bf05' : '1d22d9ec-e235-523f-abd9-309725783323' },
      image: 'https://dl.airtable.com/.attachmentThumbnails/fb7fe398f261c2aaa2acbe6c83e83cbe/8f858410',
      name: 'Christmas entertainment',
      shortDescription: "Festive fun from the UK's leading entertainment supplier",
    },
  ],
}

const offerings: { [key: string]: { [key: string]: SunriseOfferingProperties } } = {
  [OFFERING_DK_ID]: {
    da: OFFERING_DK_DA,
    en: OFFERING_DK_EN,
  },
  [OFFERING_UK_ID]: {
    da: OFFERING_UK_EN,
    en: OFFERING_UK_EN,
  },
}

const promos: { [key: string]: { [key: string]: SunriseOfferingProperties } } = {
  DK: {
    da: OFFERING_DK_DA,
    en: OFFERING_DK_EN,
  },
  UK: {
    da: OFFERING_UK_EN,
    en: OFFERING_UK_EN,
  },
}

/**
 * We grab the OfferingResponse from Billie and augment it with extra properties, hardcoded
 * in this data sheet (SunriseOffering = OfferingResponse & SunriseOfferingProperties).
 *
 * We use _.merge/2 over Object.assign/2 since it provides deep merging.
 */
export const augmentOffering = (offering: OfferingResponse, locale: string): SunriseOffering => {
  const loc = locale.split('-')[0]
  return merge(offering, offerings[offering.id][loc])
}

export const getPromo = (countryCode: string, locale: string): SunriseOfferingProperties => {
  const loc = locale.split('-')[0]
  return promos[countryCode][loc]
}

export const fetchFeaturedId = (country: string) => {
  switch (country) {
    case 'DK':
      return OFFERING_DK_ID
    case 'UK':
    default:
      return OFFERING_UK_ID
  }
}

export const fetchSupplierId = (country: string) => {
  switch (country) {
    case 'DK':
      return SUPPLIER_DK_ID
    case 'UK':
    default:
      return SUPPLIER_UK_ID
  }
}
