

















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export interface IAvatar {
  firstName: string
  id: string
  lastName: string
  name: null
}

@Component({
  components: {}
})
export default class Avatar extends Vue {
  @Prop() user: IAvatar
  @Prop() size: number

  get initials() {
    return this.user.firstName && this.user.lastName
      ? this.user.firstName.charAt(0).toUpperCase() + this.user.lastName.charAt(0).toUpperCase()
      : ''
  }
}
