export default function() {
  return {
    accessTokenKey: 'jwt',
    fileTokenKey: 'file_token',
    publicUrl: process.env.BASE_URL,
    commitId: 'platform_commitId',
  }
}

export const VIEWPORT = Object.freeze({
  MOBILE: 768,
  TABLET: 1024,
  DESKTOP: 1200,
})

export const PAGES = Object.freeze({
  AGREEMENT: 'agreements',
  CALENDAR: 'calendar',
  CONCIERGE: 'concierge',
  CONTACTS: 'contacts',
  CONTATCT_DETAILS: 'contact details',
  DASHBOARD: 'dashboard',
  HOME: 'home',
  FINANCE: 'finance',
  INVOICES: 'invoices',
  MARKETPLACE: 'marketplace',
  EXPLORE: 'explore',
  ORDERS: 'orders',
  PROFILE: 'profile',
  PROPOSAL: 'proposal',
  PROPOSALS: 'proposals',
  REQUEST: 'request',
  PROPOSAL_DETAILS: 'proposal details',
  SEARCH: 'search',
  SUBSCRIPTIONS: 'subscriptions',
  USERS: 'users',
})

export const TABS = Object.freeze({
  ARCHIVED: 'archived',
  COMPLETED: 'completed',
  CURRENT: 'current',
  INPROGRESS: 'inProgress',
  INVOICES: 'invoices',
  SUBSCRIPTIONS: 'subscriptions',
  ORDERS: 'orders',
  PAST: 'past',
  PENDING: 'pending',
})
